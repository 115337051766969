<template>
    <v-col class="receive" cols="12">
        <v-card outlined>
            <v-card-title class="d-flex justify-space-between">
                <span class="card-title">You receive</span>
            </v-card-title>

            <v-card-text>
                <h3 v-if="this.$store.state.trade.wow_version === 'Final Fantasy XIV'">Total gil sum</h3>
                <h3 v-else>Total gold sum</h3>
                <div class="cost-container" v-if="this.$store.state.trade.wow_version === 'Final Fantasy XIV'">
                    <span class="cost mr-3 mb-3">{{ itemsCost.gold }} Gil</span>
                </div>
                <div class="cost-container" v-else>
                    <span class="cost mr-3 mb-3">{{ itemsCost.gold }} Gold</span>
                </div>

                <div class="golds-info">
                    <div class="animate-container mobile-view">
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-1.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-2.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-3.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-4.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-5.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-6.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-7.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-8.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-9.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-10.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img
                            src="https://trade-raid-com.mo.cloudinary.net/icons/coin-anim-11.svg"
                            alt="Gold Coin"
                            class="animated"
                        />
                        <img src="https://trade-raid-com.mo.cloudinary.net/icons/golds.svg" alt="Gold bags" />
                    </div>
                    <p>Delivery time: 2-24 hours</p>
                    <div class="vat-row" v-if="VAT_MSG && $store.state.trade.country">
                        <v-col cols="6" sm="6" md="6" lg="6" xl="6" style="position: absolute; margin-top: 15px">
                            <p>
                                {{ VAT_MSG }}
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            v-on="on"
                                            v-bind="attrs"
                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                            alt="info icon"
                                            style="margin-left: 7px"
                                        />
                                    </template>
                                    <span
                                        >Trade-Raid is obligated to charge VAT on behalf of Danish Tax Authorities. Gold
                                        amount is reduced based on your country's VAT % rate.</span
                                    >
                                </v-tooltip>
                            </p>
                        </v-col>
                    </div>
                </div>
                <v-form
                    class="form-container"
                    v-if="
                        !this.$store.state.trade.realm_is_unpopular[1] &&
                        this.$store.state.trade.wow_version !== 'New World' &&
                        this.$store.state.trade.wow_version !== 'Lost Ark' &&
                        this.$store.state.trade.wow_version !== 'The Elder Scrolls Online' &&
                        this.$store.state.trade.wow_version !== 'Final Fantasy XIV'
                    "
                >
                    <v-row class="last-row">
                        <v-col>
                            <div class="form-group">
                                <label for="" class="d-flex align-center"> Gold Delivery Method </label>
                                <v-select
                                    v-model="method"
                                    :items="deliveryMethods"
                                    placeholder="Select delivery method"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :rules="[rules.required]"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    v-on:click="check_realm()"
                                >
                                    <template v-slot:item="{ parent, item }">
                                        <div class="d-flex justify-space-around items-stretch">
                                            <div class="inline-block">
                                                {{ item }}
                                            </div>
                                            <div>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <img
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                            alt="info icon"
                                                            style="margin-left: 7px"
                                                        />
                                                    </template>
                                                    <span
                                                        v-text="
                                                            item === 'Mail'
                                                                ? 'The gold is sent by mail directly to your character’s in-game mailbox.'
                                                                : 'List a Battle Pet on the Auction House with the price of the agreed gold sum. Trade-Raid does not cover the 5% comission fee.'
                                                        "
                                                    ></span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>

                        <v-col>
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Character Name
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span>Please mention the character name of the gold receiver</span>
                                    </v-tooltip>
                                </label>
                                <v-text-field
                                    v-model="characterName"
                                    placeholder="Character Name"
                                    outlined
                                    height="36px"
                                    dense
                                    :error="errors.characterName"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                        </v-col>

                        <v-col v-if="method === 'Auction House'">
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Battle Pet Name/BOE Item
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span
                                            >Write the name of the Battle Pet or BOE Item (WOTLK Classic) that you will
                                            list on the Auction House with the price of the agreed gold sum</span
                                        >
                                    </v-tooltip>
                                </label>
                                <v-text-field
                                    v-model="battlePetName"
                                    placeholder="Battle Pet Name/BOE Item"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
                <v-form
                    class="form-container"
                    v-if="
                        this.$store.state.trade.realm_is_unpopular[1] &&
                        this.$store.state.trade.wow_version !== 'New World' &&
                        this.$store.state.trade.wow_version !== 'Lost Ark' &&
                        this.$store.state.trade.wow_version !== 'The Elder Scrolls Online' &&
                        this.$store.state.trade.wow_version !== 'Final Fantasy XIV'
                    "
                >
                    <v-row class="last-row">
                        <v-col>
                            <div class="form-group">
                                <label for="" class="d-flex align-center"> Gold Delivery Method </label>
                                <v-select
                                    v-model="method"
                                    :items="['Auction House']"
                                    placeholder="Select delivery method"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :rules="[rules.required]"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    v-on:click="check_realm()"
                                >
                                    <template v-slot:item="{ parent, item }">
                                        <div class="d-flex justify-space-around items-stretch">
                                            <div class="inline-block">
                                                {{ item }}
                                            </div>
                                            <div>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <img
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                            alt="info icon"
                                                            style="margin-left: 7px"
                                                        />
                                                    </template>
                                                    <span
                                                        v-text="
                                                            item === 'Mail'
                                                                ? 'The gold is sent by mail directly to your character’s in-game mailbox.'
                                                                : 'List a Battle Pet on the Auction House with the price of the agreed gold sum. Trade-Raid does not cover the 5% comission fee.'
                                                        "
                                                    ></span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>

                        <v-col>
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Character Name
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span>Please mention the character name of the gold receiver</span>
                                    </v-tooltip>
                                </label>
                                <v-text-field
                                    v-model="characterName"
                                    placeholder="Character Name"
                                    outlined
                                    height="36px"
                                    dense
                                    :error="errors.characterName"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                        </v-col>

                        <v-col v-if="method === 'Auction House'">
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Battle Pet Name/BOE Item
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span
                                            >Write the name of the Battle Pet or BOE Item (WOTLK Classic) that you will
                                            list on the Auction House with the price of the agreed gold sum</span
                                        >
                                    </v-tooltip>
                                </label>
                                <v-text-field
                                    v-model="battlePetName"
                                    placeholder="Battle Pet Name/BOE Item"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>

                <v-form
                    class="form-container"
                    v-if="
                        this.$store.state.trade.wow_version === 'Lost Ark' ||
                        this.$store.state.trade.wow_version === 'The Elder Scrolls Online' ||
                        this.$store.state.trade.wow_version === 'Final Fantasy XIV'
                    "
                >
                    <v-row>
                        <v-col>
                            <div class="form-group">
                                <label for="" class="d-flex align-center"> Delivery Method </label>
                                <v-select
                                    v-model="method"
                                    :items="['Mail']"
                                    placeholder="Select delivery method"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :rules="[rules.required]"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                >
                                    <template v-slot:item="{ parent, item }">
                                        <div class="d-flex justify-space-around items-stretch">
                                            <div class="inline-block">
                                                {{ item }}
                                            </div>
                                            <div>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <img
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                            alt="info icon"
                                                            style="margin-left: 7px"
                                                        />
                                                    </template>
                                                    <span
                                                        v-text="
                                                            item === 'Mail'
                                                                ? 'The gold is sent by mail directly to your character’s in-game mailbox.'
                                                                : 'List a Battle Pet on the Auction House with the price of the agreed gold sum. Trade-Raid does not cover the 5% comission fee.'
                                                        "
                                                    ></span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>

                        <v-col>
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Character Name
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span>Please mention the character name of the gold receiver</span>
                                    </v-tooltip>
                                </label>
                                <v-text-field
                                    v-model="characterName"
                                    placeholder="Character Name"
                                    outlined
                                    height="36px"
                                    dense
                                    :error="errors.characterName"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>

                <v-form class="form-container" v-if="this.$store.state.trade.wow_version === 'New World'">
                    <v-row>
                        <v-col>
                            <div class="form-group">
                                <label for="" class="d-flex align-center"> Delivery Method </label>
                                <v-select
                                    v-model="method"
                                    :items="['Face to Face']"
                                    placeholder="Select delivery method"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :rules="[rules.required]"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                >
                                    <template v-slot:item="{ parent, item }">
                                        <div class="d-flex justify-space-around items-stretch">
                                            <div class="inline-block">
                                                {{ item }}
                                            </div>
                                            <div>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <img
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                            alt="info icon"
                                                            style="margin-left: 7px"
                                                        />
                                                    </template>
                                                    <span
                                                        v-text="
                                                            item === 'Face to Face'
                                                                ? 'The gold is traded to you, directly in-game face to face'
                                                                : 'List a Battle Pet on the Auction House with the price of the agreed gold sum. Trade-Raid does not cover the 5% comission fee.'
                                                        "
                                                    ></span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>

                        <v-col>
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Character Name
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span>Please mention the character name of the gold receiver</span>
                                    </v-tooltip>
                                </label>
                                <v-text-field
                                    v-model="characterName"
                                    placeholder="Character Name"
                                    outlined
                                    height="36px"
                                    dense
                                    :error="errors.characterName"
                                    hide-details
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import PriceService from '../../utils/PriceService.js';
import Coin from '../UI/SVG/coin.vue';

export default {
    components: {
        Coin,
    },
    computed: {
        ...mapGetters(['offerItems', 'errors']),
        async check_username_https_service() {
            if (this.$store.state.auth) {
                fetch(`${process.VUE_APP_BASE_API}/user-info/${this.$store.state.auth.user.steam_id}`)
                    .then((response) => response.json())
                    .then((data) => {
                        let username = data.username.toLowerCase();
                        if (username.includes('trade-raid.com')) {
                            this.$store.commit('SET_EXTRA_GOLD', ['extra_gold', true]);
                            this.extra_gold = true;
                            this.extra_gold_stash = parseInt(extra_gold_calc.toFixed());
                        }
                    });
            }
        },
        gamesWithSilver() {
            return PriceService.gamesWithSilver(this.$store.state.trade.wow_version);
        },
        itemsCost() {
            let price = this.offerItems.reduce(
                (accumulator, currentValue) => accumulator + currentValue.steam_price,
                0,
            );
            return PriceService.calculatePrice(
                price,
                this.$store.state.trade.country,
                this.$store.state.trade.extra_gold[1],
            );
        },
        method: {
            get() {
                return this.$store.state.trade.method;
            },
            set(val) {
                this.$store.commit('SET_RECEIVE_PARAMS', ['method', val]);
            },
        },
        unpopularRealms: {
            get() {
                return this.$store.state.realm_is_unpopular;
            },
            set(val) {
                this.$store.commit('SET_REALM_UNPOPULAR', ['realm_is_unpopular', val]);
                this.deliveryMethods.pop();
            },
        },
        characterName: {
            get() {
                return this.$store.state.trade.characterName;
            },
            set(val) {
                this.$store.commit('SET_CHARACTER_NAME', false);
                this.$store.commit('SET_RECEIVE_PARAMS', ['characterName', val]);
            },
        },
        battlePetName: {
            get() {
                return this.$store.state.trade.battlePetName;
            },
            set(val) {
                this.$store.commit('SET_RECEIVE_PARAMS', ['battlePetName', val]);
            },
        },
    },
    methods: {
        check_realm(e) {
            if (this.$store.state.trade.realm_is_unpopular[1]) {
                this.deliveryMethods.push('Auction House');
                if (this.deliveryMethods.length > 1) {
                    this.deliveryMethods.length = 0;
                    this.deliveryMethods.push('Auction House');
                }
            }
            if (!this.$store.state.trade.realm_is_unpopular[1]) {
                this.deliveryMethods.push('Auction House', 'Mail');
            }
            if (this.$store.state.trade.versions('New World')) {
                this.deliveryMethods.push('Mail');
            }
            if (this.$store.state.trade.versions('Lost Ark')) {
                this.deliveryMethods.push('Mail');
            }
            if (this.$store.state.trade.versions('The Elder Scrolls Online')) {
                this.deliveryMethods.push('Mail');
            }
            if (this.$store.state.trade.versions('Final Fantasy XIV')) {
                this.deliveryMethods.push('Mail');
            }
        },
    },
    check_for_extra_gold() {
        const id = this.$store.state.auth.user.name.toLowerCase();
        if (id.includes('trade-raid.com')) {
            this.extra_gold = true;
            this.$store.commit('SET_EXTRA_GOLD', ['extra_gold', true]);
        }
    },
    data: () => ({
        // delivery methods gets populated based on realm popularity see method => unpopularRealms()
        deliveryMethods: [
            // 'Auction House',
            // 'Mail'
        ],
        realm_is_unpopular: null,
        VAT_MSG: false,
        VAT_MSG_I: false,
        exclude_auction_field: ['New World', 'Lost Ark', 'The Elder Scrolls Online', 'Final Fantasy XIV'],
        versions: null,
        extra_gold: true,
        extra_gold_msg: null,
        extra_gold_stash: null,
        battlePetError: false,
        rules: {
            required: (value) => !!value || 'Is required!',
        },
    }),
    watch: {
        battlePetName: {
            deep: true,
            handler(curr, prev) {
                this.$store.commit('SET_RECEIVE_PARAMS', ['battlePetName', curr]);
            },
        },
    },
};
</script>

<style>
.receive input::placeholder {
    font-size: 13px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

.cost-container {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: center;
}

.vat-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.receive
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.receive
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #798197;
}

.receive .v-text-field--outlined.v-input--is-focused fieldset {
    color: #798197;
}

.receive .v-icon {
    color: #fff !important;
}

.receive .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #fff !important;
}

.receive .theme--light.v-select .v-select__selection--comma {
    color: #fff;
}

.receive .v-menu__content .v-list {
    background: #798197;
}

.receive .theme--light.v-input input {
    color: #fff;
}
</style>

<style lang="scss" scoped>
/** show mobile */
@media screen and (max-width: 820px) {
    .last-row {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .golds-info {
        display: none;
    }
    .cost {
        font-size: 30px;
    }
}

/** desktop mqs */
@media screen and (min-width: 821px) {
    .cost {
        font-size: 60px;
    }
}
.row {
    height: 100%;
}
.hide {
    display: none;
}
.extra-gold {
    font-size: 25px;
    font-weight: bold;
    // color: 	#CFB53B;
    color: #fcc938;
}
.receive {
    flex-basis: 1;

    .v-card {
        max-height: 100%;
        background: #383f51;
        border-radius: 3px;

        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;

            .card-title {
                font-weight: 600;
            }
        }

        .v-card__text {
            text-align: center;
            padding: 60px 20px 30px;

            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                color: #ffffff;
                opacity: 0.5;
                margin-bottom: 24px;
            }

            .cost {
                font-weight: 600;
                // font-size: 60px;
                line-height: 64px;
                color: #ffffff;
            }

            .golds-info {
                margin-bottom: 54px;

                img {
                    display: inline-block;
                    margin: 0 auto;
                }

                p {
                    font-size: 16px;
                    line-height: 22px;
                    color: rgba(255, 255, 255, 0.8);
                }
            }
            .form-container {
                padding: 23px 20px;
                background: #555c70;

                .form-group {
                    label {
                        font-size: 12px;
                        line-height: 11px;
                        color: rgba(255, 255, 255, 0.6);
                        border-radius: 4px;
                        margin-bottom: 7px;
                    }
                    &::deep input::placeholder {
                        font-size: 13px;
                        line-height: 18px;
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }
    }
}

.animate-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    height: 330px;

    .G {
        position: absolute;
        font-weight: 600;
        font-size: 120px;
        line-height: 144px;
        color: #ffffff;
    }

    .animated {
        position: absolute;

        &:nth-child(1) {
            left: 75%;
            top: 56%;
            animation: top-left-down-1 10s linear infinite;
        }

        &:nth-child(2) {
            left: 19%;
            top: 63%;
            animation: top-left-down-2 10s linear infinite;
        }

        &:nth-child(3) {
            left: 26%;
            top: 19%;
            animation: top-left-down-3 10s linear infinite;
        }

        &:nth-child(4) {
            left: 31%;
            top: 6%;
            animation: top-left-down-4 10s linear infinite;
        }

        &:nth-child(5) {
            left: 50%;
            top: 2%;
            animation: top-left-down-5 10s linear infinite;
        }

        &:nth-child(6) {
            left: 75%;
            top: 28%;
            animation: top-left-down-6 10s linear infinite;
        }

        &:nth-child(7) {
            left: 76%;
            top: 11%;
            animation: top-left-down-7 10s linear infinite;
        }

        &:nth-child(8) {
            left: 13%;
            top: 51%;
            animation: top-left-down-8 10s linear infinite;
        }

        &:nth-child(9) {
            left: 88%;
            top: 43%;
            animation: top-left-down-9 10s linear infinite;
        }

        &:nth-child(10) {
            left: 14%;
            top: 32%;
            animation: top-left-down-10 10s linear infinite;
        }

        &:nth-child(11) {
            left: 65%;
            top: 22%;
            animation: top-left-down-11 10s linear infinite;
        }
    }
}

@keyframes top-left-down-1 {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(-3px, -3px);
    }
    40% {
        transform: translate(3px, -3px);
    }
    60% {
        transform: translate(3px, 3px);
    }
    80% {
        transform: translate(-3px, 3px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-2 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(-3px, -3px);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(-3px, 0);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(-3px, 3px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-3 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(-3px, -3px);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(3px, -3px);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(3px, 3px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-4 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(0, -3px);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(-3px, 3px);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(3px, 3px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-5 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(0, -3px);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(-3px, -3px);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(3px, -3px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-6 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(0, -3px);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(3px, 3px);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(-3px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-7 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(3px);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(3px, 0);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(-3px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-8 {
    0% {
        transform: translate(0, 0);
    }
    12% {
        transform: translate(-3px, -3px);
    }
    25% {
        transform: translate(0, 0);
    }
    37% {
        transform: translate(-3px, -3px);
    }
    50% {
        transform: translate(0, 0);
    }
    62% {
        transform: translate(-3px, 3px);
    }
    75% {
        transform: translate(0, 0);
    }
    87% {
        transform: translate(-3px, 3px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-9 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(0, -3px);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(-3px, -3px);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(3px, 3px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-10 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(-3px, 0);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(0, -3px);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(3px, 3px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes top-left-down-11 {
    0% {
        transform: translate(0, 0);
    }
    17% {
        transform: translate(3px, 0);
    }
    34% {
        transform: translate(0, 0);
    }
    51% {
        transform: translate(3px, -3px);
    }
    68% {
        transform: translate(0, 0);
    }
    85% {
        transform: translate(0, -3px);
    }
    100% {
        transform: translate(0, 0);
    }
}
</style>
<style>
.receive .theme--light.v-input input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #555c70 inset !important;
    -webkit-text-fill-color: #fff;
}
</style>
