<template>
    <v-btn class="shopping-cart background-color-gradient static-width-220">
        <div class="shopping-cart-content">
            <ShoppingCartIcon style="fill: #f1f1f1" />
            <div>Add to Cart</div>
        </div>
    </v-btn>
</template>

<script>
import './utility.styles.css';
export default {
    name: 'shopping-cart-module',
    data() {
        return {
            aside: false,
        };
    },
    computed: {

    },
    methods: {},
};
</script>
