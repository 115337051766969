<template>
    <div class="extra_gold_tab_orange">
        <div :class="`${type === 'VAT' ? 'VAT' : 'e-gold-text'}`">{{ percentage }}</div>
    </div>
</template>

<script>
export default {
    props: {
        percentage: String,
        type: String,
    },
};
</script>

<style scoped>
.extra_gold_tab_blue {
    width: 40px;
    height: auto;
    font-size: 10px;
    color: rgb(130, 172, 130);
    background: rgba(44, 141, 87, 0.63);
    font-weight: bold;
    align-items: center;
    display: inline-flex;
    padding: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    border-radius: 4px;
    padding-left: 8px;
    font-family: Apercu Pro, system-ui;
}

.extra_gold_tab_orange {
    height: auto;
    font-size: 10px;
    color: white;
    /* color: #ffc144; */
    /* color: #ec5028; */
    /* background-color: rgba(206, 203, 56, 0.849);
     */
    /* background: rgba(255, 193, 68, 0.1);  */
    background-color: #4103fc;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
    padding: 4px;
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    border-radius: 4px;
    font-family: Apercu Pro, system-ui;
    justify-content: center;
    align-items: center;
    display: flex;
}
.extra_gold_tab_green {
    width: 40px;
    height: auto;
    font-size: 10px;
    color: #5acd8d;
    background: rgba(90, 205, 141, 0.1);
    font-weight: bold;
    align-items: center;
    display: inline-flex;
    padding: 4px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    border-radius: 4px;
    padding-left: 8px;
    font-family: Apercu Pro, system-ui;
    right: 10px;
    position: absolute;
}
.e-gold-text {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}
</style>
