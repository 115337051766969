/**
 * @IMPORTANT - Do not remove or edit anything in this list if name changes are made to game versions add a case in the list
 * @IMPORTANT - Lower case all cases
 * @param {*} game - lowercase
 * @returns game alias for MMO game images
 */
export const MMOGameAlias = (game) => {
    const g = game.toLowerCase();
    switch (g) {
        case 'new world':
            return 'new-world';
        case 'world of warcraft': 
            return 'wow-retail'
        case 'shadowlands retail':
            return 'wow-retail';
        case 'dragonflight retail':
            return 'wow-retail';
        case 'retail':
            return 'wow-retail';
        case 'lost ark':
            return 'lost-ark';
        case 'the elder scrolls online':
            return 'the-elder-scrolls-online';
        case 'wotlk classic':
            return 'wotlk';
        case 'wotlk':
            return 'wow-classic';
        case 'som':
            return 'wow-som';
        case 'classic season of mastery':
            return 'wow-som';
        case 'final fantasy xiv':
            return 'final-fantasy-xiv';
        case 'tbc':
            return 'tbc';
        default:
            return g;
    }
};

export const OrderStatusController = (status) => {
    const s = status.toLowerCase();
    switch (s) {
        case 'pending-internal':
            return 'PENDING';
        case 'declined-internal':
            return 'PENDING';
        case 'escrow':
            return 'ON-HOLD-BY-STEAM';
    }
    return status;
};

export const OrderabbreviateAmount = (number, notation, compactDisplay) => {
    return Intl.NumberFormat('en-us', {
        notation,
        compactDisplay,
    })
        .format(number)
        .replace('T', 'K');
};

export const getOrderItemsAmount = (items, n) => {
    const entries = [];
    for (const item of items) {
        if (entries.length < n) {
            entries.push(item);
        }
    }
    return entries;
};

export const getTotalShopOrderPrice = (products) => {
    let total_price = 0;
    for (const product of products.client_items) {
        total_price += product.price;
        if (product && product.options && product.options.length) {
            for (const option of product.options) {
                total_price += option.price_increase;
            }
        }
    }
    return total_price;
}


