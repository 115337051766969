<template>
    <div class="product-view outer-wrapper" v-if="filteredProducts && propProducts">
        <div class="product-game flex-inline" v-if="game">
            <MMOGameIcon v-if="game" class="game-icon-mmo" :game="abbreviateGame(game || 'default')" />{{ game
            }}<span v-if="game">offers</span>
        </div>
        <div class="product-filters" v-if="propProductFilters">
            <div
                class="product-filter"
                @click="setActiveProductFilter('ALL')"
                :class="{ activeProductFilter: 'ALL' === activeProductFilter }"
            >
                ALL
            </div>
            <div
                class="product-filter"
                v-for="(filter, index) in propProductFilters"
                :key="index"
                @click="setActiveProductFilter(filter)"
                :class="{ activeProductFilter: filter === activeProductFilter }"
            >
                {{ filter.toUpperCase() }}
            </div>
        </div>
        <div class="cards" v-if="filteredProducts">
            <div class="card" v-for="(item, index) in filteredProducts" :key="index">
                <div
                    class="card-content-wrapper"
                    :style="{ backgroundImage: `url('${item.backgroundImage}')` }"
                    @click="showProductDetails(item)"
                >
                    <div class="tags">
                        <div
                            class="tag"
                            v-for="(tag, tIdx) in item.tags"
                            :key="tIdx"
                            :style="`backgroundColor: ${tag.color}; borderColor: ${tag.color}`"
                        >
                            {{ tag.title }}
                        </div>
                    </div>
                    <div class="card-title flex-inline">
                        <MMOGameIcon :game="item.game.toLowerCase()" />
                        <span>{{ item.title }}</span>
                    </div>
                    <div class="card-description">
                        {{ item.description_short }}
                    </div>
                    <div class="list-item-container" v-if="item.reward_list">
                        <li
                            class="list-item-sub-description"
                            v-for="(item, listIndex) in item.reward_list"
                            :key="listIndex"
                        >
                            {{ item }}
                        </li>
                    </div>
                    <div v-if="item.type === 'gold'" class="trade-protection">
                        <li
                            class="list-item-sub-description"
                            v-for="(item, listIndex) in tradeProtection"
                            :style="`color: ${item.color}`"
                            :key="listIndex"
                        >
                            {{ item.title }}
                        </li>
                    </div>
                    <div class="details" v-if="item.price > 0">
                        <div class="details-price">
                            <span>from</span> {{ $store.state.shop.activeCurrency }} {{ item.price }}
                        </div>
                        <v-btn class="custom-button background-color-gradient" @click="setCurrentProduct(item)">
                            Details
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="$store.state.shop.productModalState" width="800" class="custom-modal-config">
            <div class="product-modal-container">
                <v-card v-if="$store.state.shop.productModalState" color="#202136">
                    <v-card-title class="headline modal background-color-gradient text-white"
                        ><div class="close"></div
                    ></v-card-title>
                    <v-card-text>
                        <ModalInfo :current_product="current_product" :price="totalPrice" :game="game" />
                    </v-card-text>
                </v-card>
            </div>
        </v-dialog>
    </div>
    <div v-else class="none">
        No Products available for this game yet.
        <div class="subtext">
            Did you know trade-raid.com has alternative payment methods? You can purchase boosts for MMO gold, steam
            skins and more <a href="">check it out</a>
        </div>
    </div>
</template>

<script>
import './utility.styles.css';
import { MMOGameAlias } from '../../utils/orderService.js';
import Loader from '../UI/loader.vue';
import ModalInfo from './ModalInfo.vue';
export default {
    name: 'tr-products',
    components: {
        Loader,
    },
    props: {
        propProducts: Array,
        propProductFilters: Array,
        game: String,
    },
    components: {
        ModalInfo,
    },
    data() {
        return {
            activeProductFilter: 'All',
            typeFilters: null,
            modal: false,
            current_product: null,
            active_region: 'EU',
            active_boost_method: 'Self-play',
            activeExecutionSpeed: 'Normal',
            tradeProtection: [
                { title: 'Price Guarantee', color: 'rgb(150, 212, 115)' },
                { title: 'Delivery Guarantee', color: '' },
                { title: 'Refund Guarantee', color: '' },
            ],
            customOptions: [],
            totalPrice: 0,
            checkbox_tos: false,
            checkbox_offers: false,
            active_options: [],
        };
    },
    watch: {},
    computed: {
        filteredProducts() {
            if (this.activeProductFilter === 'All' || this.activeProductFilter === 'ALL') {
                return this.propProducts;
            }
            return this.propProducts.filter((x) => {
                if (x.type.toLowerCase() === this.activeProductFilter.toLowerCase()) {
                    return x;
                }
            });
        },
        modalState() {
            return this.$store.state.shop.modalState;
        },
    },
    methods: {
        setBackgroundImage(image_url) {
            const style = `background-image: url(${image_url})`;
            return style;
        },
        setActiveProductFilter(filter) {
            this.activeProductFilter = filter;
        },
        showProductDetails(item) {
            if (item.type === 'service') {
                this.$router.push(item.edge);
            } else {
                this.$store.commit('SET_KEY_VALUE_PAIR', ['productModalState', true]);
                this.totalPrice = item.price;
                this.current_product = item;
            }
        },
        abbreviateGame() {
            return MMOGameAlias(this.game);
        },
        setCurrentProduct(item) {
            if (item.type === 'service') {
                this.$router.push(item.edge);
            } else {
                this.current_product = item;
            }
        },
    },
    beforeCreate() {
        // this.products = this.propProducts;
        // this.typeFilters = this.propProductFilters;
    },
    mounted: function () {
        this.products = this.propProducts;
        this.typeFilters = this.propProductFilters;
        console.log({_id: this.$store.state.shop.currentProductId})
        if(this.$store.state.shop.currentProductId) {
            console.log({_id: this.$store.state.shop.currentProductId});

            this.products.filter((x) => {
                console.log(x)
                if(x._id === this.$store.state.shop.currentProductId) {
                    this.current_product = x;
                    this.showProductDetails(x);
                }
            })
            this.$store.commit('SET_KEY_VALUE_PAIR', ['currentProductId', null]);
        }
    },
};
</script>

<style scoped lang="scss">
.outer-wrapper {
    transition: all 1s;
}
.product-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
}
.product-filter {
    padding: 10px;
    background-color: #333;
    color: #f1f1f1;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}
// small screens
@media (max-width: 500px) {
    .product-view {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .cards {
        display: flex;
        align-items: center;
        // margin-top: 125px;
        flex-direction: column;
    }
    .product-modal {
        display: flex;
        flex-direction: column !important;
    }
    .description {
        max-width: 300px;
    }
    .card {
        // min-width: 325px;
    }
    .expansion-content {
        max-width: 300px;
    }
    .expansion-panel {
        max-width: 300px;
    }
    .collapse-container {
        max-width: 300px;
    }
}
img {
    width: 75%;
}
.activeProductFilter {
    background-color: rgb(68, 65, 65);
}
.product-game {
    color: #f1f1f1 !important;
    font-weight: bold !important;
    font-size: 25px !important;
}

.list-item-container {
    margin-top: 15px;
}
.list-item-sub-description {
    font-size: 15px;
    list-style: none; /* Remove default bullets */
    font-weight: bold;
}
// li for list items in overview
.list-item-sub-description::before {
    content: '\2022'; /* CSS Code/unicode for a bullet */
    color: #5761ff;
    font-weight: bold;
    display: inline-block;
    width: 1em;
}
.cards {
    // width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    min-height: 325px!important;

}
.cards,
.card {
    color: #f1f1f1 !important;
    flex: 1 1 25%;
    box-sizing: border-box;
}
.card-description {
    font-size: 15px;
}
.none {
    font-weight: bold;
    font-size: 30px;
    color: #f1f1f1;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 150px;
}
.none > .subtext {
    font-size: 15px !important;
}
.card {
    height: auto;
    border-radius: 5px;
    max-width: 325px;
    min-height: 325px;
}
.card:hover .card-content-wrapper,
.card:focus .card-content-wrapper > img {
    transform: scale(1.1);
}

// medium screens
@media (max-width: 1280px) {
    .cards {
        min-width: 600px;
        display: flex;
        flex-direction: column;
    }
    .card {
        max-width: 700px;
    }
}

// ultra small screens
@media (max-width: 300px) {
    .product-filters {
        min-width: 280px;
        max-width: 280px !important;
    }
    .product-game {
        max-width: 225px;
        display: block;
        flex-wrap: wrap;
    }
    .cards {
        min-width: 280px;
        max-width: 280px;
        display: flex;
        flex-direction: column;
    }
    .card {
        max-width: 280px;
        margin-left: -35px;
        padding-right: 15px;
    }
}

// small screens
@media (max-width: 540px) {
    .cards {
        max-width: 400px;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: -35px;
    }
    .none {
        max-width: 300px;
    }
    .card {
        width: 325px;
        min-height: 325px;
    }
    .product-filters {
        max-width: 400px;
    }
}
.card-title {
    font-size: 23px;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
}
.details {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: auto;
}

.details-price {
    font-size: 20px;
    font-weight: bold;
    color: #f1f1f1;
}
.details-price > span {
    color: rgb(192, 192, 214);
    font-size: 12px;
}

.card-content-wrapper {
    cursor: pointer;
    padding: 15px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    min-height: 325px;
}
.tag {
    padding-left: 5px;
    padding-right: 5px;
    padding-block: 3px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    background-color: rgb(87, 97, 255);
    border-color: rgb(87, 97, 255);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    position: relative;
}
.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.product-modal {
    display: flex;
    flex-direction: row;
    flex: 1 1 50%;
    .title {
        color: #f1f1f1;
    }
}

.product-modal-container {
    color: #f1f1f1 !important;
}

.product-modal-info {
    color: #f1f1f1 !important;
    min-width: 500px;
    padding-block: 15px;
    padding-inline: 15px;
}
.product-modal-aside {
    color: #f1f1f1 !important;
    min-width: 250px;
    padding-block: 15px;
    padding-inline: 15px;
}
.price {
    font-size: 1.3rem;
    font-weight: bold;
}
.action-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.collapse-container {
    width: 100%;
    .v-expansion-panel {
        border-radius: 2px;
        background: #383f51;
        margin-top: 0;
        margin-bottom: 20px;
    }
    .v-expansion-panel-header {
        font-size: 18px;
        line-height: 24px;
        color: #fff;
        &::v-deep .v-expansion-panel-header__icon i {
            color: #fff;
        }
        &.v-expansion-panel-header--active,
        &.v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon i {
            color: #17d0c1;
        }
    }
    .v-expansion-panel-content {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 16px;
        line-height: 30px;
        width: 300px;
        color: rgba(255, 255, 255, 1);
        div {
            padding-top: 0;
            padding-bottom: 25px;
        }
    }
    @media (min-width: 500px) {
        .v-expansion-panel-content {
            width: 300px;
        }
    }
    .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
        font-size: 16px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
    }
}
</style>
