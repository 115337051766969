import { getInventory, sendTradeEmail } from '../../api/trade';
import { botTrade, botTradeReserve } from '../../api/bot';
import { userService } from '../../utils/userService';
import _ from 'lodash';

const state = {
    items: [],
    offerItems: [],
    realm_is_unpopular: false,
    selectedGame: 'steam',
    wow_version: null,
    region: null,
    faction: null,
    method: 'Auction House',
    referral: null,
    characterName: null,
    battlePetName: null,
    showInfoTradeModal: false,
    extra_gold: ['extra_gold', false],
    loading: false,
    offer_id: null,
    user_info: null,
    trade_details: null,
    trade_email: null,
    combine_user_id: null,
    step: 1,
    gameIds: {
        753: 'steam',
        570: 'dota',
        730: 'cs',
        440: 'tf2',
        252490: 'rust',
    },
    country: 'United States',
    message: null,
};
const mutations = {
    SET_GAME_ITEMS: (state, items) => {
        state.items = [...state.items.filter((element) => element.value !== items.value), items];
    },
    SET_USER_INFO: (state, info) => {
        state.user_info = info;
    },
    SET_EXTRA_GOLD: (state, extra_gold) => {
        state.extra_gold = extra_gold;
    },
    SET_STEP: (state, step) => {
        state.step = parseInt(step, 10);
    },
    SET_REALM_UNPOPULAR: (state, realm_is_unpopular) => {
        state.realm_is_unpopular = realm_is_unpopular;
    },
    SET_REFERRAL: (state, referral) => {
        state.referral = referral;
    },
    SET_LOADING: (state, show) => {
        state.loading = show;
    },
    CLEAR_OFFER_ITEMS: (state) => {
        state.offerItems = [];
    },
    SHOW_INFO_TRADE_MODAL: (state, show) => {
        state.showInfoTradeModal = show;
    },
    ADD_ITEM_TO_OFFER: (state, item) => {
        state.offerItems.push(item);
    },
    SET_GAME: (state, game) => {
        state.selectedGame = game;
    },
    SET_OFFER_ID: (state, id) => {
        state.offer_id = id;
    },
    SET_WOW_VERSION: (state, version) => {
        state.wow_version = version;
    },
    SET_REGION: (state, region) => {
        state.region = region;
    },
    SET_COUNTRY: (state, country) => {
        state.country = country;
    },
    SET_MESSAGE: (state, msg) => {
        state.msg = msg;
    },
    DELETE_ITEM_FROM_INVENTORY: (state, item) => {
        let games = state.items;
        let gameIndex = games.findIndex((element) => element.value === state.selectedGame);

        let gameItems = games[gameIndex].items;

        let filteredGameItems = gameItems.filter((element) => element.asset_id !== item.asset_id && element.tradable);

        games[gameIndex].items = filteredGameItems;
        state.items = games;
    },
    DELETE_ITEM_FROM_OFFER: (state, item) => {
        let index = state.offerItems.findIndex((value) => {
            return value.asset_id === item.asset_id;
        });
        state.offerItems.splice(index, 1);
    },

    ADD_ITEM_TO_INVENTORY: (state, item) => {
        let game = state.gameIds[item.app_id];
        let items = state.items;
        let gameIndex = items.findIndex((element) => element.value === game);

        items[gameIndex].items.push(item);
        state.items = []; //need for sorting
        state.items = items;
    },
    SET_RECEIVE_PARAMS: (state, [key, value]) => {
        state[key] = value;
    },
    SET_TRADE_DETAILS: (state, details) => {
        state.trade_details = details;
    },
    SET_TRADE_EMAIL: (state, email) => {
        state.trade_email = email;
    },
};

const actions = {
    async getItems({ rootState, commit }, game) {
        let obj,
            name = '';

        let data = {
            version: state.wow_version ? state.wow_version.toLowerCase() : '',
            region: state.region ? state.region.toLowerCase() : '',
            game,
            faction: state.faction ? state.faction.toLowerCase() : '',
            server: state.server ? state.server.toLowerCase() : '',
        };

        switch (game) {
            case 'steam':
                name = 'STEAM';
                break;
            case 'dota':
                name = 'DOTA2';
                break;
            case 'cs':
                name = 'CSGO';
                break;
            case 'ft':
                name = 'TF2';
                break;
            case 'rust':
                name = 'RUST';
                break;
        }

        try {
            let res = await getInventory(data, rootState.auth.user.steam_id, rootState.auth.user.access_token);
            let offerItemsNames = state.offerItems.map((item) => item.market_hash_name);
            let items = res.data.filter((item) => !offerItemsNames.includes(item.market_hash_name));
            obj = {
                name: name,
                value: game,
                items: items,
                isError: false,
                code: null,
            };
        } catch (e) {
            obj = {
                name: name,
                value: game,
                items: [],
                isError: true,
                code: e.data.code,
            };
        }
        commit('SET_GAME_ITEMS', obj);
    },
    async setCountry([commit], country) {
        commit('SET_COUNTRY', country);
    },
    async setMessage({ commit }, message) {
        commit('SET_MESSAGE', message);
    },
    async clearOfferItems({ rootState, commit }) {
        commit('CLEAR_OFFER_ITEMS');
    },
    async setExtraGold({ commit }, extra) {
        commit('SET_EXTRA_GOLD', extra);
    },
    async setStep({ commit }, step) {
        commit('SET_STEP', step);
    },
    async deleteItem({ commit }, item) {
        commit('ADD_ITEM_TO_INVENTORY', item);
        commit('DELETE_ITEM_FROM_OFFER', item);
    },
    async setGame({ commit }, game) {
        commit('SET_GAME', game);
    },
    async setReferral({ commit }, referral) {
        commit('SET_REFERRAL', referral);
    },
    async setOFferId({ commit }, id) {
        commit('SET_OFFER_ID', id);
    },
    async addItemToOffer({ commit }, item) {
        commit('ADD_ITEM_TO_OFFER', item);
        commit('DELETE_ITEM_FROM_INVENTORY', item);
    },
    async setOfferId({ commit }, id) {
        commit('SET_OFFER_ID', id);
    },
    async setWowVersion({ commit }, version) {
        commit('SET_WOW_VERSION', version);
    },
    async setPopularity({ commit }, realm_is_unpopular) {
        commit('SET_REALM_UNPOPULAR', realm_is_unpopular);
    },
    async setRegion({ commit }, region) {
        commit('SET_REGION', region);
    },
    async addSelectedItemToOffer({ commit }, items) {
        items.forEach((el) => {
            commit('ADD_ITEM_TO_OFFER', el);
            commit('DELETE_ITEM_FROM_INVENTORY', el);
        });
    },
    async trade({ rootState, commit }, data) {
        let offer = data;
        data.cc = rootState.auth.user && rootState.auth.user.client_country ? rootState.auth.user.client_country : null;
        data.ip = rootState.auth.user && rootState.auth.user.ip ? rootState.auth.user.ip : null;
        data.ua = userService.userAgent();
        
        let payload = {
            items: state.offerItems.map((item) => {
                return {
                    app_id: parseInt(item.app_id),
                    asset_id: item.asset_id,
                    amount: item.amount,
                    currency_id: 1,
                    context_id: parseInt(item.context_id),
                };
            }),
            partner: String(rootState.auth.user.steam_id),
            link_partner: parseInt(rootState.auth.user.trade_partner_id),
            token: rootState.auth.user.trade_token,
            message: `${data.price} G - ${data.region} - S:${data.server} ${
                data.faction ? '- F:' + data.faction : ''
            } - GDM:${data.delivery_method === 'Auction House' ? 'AH' : data.delivery_method} - CN:${
                data.character_name
            } ${data.battlePetName ? '- BPN:' + data.battlePetName : ''}`,
        };
        console.log(JSON.stringify(payload,null,2))
        try {
            let res = null;
            await botTrade(payload, rootState.auth.user.access_token)
                .then((response) => {
                    res = response;

                    offer.offer_id = res.data['offer_id'];
                    state.offer_id = res.data['offer_id'];
                    window.open(res.data.link, '_blank');
                    sendTradeEmail(offer, rootState.auth.user.access_token).then(() => {
                        commit('CLEAR_OFFER_ITEMS');
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (e) {
            console.error(e);
            reject(error);
        }
    },
};

export default {
    state,
    mutations,
    actions,
};
