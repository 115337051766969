<template>
    <v-app id="app">
        <Header />
        <v-main>
            <router-view />
        </v-main>
        <Cookie />
        <Footer />
    </v-app>
</template>

<script>
import Header from './components/Header/';
import Footer from './components/Footer/';
import Cookie from './components/UI/cookie-badge.vue';

// change here
export default {
    mounted() {
        this.$store.dispatch('getInfo');
        this.onResize();
        window.addEventListener('resize', this.onResize, { passive: true });
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return;
        window.removeEventListener('resize', this.onResize, { passive: true });
    },
    name: 'App',
    components: {
        Header,
        Cookie,
        Footer,
    },
    data() {
        return {
            isMobile: false,
        };
    },
    methods: {
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
    },
    watch: {
        isMobile: function (n_val, o_val) {
            console.log(n_val);
            // this.$store.state.auth.isMobile = n_val
        },
        user: {
            handler() {
                if (user.steam_id) {
                    this.$store.dispatch('setGame', 'steam');
                    this.$store.dispatch('getItems', 'steam');
                }
            },
        },
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Trade-Raid.com - Buy WoW gold with Steam items',
        // all titles will be injected into this template
        titleTemplate: '%s - Trade-raid.com',
    },
};
</script>

<style>
.v-main {
    /* 
        primary color (new) : 202136
        primary color (old) : 20283c 
    */
    background: #202136;
}
.silver {
    color: #d2dbdb !important;
}
.mr-1 {
    margin-right: 1px;
}
.mr-2 {
    margin-right: 2px;
}
.mr-3 {
    margin-right: 3px;
}
.mr-4 {
    margin-right: 4px;
}
.mr-5 {
    margin-right: 5px;
}
.custom-modal-config {
    /** use this class to ensure if modals overlap top-header it is first */
    z-index: 1031!important;
}
</style>
