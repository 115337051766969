<template>
    <div class="order-modal">
        <v-card color="#202136">
            <v-card-title class="headline modal grey text-white background-color-gradient"> Order: {{ order.id }} </v-card-title>
            <v-card-text>
                <div class="trade-info-wrap">
                    <div class="order-created_at">
                        <ClockIcon />
                        {{ new Date(order.created_at).toLocaleDateString() }}
                        {{
                            new Date(order.created_at).toLocaleTimeString(language, {
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                        }}
                    </div>
                    <div class="order-status" :class="statusController(order.status).toLowerCase()">
                        {{ statusController(order.status) }}
                    </div>
                </div>
                <div class="deposit-details">
                    <div class="title">Deposit Details</div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Deposit Amount to pay</div>
                        <div class="v">
                            {{ abbreviateAmount(order.deposit.amount, 'standard', 'long') }}
                            <Coin
                                class="cost mr-1"
                                kind="gold"
                                style="width: 15px; height: auto"
                                :include_logo="false"
                            />
                        </div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Game</div>
                        <div class="v">
                            {{ order.deposit.game_info.version }}
                            <MMOGameIcon
                                v-if="gameType(order.deposit.game_info.version)"
                                class="game-icon-mmo"
                                :game="gameType(order.deposit.game_info.version)"
                            />
                        </div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Server - Faction</div>
                        <div class="v">{{ order.deposit.game_info.server }}</div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Region</div>
                        <div class="v">{{ order.deposit.game_info.region }}</div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Delivery Method</div>
                        <div class="v">{{ order.deposit.delivery_method.obj_type }}</div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Your Character</div>
                        <div class="v">{{ order.deposit.character_name }}</div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Trade Raid Character</div>
                        <div class="v">{{ order.exchange.receiver }}</div>
                    </div>
                </div>

                <div class="deposit-details">
                    <div class="title">Exchange Details</div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Exchange Amount to receive</div>
                        <div class="v">
                            {{ abbreviateAmount(order.exchange.amount, 'standard', 'long') }}
                            <Coin
                                class="cost mr-1"
                                kind="gold"
                                style="width: 15px; height: auto"
                                :include_logo="false"
                            />
                        </div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Game</div>
                        <div class="v">
                            {{ order.exchange.game_info.version }}
                            <MMOGameIcon
                                v-if="gameType(order.exchange.game_info.version)"
                                class="game-icon-mmo"
                                :game="gameType(order.exchange.game_info.version)"
                            />
                        </div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Server - Faction</div>
                        <div class="v">{{ order.exchange.game_info.server }}</div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Region</div>
                        <div class="v">{{ order.exchange.game_info.region }}</div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Delivery Method</div>
                        <div class="v">{{ order.exchange.delivery_method.obj_type }}</div>
                    </div>
                    <div class="trade-modal-info">
                        <div class="k secondary-color">Your Character</div>
                        <div class="v">{{ order.exchange.character_name }}</div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import MMOGameIcon from '../UI/GameIcons/mmo-icons.vue';
import Coin from '../UI/SVG/coin.vue';
import { ClockIcon, ArrowRightIcon } from 'vue-feather-icons';
import {
    MMOGameAlias,
    OrderStatusController,
    getOrderItemsAmount,
    OrderabbreviateAmount,
} from '../../utils/orderService.js';
export default {
    name: 'ExchangeOrderModal',
    props: {
        order: Object,
    },
    components: {
        MMOGameIcon,
        Coin,
        ClockIcon,
        ArrowRightIcon,
    },
    data() {
        return {
            language: null,
        };
    },
    watch: {},
    computed: {},
    methods: {
        gameType(game) {
            if (game === 'Final Fantasy XIV') {
                this.MMOGameIconWidth = '100';
            } else {
                this.MMOGameIconWidth = '50';
            }
            return MMOGameAlias(game);
        },
        statusController(status) {
            return OrderStatusController(status);
        },
        getOrderItems(items, n) {
            return getOrderItemsAmount(items, n);
        },
        abbreviateAmount(number, notation, compactDisplay) {
            return OrderabbreviateAmount(number, notation, compactDisplay);
        },
    },
    mounted: function () {
        this.language = navigator.language;
    },
};
</script>

<style scoped>
* {
    color: #f1f1f1;
}
.accepted {
    color: #79c175;
    background-color: #3d4442;
}
.pending {
    color: #fad303;
    background-color: #a59233;
    height: 50%;
}
.declined {
    color: #dc6e6f;
    background-color: #53484e;
}
.order-status {
    padding: 5px;
    border-radius: 5px;
    min-width: 85px;
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
}

.trade-info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 15px;
    gap: 5px;
}
.secondary-color {
    color: hsla(0, 0%, 100%, 0.3) !important;
}
.order-created_at {
    display: flex;
    align-items: center;
    gap: 15px;
}
.deposit-details-wrap,
.exchange-details-wrap {
    display: flex;
}
.deposit-details-wrap > div,
.exchange-details-wrap > div {
    display: flex;
    gap: 5px !important;
    align-items: center;
    cursor: pointer;
}

.deposit-details-wrap > div:hover,
.exchange-details-wrap > div:hover {
    font-size: bold;
    color: #17d0c1;
}
.delivery-method {
    display: flex;
    gap: 5px;
}
.trade-modal-info {
    display: flex;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
}
.trade-modal-info > .v {
    display: flex;
    gap: 5px;
    align-items: center;
}
.background-color-gradient {
    background-image: linear-gradient(#5761ff, #4b90ff)!important;
}

</style>
