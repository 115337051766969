<template>
    <v-container fill-height class="profile-container" style="margin-top: 100px; margin-bottom: 100px">
        <v-row class="d-flex justify-center">
            <v-col cols="8">
                <v-card>
                    <v-form ref="update_profile_form" lazy-validation v-model="valid" @submit.prevent="submit">
                        <v-card-title class="headline d-flex justify-center">
                            <span class="text-h5 card-title">Update your Trade-Offer Link</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-card-text class="modal text-white">
                                    <p class="text-center">
                                        If you are having issues receiving trade offers or if you have changed your
                                        Trade Offer Link, please input your new link down below.<br />
                                        Trade-Raid will not be able to trade your STEAM Inventory to MMO Gold if your
                                        Trade-Offer link is wrong.
                                        <br />
                                        <span> Retrieve your current Trade Offer Link </span>
                                        <a
                                            :href="`https://steamcommunity.com/profiles/${user.steam_id}/tradeoffers/privacy#trade_offer_access_url`"
                                            class="ext_link"
                                            target="_blank"
                                        >
                                            HERE
                                        </a>
                                    </p>
                                </v-card-text>
                                <v-row class="d-flex justify-center">
                                    <v-col cols="12" sm="8" md="8">
                                        <div style="width: 70%; margin: 0 auto; color: white">
                                            <v-text-field
                                                v-model="url"
                                                filled
                                                label="Trade Offer Link"
                                                outlined
                                                class="text-white"
                                                color="#fff"
                                                light
                                                required
                                            ></v-text-field>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn
                                class="d-flex justify-center update-profile-btn"
                                text
                                type="submit"
                                @click="saveLink()"
                            >
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="tradeOfferDialog" persistent max-width="600">
            <v-card color="#383F51">
                <v-card-title class="white--text justify-center"> Trade-Offer Link changed! </v-card-title>
                <v-card-text class="white--text text-center">
                    Your Trade-Offer Link have now been changed! Go back and try to trade again.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn block color="info" elevation="2" raised rounded @click="navigateToTradePage">
                        Go back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Countries from '@/assets/json/countries.json';
import axios from 'axios';
import store from '../../store';
import { setTradeOfferInfo } from '../../api/auth';

export default {
    props: {
        dialog: Boolean,
        user: Object,
    },
    computed: {
        ...mapGetters(['user', 'token']),
    },
    data() {
        return {
            url: '',
            valid: false,
            username: '',
            snackbar: false,
            text: 'Profile updated successfully',
            is_unique_username: true,
            username_error_message: '',
            is_submitting: false,
            tradeOfferDialog: false,
            rules: {
                required: (value) => !!value || 'Input field is required!',
            },
        };
    },
    mounted() {
        if (this.user.email) {
            (this.country = this.user.country), (this.username = this.user.username);
            this.email = this.user.email;
        }
        this.countries = Countries.map((country) => {
            return country.name;
        });
    },
    methods: {
        async saveLink() {
            (this.tradeOfferDialog = true), console.log(this.url);
            await setTradeOfferInfo(this.user._id, { link: this.url }, this.user.access_token)
                .then(() => {
                    this.$store.dispatch('getInfo');
                    setTimeout(() => this.$router.push({ name: 'Trade' }), 5000);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async submit() {
            if (this.$refs.update_profile_form.validate()) {
                this.is_submitting = true;
                if (this.is_valid_email_domain) {
                    var data = { username: this.username, country: this.country, email: this.email };
                    var url = process.env.VUE_APP_BACKEND + `/api/user/update-profile`;

                    await axios
                        .post(
                            url,
                            { data: data },
                            {
                                headers: { Authorization: `Bearer ${this.token}` },
                            },
                        )
                        .then((response) => {
                            this.is_submitting = false;
                            response = response.data;

                            if (response.status == 'error') {
                                for (var messageKey in response.message) {
                                    var message = [...response.message[messageKey]].shift();

                                    if (messageKey == 'email') {
                                        this.is_unique_email = false;
                                        this.email_error_message = message;
                                    } else if (messageKey == 'username') {
                                        this.is_unique_username = false;
                                        this.username_error_message = message;
                                    }
                                }
                                this.$refs.update_profile_form.validate();
                            } else {
                                this.snackbar = true;
                                this.is_unique_username = true;

                                store.commit('SET_USER', response);
                            }
                        })
                        .catch((error) => {
                            this.is_submitting = false;
                        });
                }
            }
        },
        updateUserNameErrorMessage() {
            this.is_unique_username = true;
            this.username_error_message = '';
            this.is_valid_email_domain = true;
            this.$refs.update_profile_form.validate();
        },
        navigateToTradePage() {
            this.$router.push({ name: 'Trade' });
        },
    },
};
</script>

<style>
.profile-container input::placeholder {
    font-size: 13px !important;
    line-height: 18px !important;
    color: rgb(255, 255, 255) !important;
}
.profile-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.profile-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
.profile-container .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #fff !important;
}
.profile-container .theme--light.v-select .v-select__selection--comma {
    color: #fff;
}
.profile-container .v-menu__content .v-list {
    background: #fff;
}
.profile-container .theme--light.v-input input {
    color: #fff;
}
.profile-container label.v-label.v-label--active.theme--light {
    color: #fff;
}
</style>

<style lang="scss" scoped>
.modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
.modal.grey {
    background: #555c70;
}
.text-white {
    color: #fff !important;
}
.ext_link {
    color: #17d0c1;
    text-decoration: underline;
}

.ext_link:hover {
    color: #17d0c1 !important;
    opacity: 0.5;
}
modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
.text-white >>> .v-label {
    color: #fff;
}
.text-white >>> input {
    color: #fff;
}
.profile-container {
    flex-basis: 1;

    .v-card {
        background: #383f51;
        border-radius: 3px;
        border-width: 0;
        height: 100%;

        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
        }

        .v-card__text {
            padding: 30px 20px;
            overflow: auto;
        }

        .update-profile-btn,
        .create-profile-btn {
            font-family: Open Sans !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 13px !important;
            line-height: 18px !important;
            text-align: center !important;
            text-transform: uppercase !important;
            text-decoration: none !important;
            color: #ffffff !important;
            background: #17d0c1 !important;
            box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
            border-radius: 3px !important;
            padding: 0 30px !important;
        }
    }
}
</style>
