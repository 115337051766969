<template>
    <v-container fluid fill-height id="about">
        <!-- Det er dette der afgør hvilken CSS den skal bruge -->
        <h1 class="page-title">Trade-Raid Support</h1>
        <p class="page-sub-title">
            Do you need help with your current order, having issues creating one or simply want to reach out to us?
        </p>
        <p>Trade-Raid Support can be contacted through our Live Chat & Discord.</p>
        <p>Get in touch with our Live Chat at the bottom right corner. The Live Chat is for general inquiries.</p>
        <p>
            Our Live Chat turns into a ticket/e-mail system outside our opening hours. We'll get back to you inquiry as
            soon as we can!
        </p>
        <p>Trade-Raid's Live Chat is available between 9:00 AM - 5:00 PM Monday - Friday.</p>
        <p>Trade-Raid's Discord Support: Trade-Raid Support#8652. Discord Support is primarily for active orders.</p>
        <p class="page-sub-title">Our Support speaks Danish and English.</p>
    </v-container>
</template>

<script>
export default {
    name: 'Support',
    metaInfo: {
        title: 'Support',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Trade-Raid Support. Contact Trade-Raid Support through our Facebook Live Chat or by Mail. Our Support is available 24/7',
            },
        ],
    },
};
</script>

<style lang="scss" scoped>
#about {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    .page-title {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    .page-sub-title {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
    }
    p {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 3px;
        a {
            color: #fff;
        }
    }
    .about-container {
        width: 100%;
        max-width: 1530px;
        .image-container {
            width: 100%;
            height: 100%;
            max-height: 400px;
            min-height: 400px;
            margin-bottom: 80px;
            background: url('https://trade-raid-com.mo.cloudinary.net/dota.jpeg') no-repeat;
            background-size: cover;
            background-position: 0 -108px;
        }
        .info-container {
            h2 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #fff;
            }
            .desc {
                font-size: 18px;
                line-height: 35px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
            }
            a {
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: Open Sans;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                color: #ffffff;
                text-decoration: none;
                padding: 13px 30px;
                display: inline-block;
            }
            a + a {
                margin-left: 20px;
            }
        }
    }
}
@media screen and (max-width: 960px) {
    #about .about-container .info-container h2,
    #about .about-container .info-container .desc {
        text-align: center;
    }
}
</style>
