var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-info v-lazy"},[_c('div',{staticClass:"section-title"},[_vm._v("Award Track")]),_c('div',{staticClass:"section",staticStyle:{"color":"white"}},[_c('div',{staticClass:"icon-text-section steam"},[_c('AwardIcon',{class:{
                    'primary-color-info': _vm.computedAwards.connectSteam,
                    'secondary-color-text': !_vm.computedAwards.connectSteam,
                }}),_c('p',{staticClass:"secondary-color-text"},[_vm._v("Connect with Steam")])],1),_c('ConnectSteam'),_c('div',{staticClass:"icon-text-section"},[_c('AwardIcon',{class:{
                    'primary-color-info': _vm.computedAwards.connectEmail,
                    'secondary-color-text': !_vm.computedAwards.connectEmail,
                }}),_c('p',{staticClass:"secondary-color-text"},[_vm._v("Connect with Email")])],1),_c('div',{staticClass:"icon-text-section"},[_c('AwardIcon',{class:{
                    'primary-color-info': _vm.computedAwards.applyTradeLink,
                    'secondary-color-text': !_vm.computedAwards.applyTradeLink,
                }}),_c('p',{staticClass:"secondary-color-text"},[_vm._v("Add Trade Link")])],1),_c('div',{staticClass:"icon-text-section"},[_c('AwardIcon',{class:{
                    'primary-color-info': _vm.computedAwards.tradeES,
                    'secondary-color-text': !_vm.computedAwards.tradeES,
                }}),_c('p',{staticClass:"secondary-color-text"},[_vm._v("Complete a trade on the Exchange Service")])],1),_c('div',{staticClass:"icon-text-section"},[_c('AwardIcon',{class:{
                    'primary-color-info': _vm.computedAwards.tradeTS,
                    'secondary-color-text': !_vm.computedAwards.tradeTS,
                }}),_c('p',{staticClass:"secondary-color-text"},[_vm._v("Complete a trade on the Trade Service")])],1),_c('div',{staticClass:"icon-text-section"},[_c('AwardIcon',{class:{
                    'primary-color-info': _vm.computedAwards.shopOrder,
                    'secondary-color-text': !_vm.computedAwards.shopOrder,
                }}),_c('p',{staticClass:"secondary-color-text"},[_vm._v("Place an order in our shop")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }