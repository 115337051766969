const getters = {
    token: (state) => state.auth.token,
    user: (state) => state.auth.user,
    step: (state) => state.trade.step,
    mobile: (state) => state.auth.mobile,
    ip: (state) => state.auth.user.ip,
    client_country: (state) => state.auth.user.client_country,
    is_realm_unpopular: (state) => state.trade.realm_is_unpopular,
    combine_user_id: (state) => state.trade.combine_user_id,
    offer_id: (state) => state.trade.offer_id,
    referral: (state) => state.trade.referral,
    referral_tgbs: (state) => state.trade.referral_tgbs,
    inventory: (state) => state.trade.items,
    extra_gold: (state) => state.trade.extra_gold,
    steamName: (state) => state.steam.steamName,
    receive: (state) => {
        return {
            method: state.trade.method,
            characterName: state.trade.characterName,
            battlePetName: state.trade.battlePetName,
        };
    },
    items(state) {
        return (value) => {
            if (value && state.trade.items) {
                return state.trade.items.filter((item) => {
                    return item.value === value;
                })[0];
            }
            return [];
        };
    },
    offerItems: (state) => state.trade.offerItems,
    selectedGame: (state) => state.trade.selectedGame,
    gameData: (state) => {
        return { wowVersion: state.trade.wow_version, region: state.trade.region };
    },
    errors: (state) => state.errors,
    showInfoTradeModal: (state) => state.trade.showInfoTradeModal,
    showLoader: (state) => state.trade.loading,
    trade_details: (state) => state.trade.trade_details,
    trade_email: (state) => state.trade.trade_email,
    aside: (state) => state.shop.aside,
    cartItems: (state) => state.shop.cartItems,
    modalState: (state) => state.shop.modalState,
};

export default getters;
