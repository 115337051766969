import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VDialog,{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"#383F51"}},[_c(VCardTitle,{staticClass:"white--text"},[_c('span',{staticClass:"text-h5"},[_vm._v("Trade Service Maintenance")])]),_c(VCardText,{staticClass:"white--text"},[_vm._v(" We are dealing with technical issues at our Trade Service. "),_c('br'),_vm._v("As a result, the service has been temporarily taken down for maintenance. "),_c('br'),_vm._v("Users that seek to trade their Steam Items to Gold are recommended to add Trade-Raid on "),_c('a',{staticStyle:{"color":"#17d0c1"},attrs:{"href":"https://steamcommunity.com/id/wowgoldtrader/"}},[_vm._v("Steam ")]),_vm._v(" in the meantime. "),_c('br'),_vm._v("Exchange Service and Reward Activities are unaffected and operating normally. ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }