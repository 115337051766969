<template>
    <div class="order-callback">
            <div class="title jc">Something went wrong with your order, please try again or contact support.</div>
    </div>
</template>

<script>
import { orderController } from '@/api/shop.js';
import './utility.styles.css';
export default {
    data() {
        return {
            data: null,
        };
    },
    methods: {},
    computed: {

    },
    watch: {},
    mounted: async function () {
        const params = new URLSearchParams(document.location.search);
        const txt_token = params.get('q');
        await orderController(txt_token, 'DECLINED').then((res) => {
            this.data = res.data;
            if(res && res.data && res.data.order && res.data.order.client_items) {
                this.$store.state.shop.cartItems = res.data.order.client_items
            }
        
        }).catch((err) => {
            this.$store.state.shop.cartItems = [];
        });
    },
};
</script>
