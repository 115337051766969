<template>
    <div class="wrap">
        <header id="header" class="nav defaultscroll sticky">
            <div class="bold steps">
                Step
                <div class="active-step">{{ getStep }}</div>
                of {{ maxSteps }}
            </div>
            <div class="bold gold">
                {{ itemsCost.gold }}
                <Coin class="cost mr-1" kind="gold" style="width: 15px; height: auto" :include_logo="false" />
            </div>
            <div class="controls">
                <ChevronLeftIcon @click="decrementStep()" class="icon cursor hover" />
                <ChevronRightIcon @click="incrementStep()" class="icon cursor hover" />
            </div>
            <div id="progress">
                <div id="bar"></div>
            </div>
        </header>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PriceService from '../../utils/PriceService.js';
import Coin from '../../components/UI/SVG/coin.vue';
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons';
export default {
    name: 'ProgressNavigation',
    props: {},
    components: {
        Coin,
        ChevronRightIcon,
        ChevronLeftIcon,
    },
    data() {
        return {
            max: 3,
            min: 1,
            progress: 1,
            step_progress: 0,
            width: 33,
            step_op: 'increment',
        };
    },
    computed: {
        ...mapGetters(['offerItems', 'receive', 'selectedGame', 'getSteamUserName', 'errors']),
        itemsCost() {
            let price = this.offerItems.reduce(
                (accumulator, currentValue) => accumulator + currentValue.steam_price,
                0,
            );
            return PriceService.calculatePrice(
                price,
                this.$store.state.trade.country,
                this.$store.state.trade.extra_gold[1],
            );
        },
        getStep() {
            return this.$store.state.trade.step;
        },
        maxSteps() {
            return this.max;
        },
    },
    methods: {
        incrementStep() {
            if (this.$store.state.trade.step === 1) {
                this.$store.dispatch('getItems', this.selectedGame).then(() => {
                    this.$store.commit('SET_LOADING', false);
                });
            }
            this.step_op = 'increment';

            if (this.$store.state.trade.step < this.max) {
                this.$store.state.trade.step += 1;
                this.step_progress += 33;
                return;
            } else {
                this.step_progress = 100;
                this.$store.state.trade.step = this.max;
            }
        },
        decrementStep() {
            this.$store.dispatch('getItems', this.selectedGame || 'steam').then(() => {
                this.$store.commit('SET_LOADING', false);
            });
            this.step_op = 'decrement';
            if (this.$store.state.trade.step > this.min) {
                this.$store.state.trade.step -= 1;
                this.step_progress -= 33;
                return;
            } else {
                this.$store.state.trade.step = this.min;
            }

            if (this.$store.state.trade.step === 2) {
                this.step_progress = 66;
            }
        },
        progressBar(step_progress, op) {
            const fps = 10;
            if (this.width >= 100 && op === 'increment') {
                return;
            }
            const elem = document.getElementById('bar');

            let id = setInterval(() => {
                if (
                    (this.width >= step_progress && op === 'increment') ||
                    (this.width <= step_progress && op === 'decrement' && this.$store.state.trade.step >= 1)
                ) {
                    clearInterval(id);
                }

                if (op === 'increment' || this.width <= 33) {
                    this.width++;
                }

                if ((op === 'decrement' && this.width >= 0) || this.width <= 33) {
                    i++;
                    this.width--;
                    if (this.width < 0) {
                        clearInterval(id);
                    }
                }

                elem.style.width = this.width + '%';
                const color = this.$store.state.trade.step === 3 ? '#2eca8b' : '#17d0c1';
                elem.style.backgroundColor = color;
            }, fps);
        },
    },
    watch: {
        step_progress: {
            deep: true,
            handler(val, oval) {
                if (val > oval) {
                    this.progressBar(Math.abs(val), 'increment');
                }
                if (oval > val) {
                    this.progressBar(Math.abs(val), 'decrement');
                }
            },
        },
    },
    mounted: function () {
        this.step_progress = 33;
        this.width = 33;
    },
};
</script>
<style scoped lang="scss">
#progress {
    width: 93.5%;
    height: 15px;
    background-color: grey;
    position: absolute;
    bottom: 0;
}
#bar {
    width: 0%;
    height: 15px;
    background-color: #17d0c1;
    border-right: 1px solid rgba(0, 0, 0, 0.87);
}
.steps {
    display: flex;
    gap: 5px;
}
.nav {
    color: #f1f1f1;
    background-color: #555c70;
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: space-around;
    width: 93.5%;
    padding: 15px 15px 15px 15px;
}
header {
    border-radius: 2px 2px 0px 0px;
}
// .finished {
//     background-color:
// }
.wrap {
    // height: 100%;
    width: 100%;
    position: fixed;
    margin-right: 15px;
    z-index: 100;
}
.white {
    color: #f1f1f1;
}

.hover:hover {
    opacity: 0.9;
}
.controls {
    display: flex;
    gap: 15px;
}
.controls > div {
    font-weight: bold;
}
.gold {
    display: flex;
    gap: 5px;
}

.bold {
    font-weight: bold;
}
.cursor {
    cursor: pointer;
}
</style>
