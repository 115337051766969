<template>
    <div class="container">
        <div class="layout-shop-container">
            <div class="aside">
                <ul>
                    <li
                        v-for="(filter, index) in sideFilters"
                        :key="index"
                        @click="setActiveFilter(filter)"
                        :class="{ active: filter === activeSideFilter }"
                    >
                        {{ filter }}
                    </li>
                </ul>
            </div>
            <ShopProducts
                v-if="product_display"
                :propProducts="product_display"
                :game="activeSideFilter"
                :propProductFilters="product_display_filters"
            />
            <div v-else class="loader">
                   <Loader  />
            </div>
         
        </div>
    </div>
</template>

<script>
import ShopProducts from './products.vue';
import {getShop} from '../../api/shop.js';
import Loader from '../UI/loader.vue';
import {services} from '../../utils/productService';
export default {
    name: 'tr-shop',
    components: {
        ShopProducts,
        Loader
    },
    data() {
        return {
            product_display: null,
            product_display_filters: [],
            sideFilters: ['All','Dragonflight Retail', 'WOTLK Classic', 'Final Fantasy XIV', /** 'Classic Era',*/ 'Lost Ark'],
            activeSideFilter: 'All',
            products: null,
        };
    },
    watch: {
        product_display: {
            handler(val,oval) {
                this.setActiveFilter(this.activeSideFilter);
            }
        }
    },
    computed: {
      
    },
    methods: {
        async setActiveFilter(filter) {
            this.activeSideFilter = filter;
            if(filter === 'All') {
                
            }
            this.product_display = this.products[filter];
            this.product_display_filters = [];
            for (const entry of this.products[filter]) {
                if (!this.product_display_filters.includes(entry.type)) {
                    this.product_display_filters.push(entry.type);
                }
            }
        },
        async getAllProducts() {
            const allProducts = (await getShop()).data;
            this.products = {
                'All': [],
            }
            for(const service of services) {
                if(service.active) {
                    this.products['All'].push(service);
                }
            }
            
            for(const product of allProducts.data) {
                if(!this.products[product.game]) {
                    this.products[product.game] = []
                }
                this.products['All'].push(product)
                this.products[product.game].push(product);
            }
        }
    },
    beforeCreate: function () {},
    mounted: async function () {
        await this.getAllProducts();
        await this.setActiveFilter(this.activeSideFilter);
    },
};
</script>

<style scoped lang="scss">
@media (max-width: 500px) {
    .aside {
        display: flex;
    }
}
.aside {
    color: #f1f1f1;
    font-size: 16px;
}
.loader {
    margin: auto;
    background-color: #383f51;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aside > ul {
    list-style-type: none;
}

.aside > ul > li {
    cursor: pointer;
    width: 175px;
    padding: 8px;
    padding-left: 15px;
    white-space: nowrap;
}

.aside > ul > li:hover {
    color: rgb(192, 192, 214);
}
.title {
    color: #f1f1f1 !important;
    font-weight: bold !important;
    font-size: 50px !important;
}

.layout-shop-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 5fr;
    grid-template-areas: 'sidebar content';
    max-width: 1700px;
    min-width: 1700px;
}

// small screens
@media (max-width: 500px) {
    .layout-shop-container {
        display: flex;
        flex-direction: column;
    }
}
.active {
    color: rgb(255, 255, 255);
    background-color: rgba(87, 97, 255, 0.1);
    border-color: rgb(87, 97, 255);
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    position: relative;
}
.tags,
.filter-tags {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.filter-tags {
    cursor: pointer;
    gap: 15px;
    font-size: 2rem !important;
    color: #f1f1f1;
}
.filter {
    background-image: linear-gradient(#5761ff, #4b90ff);
    color: #f1f1f1;
}
.tag,
.filter {
    padding: 5px;
    color: black;
    font-weight: bold;
    font-size: 0.9rem;
    background-color: #4b90ff;
    border-radius: 5px;
}

.container {
    display: flex;
    align-items: center;
    margin-top: 125px;
    margin-bottom: 250px;
    flex-direction: column;
}

@media (max-width: 500px) {
    .layout-shop-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 400px;
        min-width: 400px;
    }
    .aside {
        display: flex;
        flex-direction: row;
    }
    .container {
     display: block;
     align-items: center;
    }
}

</style>
