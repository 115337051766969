<template>
    <v-col class="user-offer-container" cols="12">
        <v-card outlined>
            <h1 style="display: none">Trade Service</h1>
            <v-card-title class="d-flex justify-space-between">
                <span class="card-title">You offer</span>
                <span class="items-count">{{ `${offerItems.length}` }} <span>items</span></span>
            </v-card-title>

            <v-card-text class="d-flex align-stretch">
                <v-row>
                    <v-col cols="12" v-if="!offerItems.length" class="d-flex justify-center align-center">
                        <div class="small-text">No items chosen</div>
                    </v-col>

                    <user-offer-item v-else v-for="(item, index) in offerItems" :key="index" :item="item" />
                </v-row>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
import UserOfferItem from '../UserOfferItem/';
import { mapGetters } from 'vuex';

export default {
    components: {
        UserOfferItem,
    },
    computed: {
        ...mapGetters(['offerItems']),
    },
    data: () => ({
        items: [
            {
                name: 'bs',
                logo: 'https://trade-raid-com.mo.cloudinary.net/item.png',
                cost: 210000,
            },

            {
                name: 'fn',
                logo: 'https://trade-raid-com.mo.cloudinary.net/item.png',
                cost: 3000,
            },

            {
                name: 'mw',
                logo: 'https://trade-raid-com.mo.cloudinary.net/item.png',
                cost: 3000,
            },
        ],
    }),
};
</script>

<style>
.v-card__text::-webkit-scrollbar {
    width: 6px;
}

.v-card__text::-webkit-scrollbar-track {
    background: #43495a;
}

.v-card__text::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
}
</style>

<style lang="scss" scoped>
.user-offer-container {
    max-height: calc(245px + 24px);
}
.user-offer-container {
    flex-basis: 1;

    .v-card {
        background: #383f51;
        border-radius: 3px;
        border-width: 0;

        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;

            .card-title {
                font-weight: 600;
            }

            .items-count span {
                color: #aaadb7;
            }
        }

        .v-card__text {
            padding: 30px 20px;
            height: 185px;
            overflow: auto;

            .small-text {
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                color: rgba(255, 255, 255, 0.5);
                text-align: center;
                margin-bottom: 0;
            }
        }
    }
}
</style>
