<template>
    <v-col @mouseleave="hideMenu($event)" @contextmenu.prevent="clickRight" cols="6" sm="4" md="3" lg="4" xl="3">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            min-width="200px"
            max-width="200px"
            offset-y
            :position-x="x"
            :position-y="y"
            absolute
        >
            <template v-slot:activator="{ on }">
                <v-card
                    class="item-container"
                    :disabled="item.price < 0.04 || !item.tradable"
                    outlined
                    @click="clickLeft(item)"
                >
                    <div ref="container">
                        <div class="bg-blur" />
                        <v-card-title class="d-flex justify-space-between">
                            <span class="name">{{ itemName }}</span>
                        </v-card-title>

                        <v-card-text>
                            <div class="image-container">
                                <img class="logo" :src="item.image" alt="item images" />
                            </div>
                            <div
                                class="d-flex justify-space-between"
                                v-if="item.steam_price !== null && item.steam_price > 0 && item.price >= 0.04"
                            >
                                <div class="cost-container">
                                    <!-- <span class="cost"
                                        ><span v-if="itemPrice.currency !== 'GOLD'">{{ itemPrice.currency }}</span
                                        >{{ itemPrice.gold }}
                                    </span> -->
                                    <span class="cost" :class="item.type" style="margin-right: 5px">
                                        <span v-if="itemPrice.currency !== 'GOLD'" class="from-tag"> from </span>
                                        <span v-if="itemPrice.currency !== 'GOLD'">
                                            {{ itemPrice.currency }}
                                        </span>
                                        <span >
                                            {{ itemPrice.gold }}
                                        </span>
                                    </span>
                                    <Coin
                                        v-if="item.currency === 'GOLD'"
                                        class="cost"
                                        kind="gold"
                                        style="width: 10px; height: auto"
                                        :include_logo="false"
                                    />
                                    <span
                                        v-if="itemPrice.silver > 0 && gamesWithSilver && itemPrice.gold < 100"
                                        class="cost silver"
                                        >{{ itemPrice.silver }}</span
                                    >
                                    <Coin
                                        class="cost"
                                        v-if="itemPrice.silver > 0 && gamesWithSilver && itemPrice.gold < 100"
                                        kind="silver"
                                        style="width: 10px; height: auto"
                                        :include_logo="false"
                                    />
                                    <!-- <EventBadge type="e-gold-text" percentage="+15%" v-if="itemPrice.currency === 'GOLD'" /> -->
                                </div>
                            </div>
                            <div v-else-if="item.steam_price !== null && item.steam_price === -1"></div>
                        </v-card-text>
                    </div>
                </v-card>
            </template>
            <div class="menu-container">
                <p>
                    {{ item.name.split('|')[0] || '' }} <br />
                    {{ item.name.split('|')[1] || '' }}
                </p>
                <span>
                    <img
                        v-if="itemPrice.currency !== 'GOLD'"
                        class="logo"
                        src="https://trade-raid-com.mo.cloudinary.net/fiat-payment-cards.png"
                        alt="buy mmo gold"
                    />
                </span>
                <div class="d-flex justify-space-between">
                    <div class="cost-container d-flex align-center item-info-rc" v-if="item.steam_price > 0">
                        <span class="cost" style="margin-right: 5px">
                            <span v-if="itemPrice.currency !== 'GOLD'" class="from-tag"> from </span>
                            <span v-if="itemPrice.currency !== 'GOLD'">
                                {{ itemPrice.currency }}
                            </span>
                            <span>
                                {{ itemPrice.gold }}
                            </span>
                        </span>
                        <Coin
                            v-if="item.currency === 'GOLD'"
                            class="cost"
                            kind="gold"
                            style="width: 10px; height: auto; margin-right: 5px"
                            :include_logo="true"
                        />
                        <span
                            v-if="itemPrice.silver > 0 && gamesWithSilver && itemPrice.gold < 100"
                            style="margin-right: 5px"
                            class="cost silver"
                            >{{ itemPrice.silver }}</span
                        >
                        <Coin
                            class="cost"
                            v-if="itemPrice.silver > 0 && gamesWithSilver && itemPrice.gold < 100"
                            kind="silver"
                            style="width: 10px; height: auto; margin-right: 5px"
                            :include_logo="true"
                        />
                        <!-- <EventBadge type="e-gold-text" percentage="+15%" v-if="itemPrice.currency === 'GOLD'" />
                         -->
                    </div>
                    <div v-if="item.exterior !== null" class="exterior-info">
                        {{ item.exterior }}
                    </div>
                </div>
            </div>
        </v-menu>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBadge from '../UI/event-badge';
import PriceService from '../../utils/PriceService.js';
import Coin from '../UI/SVG/coin.vue';

export default {
    components: {
        EventBadge,
        Coin,
    },
    props: {
        item: Object,
        disabledItems: Boolean,
    },
    computed: {
        ...mapGetters(['offerItems', 'inventory']),
        itemPrice() {
            return PriceService.calculatePrice(
                this.item.steam_price,
                this.$store.state.trade.country,
                this.$store.state.trade.extra_gold[1],
                this.item.currency,
            );
        },
        gamesWithSilver() {
            return PriceService.gamesWithSilver(this.$store.state.trade.wow_version);
        },
        itemName() {
            let name = '';
            if (
                (this.item.steam_price > 0 && this.item.price >= 0.04 && this.item.tradable === true) ||
                this.item.steam_price === -1
            ) {
                name = this.item.name;
            }
            if (!this.item.tradable) {
                name = 'Trade locked';
            }
            if ((this.item.steam_price !== null && this.item.price < 0.04) || this.item.price === 0) {
                name = 'Too low price';
            }
            if (this.item.steam_price === null) {
                name = 'Unavailable';
            }
            if (this.item.type === 'display-item') {
                name = `Buy ${this.$store.state.trade.wow_version} Gold`;
            }
            return name;
        },
    },
    data: () => ({
        menu: false,
        x: 0,
        y: 0,
        activeDisplay: false,
    }),
    methods: {
        hideMenu(e) {
            this.menu = false;
        },
        clickLeft(item) {
            if (item.edge) {
                this.$store.commit('SET_KEY_VALUE_PAIR', ['currentProductId', item._id]);
                this.$router.push(item.edge);
                return;
            }
            if (this.disabledItems) {
                this.$store.commit('SHOW_INFO_TRADE_MODAL', true);
            } else {
                if (item.price >= 0.04) {
                    this.$store.dispatch('addItemToOffer', item);
                }
            }
        },
        clickRight(e) {
            const left = this.$refs['container'].getBoundingClientRect().left;
            const top = this.$refs['container'].getBoundingClientRect().top;
            const height = this.$refs['container'].getBoundingClientRect().height;
            const width = this.$refs['container'].getBoundingClientRect().width;
            this.x = left + width + 5;
            this.y = top + height - 180;
            this.menu = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.from-tag {
    color: #c0c0d6!important;
}
.display-item {
    color: #f1f1f1!important;
    font-size: 12px!important;
}
@media screen and (max-width: 820px) {
    .event-badge {
        position: absolute;
        top: 0;
    }
}
.exterior-info {
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    height: 26px !important;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #fff;
    padding: 5px 6px;
    background-color: #777d8d !important;
    min-width: 50px;
}

.v-card__text {
    position: relative;
    z-index: 1;
    padding-top: 18px;
    padding-bottom: 18px;
}

.item-container {
    background: linear-gradient(37.73deg, #555c70 -19.82%, #51586c 66.45%);
    border-radius: 2px;
    position: relative;
    overflow: hidden;
    min-height: 140px;
    .v-card__title {
        padding-top: 5px;
        padding-bottom: 0;
    }
    .v-card__text {
        padding-bottom: 10px;
    }
    .bg-blur {
        background: #81899e;
        height: calc(50% - 25px);
        width: calc(100% - 25px);
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        filter: blur(25px);
        transform: translate(-50%, -50%);
        border-radius: 100% 100% !important;
        z-index: 0;
    }
    .name {
        display: inline-block;
        width: 100%;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.8;
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .count {
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #ffffff;
        opacity: 0.8;
    }
    .logo {
        width: 100%;
    }
    .item-info-rc {
        padding-top: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
    }
    .cost-container {
        width: 100%;
        padding-top: 15px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 5px;
        img {
            margin-right: 5px;
        }
    }
    .cost {
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #fcc938;
    }
}
.v-card__title {
    padding-bottom: 10px;
    padding-top: 10px;
}
.v-menu__content {
    margin-top: 5px;
    contain: initial;
    overflow: visible;
}
.v-menu__content::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    transform: translate(-50%, -2px);
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 6px solid #51586c;
    width: 14px;
    height: 6px;
}
.menu-container {
    padding: 14px;
    background: #51586c;
    border-radius: 2px;
    p {
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #ffffff;
    }
    .cost {
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        color: #ffffff;
    }
}
.v-menu--attached {
    position: relative;
    .v-menu__content {
        top: 0 !important;
    }
}
.v-btn.v-btn--depressed {
    height: 26px !important;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #ffffff;
    padding: 5px 6px;
    background-color: #777d8d !important;
}
.image-container {
    height: 78px;
    width: 100%;
    margin-bottom: 0;
    img {
        object-fit: contain;
        height: 100%;
    }
}
</style>
