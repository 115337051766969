<template>
    <v-container fill-height class="transactions-container">
        <SideNav class="sidenav-layout" />
        <v-col class="transactions-wrapper">
            <div class="section-title">Orders</div>
            <div class="sub-menu-nav">
                <div
                    class="btn trade-orders"
                    :class="{ active: active === 'ts' }"
                    value="ts"
                    @click="getOrders($event)"
                >
                    Trade Service Orders
                </div>
                <div
                    class="btn exchange-orders"
                    :class="{ active: active === 'es' }"
                    value="es"
                    @click="getOrders($event)"
                >
                    Exchange Orders
                </div>
                <div
                    class="btn shop-orders"
                    :class="{ active: active === 'shop' }"
                    value="shop"
                    @click="getOrders($event)"
                >
                    Shop Orders
                </div>
            </div>
            <div class="transactions-table">
                <ExchangeOrders v-if="active === 'es'" />
                <TradeOrders v-if="active === 'ts'" />
                <ShopOrders v-if="active === 'shop'" />
            </div>
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SideNav from './sidebar';
import ExchangeOrders from './exchangeOrders.vue';
import TradeOrders from './tradeOrders.vue';
import ShopOrders from './shopOrders.vue';
export default {
    name: 'orders',
    props: {},
    components: {
        SideNav,
        ExchangeOrders,
        TradeOrders,
        ShopOrders
    },
    data() {
        return {
            active: 'ts',
        };
    },
    methods: {
        async getOrders(e) {
            const type = e.srcElement.attributes[1].value;
            this.active = type;
            window.location.hash = type;
        },
    },
    computed: {
        ...mapGetters(['user']),
    },
    mounted: function () {},
    watch: {},
};
</script>

<style scoped>
.transactions-container {
    padding-top: 6rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.transactions-table {
    margin: 0;
    padding: 0;
}

.transactions-wrapper {
    background-color: #383f51;
}

.sub-menu-nav {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    gap: 15px;
}

.sub-menu-nav > div {
    color: #f1f1f1;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
    cursor: pointer;
}

.sub-menu-nav > div:hover {
    color: #17d0c1;
    background-color: hsl(220, 5%, 35%);
    border-radius: 5px;
}

.active {
    color: #17d0c1 !important;
    background-color: hsl(220, 5%, 35%);
    border-radius: 5px;
}

.section-title {
    color: #f1f1f1;
    font-size: 25px;
    font-weight: bold;
}
@media screen and (max-width: 820px) {
    .sidenav-layout {
        display: none;
    }
    .transactions-container {
        justify-content: center;
    }
}
</style>
