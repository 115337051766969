<template>
    <v-container fluid fill-height id="trade">
        <v-row class="trade-container" style="width: 100%">
            <v-col class="user-offer-container" cols="12" sm="12" md="12" lg="5" xl="5">
                <v-row class="flex-column flex-nowrap">
                    <user-deposit @inputDataDeposit="updateUserDeposit" />
                </v-row>
            </v-col>

            <v-col class="user-offer-container" cols="12" sm="12" md="12" lg="2" xl="2">
                <v-row>
                    <trade-detail
                        :userData="userData"
                        @setExchangeAmount="setExchangeAmount"
                        @showLoginError="showLoginError"
                    />
                </v-row>
            </v-col>

            <v-col class="user-exchange-container" cols="12" sm="12" md="12" lg="5" xl="5">
                <v-row>
                    <user-exchange @inputDataExport="updateUserExport" :exchangeAmount="exchange_amount" />
                </v-row>
            </v-col>
        </v-row>
        <template>
            <div class="text-center">
                <!-- <v-snackbar v-model="snackbar" :multi-line="multiLine" :timeout="-1">
                {{ text }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="teal accent-2" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
                </v-snackbar> -->
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <v-card color="#383F51">
                        <v-toolbar color="primary" dark
                            ><span class="text-h5 font-weight-bold">
                                Welcome to Exchange Service - BETA
                            </span></v-toolbar
                        >
                        <br />
                        <v-card-text class="white--text" v-if="!$store.state.auth.user.avatar">
                            Our exchange service allows players to trade their MMO Gold between Regions, Servers and
                            game versions: Retail, WOTLK, New World and Lost Ark.
                            <br /><br />Get started by <bold class="fat">Signing Up</bold> in the top right corner and
                            then fill in your WoW details on this screen afterwards.
                        </v-card-text>
                        <v-card-text
                            class="white--text"
                            v-if="$store.state.auth.user.avatar && !$store.state.auth.user.email"
                        >
                            Our exchange service allows players to trade their MMO Gold between Regions, Servers and
                            game versions: Retail, WOTLK, New World and Lost Ark.
                            <br />As we need your email to complete the trade, you will need to sign out of STEAM and
                            sign up using your email.<br />
                            To do that, press your STEAM icon on the upper-right top, press Logout - then login again by
                            pressing EMAIL SIGN UP/LOGIN.
                        </v-card-text>
                        <v-card-text
                            class="white--text"
                            v-if="$store.state.auth.user.avatar && $store.state.auth.user.email"
                        >
                            Our exchange service allows players to trade their MMO Gold between Regions, Servers and
                            game versions: Retail, WOTLK, New World and Lost Ark.
                            <br />If you've ever wanted to play on a different server or version but didn't want to lose
                            your hard earned gold. Well, now you can finally trade it across!<br />
                            Fill in your details and start trading across!
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed color="#17D0C1" @click="dialog = false"> Understood! </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="loginDialog" persistent max-width="600px">
                    <v-card color="#383F51">
                        <v-toolbar color="primary" dark
                            ><span class="text-h5 font-weight-bold"> Please Sign In/Sign up first! </span></v-toolbar
                        >
                        <br />
                        <v-card-text class="white--text">
                            <bold class="fat">Sign In/Sign Up</bold> with your E-mail in the top right corner to use the
                            Exchange Service. <br /><br />If you are already Signed In with Steam you must sign out of
                            your Steam Account before signing up.
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#17D0C1" depressed @click="loginDialog = false"> OK, will do! </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template>
        <login-modal :dialog="showInfoTradeModal" @closeInfoModal="hideInfoModal()" />
        <SignInModal
            :user="this.user"
            :dissmisable="false"
            explainer="To use this service you must connect to Trade-Raid.com by providing us the following information"
            :dialog="emailNotSignedIn"
        />
    </v-container>
</template>

<script>
import UserOffer from '../../components/UserOffer/';
import UserDeposit from '../../components/UserDeposit/';
import UserExchange from '../../components/UserExchange/';
import UserItems from '../../components/UserItems/';
import TradeInfo from '../../components/TradeInfo/';
import UserReceive from '../../components/UserReceive/';
import TradeDetail from '../../components/TradeDetail/';
import TradeLinkModal from '../../components/TradeLinkModal/';
import LoginModal from '../../components/LoginModal/';
import { mapGetters } from 'vuex';
import { getTradeAmountExchange } from '../../api/services.js';
import SignInModal from '../../components/SignInModal/emailNotSignedIn.vue';
export default {
    name: 'Exchange Service',
    metaInfo: {
        title: 'Swap MMO gold across games, servers and regions',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Swap MMO gold across games, servers and regions - Dragonflight gold, WOTLK gold, FFXIV Gil, New World, Lost Ark, TESO',
            },
        ],
    },
    components: {
        UserOffer,
        UserItems,
        TradeInfo,
        UserReceive,
        TradeDetail,
        TradeLinkModal,
        UserDeposit,
        UserExchange,
        LoginModal,
        SignInModal,
    },
    computed: {
        ...mapGetters(['showInfoTradeModal', 'user']),
    },
    data() {
        return {
            emailNotSignedIn: false,
            show: false,
            showOfferLinkModal: false,
            exchange_amount: null,
            multiLine: true,
            snackbar: false,
            dialog: false,
            loginDialog: false,
            text: `Please sign in/sign up with your email to continue! 
            If you're already signed in with Steam, press your Steam account logo and log out. Then sign up with your email`,
            userData: {
                deposit_amount: null,
                deposit_character_name: null,
                deposit_delivery_method: '',
                deposit_region: '',
                deposit_server: '',
                deposit_version: null,
                exchange_amount: null,
                thresholdValues: null,
                exchange_character_name: null,
                exchange_delivery_method: null,
                exchange_region: '',
                exchange_server: '',
                exchange_version: '',
                deposit_server_fee: 0,
                deposit_delivery_method_fee: 0,
                min_deposit_amount: 0,
                max_deposit_amount: 0,
                exchange_server_fee: 0,
                exchange_delivery_method_fee: 0,
            },
        };
    },
    methods: {
        hideInfoModal() {
            this.$store.commit('SHOW_INFO_TRADE_MODAL', false);
        },
        hideOfferLinkModal() {
            this.showOfferLinkModal = false;
            this.$store.dispatch('getItems', 'steam');
        },
        updateUserDeposit(data) {
            this.userData.deposit_amount = data.deposit_amount;
            this.userData.deposit_character_name = data.deposit_character_name;
            this.userData.deposit_delivery_method = data.deposit_delivery_method;
            this.userData.deposit_region = data.deposit_region;
            this.userData.deposit_server = data.deposit_server;
            this.userData.deposit_server_fee = data.deposit_server_fee;
            this.userData.deposit_delivery_method_fee = data.deposit_delivery_method_fee;
            this.userData.deposit_version = data.deposit_version;
            this.userData.is_invalid_deposit_amount = data.is_invalid_deposit_amount;
            this.userData.min_deposit_amount = data.min_deposit_amount;
            this.userData.max_deposit_amount = data.max_deposit_amount;
            this.userData.receiver_character_name = data.receiver_character_name;
        },
        updateUserExport(data) {
            this.userData.exchange_amount = data.exchange_amount;
            this.userData.exchange_character_name = data.exchange_character_name;
            this.userData.exchange_delivery_method = data.exchange_delivery_method;
            this.userData.exchange_region = data.exchange_region;
            this.userData.exchange_server = data.exchange_server;
            this.userData.exchange_version = data.exchange_version;
            this.userData.exchange_server_fee = data.exchange_server_fee;
            this.userData.exchange_delivery_method_fee = data.exchange_delivery_method_fee;
        },
        setExchangeAmount(data) {
            this.exchange_amount = data;
        },
        showLoginError(data) {
            this.loginDialog = true;
        },
    },
    watch: {
        user: {
            handler(newValue, oldValue) {
                if ((this.user.steam_id && !this.user.email) || (this.user.steam_id && newValue.email)) {
                    this.emailNotSignedIn = true;
                }
            },
            immediate: true,
        },
    },
    mounted() {
        getTradeAmountExchange()
            .then((res) => {
                this.userData.thresholdValues = res.data;
            })
            .catch((err) => {
                console.log('ERROR SETTING THRESHOLDVALUES IN EXCHANGE');
                console.log(err);
            });
        if (this.user.email) {
            this.dialog = true;
        }
        if (this.user.steam_id && !this.user.email) {
            this.emailNotSignedIn = true;
        }
        if (this.user.steam_id)
            if (Object.keys(this.user).length == 0) {
                this.dialog = true;
            }
    },
};
</script>
<style>
.v-snack__content {
    font-size: 1rem !important;
}
</style>
<style lang="scss" scoped>
#trade {
    padding-top: calc(80px + 40px);
    padding-bottom: 75px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );

    .row {
        height: 100%;
    }
}
</style>
