<template>
    <div>
        <!-- Hero Start -->
        <v-lazy
            v-model="isActive"
            :options="{
                threshold: 0.5,
            }"
            min-height="200"
            transition="fade-transition"
        >
            <section
                class="bg-home d-flex align-items-center"
                style="background: center center; height: auto"
                id="home"
            >
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                            <div class="title-heading margin-top-100">
                                <h1 class="heading mb-3" style="font-weight: bold">Our Mission</h1>
                                <h4 class="title-heading mb-4">
                                    At Trade-Raid our mission is to create services that make the life of a gamer easier
                                    and cheaper
                                </h4>
                                <p class="para-desc mx-auto text-muted">
                                    Games and their respective platforms have become more restrictive in their ability
                                    to transfer the value between games over the years.
                                </p>
                                <p class="para-desc mx-auto text-muted">
                                    Today, gaming platforms ask their players to invest money into their game universes.
                                    But once the gamer has swiped their credit card, it has become common sense that the
                                    value is locked within the specific gaming platform. At Trade-Raid we seek to break
                                    these artificial barriers and innovate services that creates opportunities for
                                    gamers to trade their hard-earned value between games and/or game versions.
                                </p>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
            </section>
        </v-lazy>
        <!--end section-->
        <!-- Hero End -->

        <!-- Feature Start -->
        <v-lazy
            v-model="isActive"
            :options="{
                threshold: 0.5,
            }"
            min-height="200"
            transition="fade-transition"
        >
            <section class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-12">
                            <div class="features">
                                <div class="image position-relative d-inline-block">
                                    <clock-icon size="2.5x" style="color: #17d0c1"></clock-icon>
                                </div>

                                <div class="content mt-4">
                                    <h5>Save Money and Time</h5>
                                    <p class="text-muted mb-0">
                                        Save money and time by not swiping your credit card twice. Trade-Raid creates
                                        innovative services that allows you to conveniently transfer your digital assets
                                        across games and platforms. Take the value of your digital assets from previous
                                        played games to where it matters to you.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-12 mt-5 mt-sm-0">
                            <div class="features">
                                <div class="image position-relative d-inline-block">
                                    <users-icon size="2.5x" style="color: #17d0c1"></users-icon>
                                </div>

                                <div class="content mt-4">
                                    <h5>Accessible to everyone</h5>
                                    <p class="text-muted mb-0">
                                        Our services are inclusive and have a low entry barrier. We accept almost any
                                        digital item and our minimum trade amounts are low to ensure that Trade-Raid is
                                        accessible to everyone.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-12 mt-5 mt-sm-0">
                            <div class="features">
                                <div class="image position-relative d-inline-block">
                                    <message-circle-icon size="2.5x" style="color: #17d0c1"></message-circle-icon>
                                </div>

                                <div class="content mt-4">
                                    <h5>Great Customer Service</h5>
                                    <p class="text-muted mb-0">
                                        Our services are made by gamers for gamers. Trade-Raid Support understands the
                                        situation users of our services are in because we play the same games. We take
                                        our time with each user and you can reach out to us through Live chat and E-mail
                                        support.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                            <h4 class="title-heading mb-4">
                                We work by the essential core idea that one man’s trash is another man’s treasure
                            </h4>
                            <p class="para-desc mx-auto text-muted">
                                Too many people have invested both their time and money in games they have not touched
                                for years.
                            </p>
                            <p class="para-desc mx-auto text-muted">
                                If you had invested hours and money on your DOTA 2 Inventory or if you have a bunch of
                                WoW Gold on a server you have abandoned years ago, we believe it should be possible to
                                exchange that value to buy a WoW Token, BoE item or receive gold on the server you are
                                currently playing on.
                            </p>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>

                <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <img
                                src="https://trade-raid-com.mo.cloudinary.net/icons/trade_raid_made.png"
                                class="img-fluid rounded"
                                alt="Trade-raid.com Denmark logo"
                            />
                        </div>
                        <!--end col-->

                        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title ml-lg-5">
                                <h4 class="title-heading mb-4">
                                    We want to avoid people swiping their credit card more than what is necessary to
                                    enjoy their games
                                </h4>
                                <p class="text-muted">
                                    We term this cross-game trading. We are passionate about this concept and at
                                    Trade-Raid we constantly try to innovate and develop new trade services for our
                                    users. In 2021, we launched our first stand-alone service: Trade Gold Between
                                    Servers that enables users to trade gold between Servers, Retail, Classic Versions,
                                    and Regions. We will try to keep update our services to accommodate more users, add
                                    more games and servers to make our services as accessible as possible.
                                </p>
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0">
                                        <span class="text-primary h5 mr-2"
                                            ><facebook-icon size="1x" style="color: #17d0c1"></facebook-icon></span
                                        >Like Trade-Raid at our Facebook Page to keep up with the status and development
                                        of new services:
                                    </li>
                                </ul>
                                <a
                                    href="https://www.facebook.com/TradeRaid"
                                    target="_blank"
                                    class="mt-3 h6 text-primary"
                                    >Check out our Facebook here <i class="mdi mdi-chevron-right"></i
                                ></a>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                            <h1 class="heading mb-3" style="font-weight: bold; color: white">About Us</h1>
                            <p class="para-desc mx-auto text-muted">
                                Trade-Raid is founded by Shak111 from Steam. I am well-known within both the WoW and
                                Steam Trading community where I have been trading Steam Items to MMO gold since 2015
                                daily. Up until today I have on my Steam Account conducted more than 14000+ trades and
                                had 230000+ Market Transactions all documented by Steam. I have also created my own
                                Steam Group to further expand my legitimacy and trade practice – Shak’s Trade Services.
                                I have exchanged for more than 500M+ gold to WoW Players on most popular servers mainly
                                in the EU, US and Oceanic Regions.
                            </p>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>

                <div class="container mt-100 mt-60">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="section-title mr-lg-5">
                                <p class="text-muted">
                                    We began working on Trade-Raid June 2020 and launched our beta version of our Steam
                                    Items for MMO Gold Service in October 2020. Despite the technical challenges and
                                    issues Trade-Raid has been off to a great start and we are humbled by the support we
                                    have received by our users. In September 2021, we launched our first stand-alone
                                    service: Trade Gold Between Servers.
                                </p>
                                <p class="text-muted">As of 1st of June 2022 Trade-Raid has had:</p>
                                <ul class="list-unstyled text-muted">
                                    <li class="mb-0">
                                        <span class="text-primary h5 mr-2"
                                            ><chevron-right-icon
                                                size="1x"
                                                style="color: #17d0c1"
                                            ></chevron-right-icon></span
                                        >7000+ Users that have Signed in Through Steam and had their Steam Inventories
                                        calculated into MMO Gold
                                    </li>
                                    <li class="mb-0">
                                        <span class="text-primary h5 mr-2"
                                            ><chevron-right-icon
                                                size="1x"
                                                style="color: #17d0c1"
                                            ></chevron-right-icon></span
                                        >3700+ Trade Service Orders Completed
                                    </li>
                                    <li class="mb-0">
                                        <span class="text-primary h5 mr-2"
                                            ><chevron-right-icon
                                                size="1x"
                                                style="color: #17d0c1"
                                            ></chevron-right-icon></span
                                        >500+ Exchange Service Orders Completed
                                    </li>
                                    <li class="mb-0">
                                        <span class="text-primary h5 mr-2"
                                            ><chevron-right-icon
                                                size="1x"
                                                style="color: #17d0c1"
                                            ></chevron-right-icon></span
                                        >30% of our users return to Trade-Raid and use our services again
                                    </li>
                                </ul>
                                <a
                                    href="https://www.trustpilot.com/review/trade-raid.com"
                                    target="_blank"
                                    class="mt-3 h6 text-primary"
                                    >Check out our Trustpilot <i class="mdi mdi-chevron-right"></i
                                ></a>
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-lg-5 col-md-6 order-1 order-md-2">
                            <img
                                src="https://trade-raid-com.mo.cloudinary.net/dota.jpeg"
                                class="img-fluid rounded"
                                alt="DOTA image"
                            />
                            <!-- <img src="images/illustrator/app_development_SVG.svg" alt="" /> -->
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end container-->
                <!--end container-->
            </section>
        </v-lazy>
        <!--end sectipn-->
        <!-- Client End -->
        <!-- Footer End -->
        <!-- Back to top -->
        <!-- Back to top -->
    </div>
</template>
<script>
import { ChevronRightIcon } from 'vue-feather-icons';
import { FacebookIcon } from 'vue-feather-icons';
import { UsersIcon } from 'vue-feather-icons';
import { ClockIcon } from 'vue-feather-icons';
import { MessageCircleIcon } from 'vue-feather-icons';

/**
 * Index-saas component
 */
export default {
    name: 'The Trade-Raid Mission',
    metaInfo: {
        title: 'The Trade-Raid Mission',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Trade-Raid is on a mission to change the gaming industry by making it easier for gamers to trade their Steam Items for MMO Gold.',
            },
        ],
    },
    components: {
        ChevronRightIcon,
        FacebookIcon,
        UsersIcon,
        ClockIcon,
        MessageCircleIcon,
    },
    data: () => ({
        isActive: false,
    }),
};
</script>
<style scoped>
/* Template Name: Landrick - Saas & Software Landing Page Template
   Author: Themesbrand
   E-mail: themesbrand@gmail.com
   Version: 2.6
   Updated: November 2020
   File Description: Main Css file of the template
*/
/*********************************/
/*         INDEX                 */
/*================================
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 *     07.  Home / Landing       *
 *     08.  Features / Services  *
 *     09.  Testimonial          *
 *     10.  Price                *
 *     11.  C.T.A                *
 *     12.  Blog / Careers / Job *
 *     13.  Work / Projects      *
 *     14.  Team                 *
 *     15.  User Pages           *
 *     16.  Countdown            *
 *     17.  Contact              *
 *     18.  Footer               *
 *********************************
 
 *********************************
 *         Dark SCSS             *
 *********************************
 *     01.  Variables            *
 *     02.  Bootstrap Custom     *
 *     03.  Components           *
 *     04.  General              *
 *     05.  Helper               *
 *     06.  Menu                 *
 ================================*/
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap');
h1 {
    font-size: 42px !important;
}

h4 {
    font-size: 24px !important;
}

h5 {
    font-size: 20px !important;
    color: #fff;
}

h6 {
    font-size: 16px !important;
}

.text-primary {
    color: #17d0c1 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #284bc1 !important;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.rounded {
    border-radius: 6px !important;
}

.btn-primary {
    background-color: #17d0c1 !important;
    border: 1px solid #17d0c1 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #17d0c1 !important;
    border-color: #17d0c1 !important;
    color: #ffffff !important;
}

.btn-outline-primary {
    border: 1px solid #17d0c1;
    color: #17d0c1;
    background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #17d0c1;
    border-color: #17d0c1;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 600;
    border-radius: 6px;
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0;
    }
}

@keyframes animate-positive {
    0% {
        width: 0;
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

[class^='uil-']:before,
[class*=' uil-']:before {
    margin: 0;
}

h1,
h4,
h5,
h6,
.h1,
.h4,
.h5,
.h6 {
    font-family: 'Nunito', sans-serif;
    line-height: 1.5;
    font-weight: 600;
}

::-moz-selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

::selection {
    background: rgba(47, 85, 212, 0.9);
    color: #ffffff;
}

a {
    text-decoration: none !important;
}

p {
    line-height: 1.6;
}

.section {
    padding: 100px 0;
    position: relative;
}

@media (max-width: 768px) {
    .section {
        padding: 60px 0;
    }
}

.title-heading {
    line-height: 26px;
    color: #fff;
}

.title-heading .heading {
    font-size: 45px !important;
    letter-spacing: 1px;
    color: #fff;
}

@media (max-width: 768px) {
    .title-heading .heading {
        font-size: 35px !important;
        color: #fff;
    }
}
.para-desc {
    font-size: 18px;
    color: #fff;
    max-width: 900px;
}

.title-heading .para-desc {
    font-size: 18px;
    color: #fff;
}

.section-title {
    position: relative;
}

.section-title .title {
    letter-spacing: 0.5px;
    font-size: 30px !important;
}

@media (max-width: 768px) {
    .section-title .title {
        font-size: 24px !important;
    }
}

.para-desc {
    max-width: 900px;
}

.mt-100 {
    margin-top: 100px;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }
}

@-webkit-keyframes spinner-animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-animation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
    }
}

@media (max-width: 768px) {
    .mt-60 {
        margin-top: 60px;
    }
}

.bg-home {
    height: 100vh;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

@media (max-width: 767px) {
    .bg-home {
        padding: 150px 0;
        height: auto;
    }
}

.features .image:before {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 64px;
    height: 64px;
    border-radius: 6px;
    -webkit-transform: rotate(33.75deg);
    transform: rotate(33.75deg);
    background: linear-gradient(45deg, transparent, rgba(47, 85, 212, 0.1));
}

.features:hover .image:before {
    background: rgba(47, 85, 212, 0.1);
    -webkit-animation: spinner-border 5s linear infinite !important;
    animation: spinner-border 5s linear infinite !important;
}

.features .title {
    font-size: 18px !important;
}

.text-primary {
    color: #17d0c1 !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #284bc1 !important;
}

.card {
    background-color: #1f2d3d;
}

.btn-primary {
    background-color: #17d0c1 !important;
    border: 1px solid #17d0c1 !important;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background-color: #17d0c1 !important;
    border-color: #17d0c1 !important;
    color: #ffffff !important;
}

.btn-outline-primary {
    border: 1px solid #17d0c1;
    color: #17d0c1;
    background-color: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #17d0c1;
    border-color: #17d0c1;
    color: #ffffff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}

::-moz-selection {
    color: #ffffff;
}

::selection {
    color: #ffffff;
}
/*# sourceMappingURL=style-dark.css.map */
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --blue: #17d0c1;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #17d0c1;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}
*,
::after,
::before {
    box-sizing: border-box;
}
section {
    display: block;
}
h1,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul ul {
    margin-bottom: 0;
}
a {
    color: #17d0c1;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #0056b3;
    text-decoration: underline;
}
a:not([href]):not([class]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}
img {
    vertical-align: middle;
    border-style: none;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}
template {
    display: none;
}
.h1,
.h4,
.h5,
.h6,
h1,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.h1,
h1 {
    font-size: 2.5rem;
}
.h4,
h4 {
    font-size: 1.5rem;
}
.h5,
h5 {
    font-size: 1.25rem;
}
.h6,
h6 {
    font-size: 1rem;
}
.list-unstyled {
    padding-left: 0;
    list-style: none;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-12,
.col-lg-12,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-md-4,
.col-md-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.order-1 {
    -ms-flex-order: 1;
    order: 1;
}
.order-2 {
    -ms-flex-order: 2;
    order: 2;
}
@media (min-width: 768px) {
    .col-md-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .order-md-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .order-md-2 {
        -ms-flex-order: 2;
        order: 2;
    }
}
@media (min-width: 992px) {
    .col-lg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-lg-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }
    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    color: #212529;
    text-decoration: none;
}
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn:disabled {
    opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-primary {
    color: #fff;
    background-color: #17d0c1;
    border-color: #17d0c1;
}
.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.btn-primary:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary:disabled {
    color: #fff;
    background-color: #17d0c1;
    border-color: #17d0c1;
}
.btn-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-outline-primary {
    color: #17d0c1;
    border-color: #17d0c1;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #17d0c1;
    border-color: #17d0c1;
}
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary:disabled {
    color: #17d0c1;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #17d0c1;
    border-color: #17d0c1;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}
@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.align-middle {
    vertical-align: middle !important;
}
.rounded {
    border-radius: 0.25rem !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}
.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}
.position-relative {
    position: relative !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.mt-4 {
    margin-top: 1.5rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.mt-5 {
    margin-top: 3rem !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pt-2 {
    padding-top: 0.5rem !important;
}
.mx-auto {
    margin-right: auto !important;
}
.mx-auto {
    margin-left: auto !important;
}
@media (min-width: 576px) {
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    .pt-sm-0 {
        padding-top: 0 !important;
    }
}
@media (min-width: 768px) {
    .mt-md-5 {
        margin-top: 3rem !important;
    }
    .mb-md-5 {
        margin-bottom: 3rem !important;
    }
    .pt-md-5 {
        padding-top: 3rem !important;
    }
}
@media (min-width: 992px) {
    .mr-lg-5 {
        margin-right: 3rem !important;
    }
    .ml-lg-5 {
        margin-left: 3rem !important;
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }
}
.text-center {
    text-align: center !important;
}
.font-weight-bold {
    font-weight: 700 !important;
}
.text-primary {
    color: #17d0c1 !important;
}
a.text-primary:focus,
a.text-primary:hover {
    color: #0056b3 !important;
}
.text-muted {
    color: rgba(255, 255, 255, 0.7) !important;
}
@media print {
    *,
    ::after,
    ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }
    a:not(.btn) {
        text-decoration: underline;
    }
    img {
        page-break-inside: avoid;
    }
    p {
        orphans: 3;
        widows: 3;
    }
    @page {
        size: a3;
    }
    .container {
        min-width: 992px !important;
    }
}
/*# sourceMappingURL=bootstrap.min.css.map */
</style>
