<template>
    <div class="profile-info v-lazy">
        <div class="section-title">Award Track</div>
        <div class="section" style="color: white">
            <div class="icon-text-section steam">
                <AwardIcon
                    :class="{
                        'primary-color-info': computedAwards.connectSteam,
                        'secondary-color-text': !computedAwards.connectSteam,
                    }"
                />
                <p class="secondary-color-text">Connect with Steam</p>
            </div>
            <ConnectSteam />
            <div class="icon-text-section">
                <AwardIcon
                    :class="{
                        'primary-color-info': computedAwards.connectEmail,
                        'secondary-color-text': !computedAwards.connectEmail,
                    }"
                />
                <p class="secondary-color-text">Connect with Email</p>
            </div>
            <div class="icon-text-section">
                <AwardIcon
                    :class="{
                        'primary-color-info': computedAwards.applyTradeLink,
                        'secondary-color-text': !computedAwards.applyTradeLink,
                    }"
                />
                <p class="secondary-color-text">Add Trade Link</p>
            </div>
            <div class="icon-text-section">
                <AwardIcon
                    :class="{
                        'primary-color-info': computedAwards.tradeES,
                        'secondary-color-text': !computedAwards.tradeES,
                    }"
                />
                <p class="secondary-color-text">Complete a trade on the Exchange Service</p>
            </div>
            <div class="icon-text-section">
                <AwardIcon
                    :class="{
                        'primary-color-info': computedAwards.tradeTS,
                        'secondary-color-text': !computedAwards.tradeTS,
                    }"
                />
                <p class="secondary-color-text">Complete a trade on the Trade Service</p>
            </div>
               <div class="icon-text-section">
                <AwardIcon
                    :class="{
                        'primary-color-info': computedAwards.shopOrder,
                        'secondary-color-text': !computedAwards.shopOrder,
                    }"
                />
                <p class="secondary-color-text">Place an order in our shop</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConnectSteam from '../ConnectSteam';
import { PlusSquareIcon, MinusSquareIcon, AwardIcon } from 'vue-feather-icons';
import { orderCount } from '../../api/auth';
export default {
    name: 'userAwards',
    props: {},
    components: {
        ConnectSteam,
        PlusSquareIcon,
        MinusSquareIcon,
        AwardIcon,
    },
    data() {
        return {
            computedAwards: {
                connectSteam: false,
                connectEmail: false,
                applyTradeLink: false,
                tradeES: false,
                tradeTS: false,
                shopOrder: null,
            },
            orders: null,
        };
    },
    methods: {
        async checkAwards() {
            this.computedAwards.connectSteam = !!this.user.steam_id;
            this.computedAwards.connectEmail = !!this.user.email;
            this.computedAwards.applyTradeLink = !!this.user.trade_token;
            this.computedAwards.tradeES = (await orderCount(this.user._id, 'es', this.user.access_token)).data.val >= 1;
            this.computedAwards.tradeTS = (await orderCount(this.user._id, 'ts', this.user.access_token)).data.val >= 1;
            this.computedAwards.shopOrder = (await orderCount(this.user._id, 'shop', this.user.access_token)).data.val >= 1;
        },
    },
    computed: {
        ...mapGetters(['user']),
    },
    mounted: function () {
        this.checkAwards();
    },
    watch: {
        user: {
            handler() {
                this.checkAwards();
            },
        },
    },
};
</script>

<style scoped>
.secondary-color-text {
    color: #c0c0c2;
}
.profile-info {
    width: 100%;
    min-width: 35vw;
    background-color: #383f51;
    padding: 25px;
    border-radius: 4px;
}

/** medium screens */
@media screen and (max-width: 1500px) {
    .profile-info {
        width: 570px;
    }
}

.steam {
    display: flex;
}

/** small screens */

.profile-title {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 25px;
}

.section-title {
    color: #f1f1f1;
    font-size: 25px;
    font-weight: bold;
}
.section {
    border-radius: 25px;
    padding-top: 25px;
}

.section > div {
    padding-top: 15px;
}
.icon-text-section {
    display: flex;
    gap: 5px;
}
.primary-color-info {
    color: #17d0c1;
}
</style>
