<template>
    <v-col class="trade-detail" cols="12">
        <v-card outlined elevation="5">
            <v-card-title class="d-flex justify-center">
                <!-- <EventBadge v-if="new Date() > new Date('2023-03-04') && new Date < new Date('2023-03-26')" style="width: auto; height: 20px; font-size: 15px; font-weight: bold;" type="e-gold-text" percentage="+10% gold on all orders!" /> -->
            </v-card-title>
            <v-card-text>
                <h2 style="display: none">
                    Exchange MMO gold across games and servers and regions - WoW Gold, FFXIV Gil, New World, Lost Ark,
                    TESO
                </h2>
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
                    <div class="action-container d-flex justify-center">
                        <v-btn type="submit" class="trade-button" :loading="loading">Trade</v-btn>
                    </div>
                    <div class="trade-form-container">
                        <div class="form-group">
                            <label for="" class="d-flex align-center text-center">
                                Email
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            v-on="on"
                                            v-bind="attrs"
                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                            alt="info icon"
                                            style="margin-left: 7px"
                                        />
                                    </template>
                                    <span>Fill in your contact email</span>
                                </v-tooltip>
                            </label>

                            <v-text-field
                                v-model="email"
                                placeholder="Enter email"
                                ref="userEmailId"
                                outlined
                                height="36px"
                                dense
                                hide-details
                                :rules="[rules.required, rules.email]"
                                color="primary"
                                @keyup="isValidData"
                            >
                            </v-text-field>
                        </div>
                        <div class="form-group">
                            <a href="/faq?q=5#ratios" target="_blank">
                                <label for="" class="d-flex align-center">
                                    VAT Country
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span>Choose your VAT country. Click here for more info.</span>
                                    </v-tooltip>
                                </label>
                            </a>
                            <v-select
                                autocomplete="false"
                                v-model="country"
                                :items="countries"
                                placeholder="United States"
                                outlined
                                height="36px"
                                dense
                                hide-details
                                :error="countryErr"
                                :append-icon="'mdi-chevron-down'"
                                :rules="[rules.country]"
                                color="primary"
                                :menu-props="{ offsetY: true }"
                                attach
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <div class="d-flex">
                                                <v-text-field
                                                    autofocus
                                                    v-model="searchTerm"
                                                    class="custom-search"
                                                    @input="searchCountries"
                                                    label="Search"
                                                    hide-details
                                                    outlined
                                                    color="secondary"
                                                    height="20"
                                                    :clearable="searchTerm && searchTerm.length > 2"
                                                ></v-text-field>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <p>
                                                <bold class="fat">VAT</bold> is a tax that decreases your gold %
                                                received. You can
                                                <bold class="fat">freely choose a non-VAT country</bold>, if this does
                                                not apply to you.
                                                <a
                                                    href="/faq?q=5#ratios"
                                                    target="_blank"
                                                    style="text-decoration: underline"
                                                    >Read here for more info.</a
                                                >
                                            </p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </div>
                        <p>
                            Note that the Exchange Service is in BETA.
                            <br />For more in-depth information, visit our
                            <a
                                target="_blank"
                                href="https://docs.google.com/document/d/1G1JIQZfEHb4Su3InJswHXp_7T6YDHNxR65TxapscCIc/edit?usp=sharing"
                                >MMO Currency Tendencies Document</a
                            >
                            or read our <a target="_blank" href="https://www.trade-raid.com/faq">FAQ</a>. If you need
                            help with your order, contact Trade-Raid Support.
                        </p>
                        <div class="form-group2">
                            <input type="hidden" v-model="exchange_rate" />
                            <v-tooltip top color="black">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        class="exchange_rate disabled-input"
                                        v-on="on"
                                        v-bind="attrs"
                                        placeholder="Exchange Rates"
                                        outlined
                                        height="36px"
                                        dense
                                        hide-details
                                        readonly
                                        color="primary"
                                    >
                                    </v-text-field>
                                </template>
                                <span
                                    ><center>
                                        The Exchange Service's gold ratio is 1:1. <br />The fee for any exchange is 20%.
                                        <br />
                                        The “Exchanged Amount” is always the total Gold amount you will receive in-game.
                                        <br />For more details, check out our FAQ or Google Docs.
                                    </center>
                                </span>
                            </v-tooltip>
                        </div>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>

        <v-dialog v-model="showTradeErrorModal" persistent width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"> Gold sum is too low </v-card-title>
                <v-card-text class="modal text-white">
                    Your deposit gold amount is either below or above our allowed trade amounts.
                    <br />Trade amount entered: {{ this.userData.deposit_amount }} Gold <br />
                    Retail:
                    <br />EU Minimum trade amount:
                    {{ abbreviateNumber(this.userData.thresholdValues['Retail']['EU']['min_trade_amount']) }} Gold
                    <br />
                    US Minimum trade amount:
                    {{ abbreviateNumber(this.userData.thresholdValues['Retail']['US']['min_trade_amount']) }} Gold
                    <br />
                    WOTLK: <br />
                    EU Minimum trade amount:
                    {{ abbreviateNumber(this.userData.thresholdValues['WOTLK']['EU']['min_trade_amount']) }} Gold <br />

                    US Minimum trade amount:
                    {{ abbreviateNumber(this.userData.thresholdValues['WOTLK']['US']['min_trade_amount']) }} Gold <br />
                    Classic Era: <br />
                    EU Minimum trade amount:
                    {{ abbreviateNumber(this.userData.thresholdValues['SoM']['EU']['min_trade_amount']) }} Gold <br />

                    US Minimum trade amount:
                    {{ abbreviateNumber(this.userData.thresholdValues['SoM']['US']['min_trade_amount']) }} Gold <br />
                    Your deposit gold amount is below our minimum trade amounts.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#17D0C1" class="signin-btn mr-3" @click="hideModal()"> Okay </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showMissingInfoModal" persistent width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"> Exchange Info missing </v-card-title>
                <v-card-text class="modal text-white">
                    To start the exchange process, we need all the relevant information.
                    <br />Please fill out all required fields and try again.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#17D0C1" class="signin-btn mr-3" @click="hideModal()"> Okay </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showCrossModal" persistent width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white"> Cross Region Exchange </v-card-title>
                <v-card-text class="modal text-white">
                    We're sorry but cross region exchange is currently not available on our site.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#17D0C1" class="signin-btn mr-3" @click="hideModal()"> Okay </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loginDialog" persistent max-width="600px">
            <v-card color="#383F51">
                <v-toolbar color="primary" dark
                    ><span class="text-h5 font-weight-bold"> Please Sign In/Sign up first! </span></v-toolbar
                >
                <br />
                <v-card-text class="white--text">
                    <bold class="fat">Sign In/Sign Up</bold> with your E-mail in the top right corner to use use the
                    Exchange Service. <br /><br />If you are already Signed In with Steam you must sign out of your
                    Steam Account before signing up.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#17D0C1" depressed @click="loginDialog = false"> OK, will do! </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import { getRatio } from '../../api/services.js';
import axios from 'axios';
import { mapGetters } from 'vuex';
import store from '../../store';
import { v4 as uuidv4 } from 'uuid';
import VatService from '../../utils/VatService.js';
import { userService } from '../../utils/userService.js';
import PriceService from '../../utils/PriceService.js';
import EventBadge from '../UI/event-badge.vue';
export default {
    components: {
        EventBadge,
    },
    computed: {
        ...mapGetters(['user', 'token']),
    },

    props: ['userData'],
    data: () => ({
        searchTerm: '',
        ratios: null,
        countryErr: false,
        countries: [],
        country: 'United States',
        email: null,
        cc: null,
        ip: null,
        loading: false,
        exchange_rate: null,
        thresholdValues: null,
        showTradeErrorModal: false,
        showMissingInfoModal: false,
        showCrossModal: false,
        valid: false,
        loginDialog: false,
        exchange_amount: null,
        base_fee: 20,
        uid: null,
        VAT: { data: false },
        cost: 0,
        countriesCopy: [],
        rules: {
            required: (value) => !!value || 'Is required!',
            email: (value) => {
                const pattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail.';
            },
        },
    }),
    watch: {
        userData: {
            deep: true,
            handler(val, oldVal) {
                this.isValidData();
            },
        },
        country: {
            deep: true,
            handler(v, o) {
                this.isValidData();
            },
        },
        user: {
            handler(val, oldVal) {
                if (val.email != undefined) {
                    this.email = val.email;
                }
            },
        },
    },
    methods: {
        abbreviateNumber(number) {
            return PriceService.abbreviateNumber(number);
        },
        searchCountries(e) {
            this.countryErr = false;
            if (!this.searchTerm) {
                this.countries = this.countriesCopy;
            }
            this.countries = this.countriesCopy.filter((c) => {
                return c.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
            });

            if (this.countries.length) {
                this.country = this.countries[0];
            } else {
                this.countryErr = true;
            }
        },
        isValidData() {
            this.VAT_MSG = false;
            this.$store.commit('SET_MESSAGE', null);
            let data = this.userData;
            var isTradeRateSet = false;

            if (data.deposit_region === 'EU') {
                this.country = 'United Kingdom';
            } else {
                this.country = 'United States';
            }
            if (data.exchange_version === 'Classic Era') {
                data.exchange_version = 'SoM';
            }
            if (data.deposit_version === 'Classic Era') {
                data.deposit_version = 'SoM';
            }

            if (
                data.deposit_amount &&
                data.deposit_character_name &&
                data.deposit_delivery_method &&
                data.deposit_region &&
                data.deposit_server &&
                data.deposit_version &&
                data.exchange_character_name &&
                data.exchange_delivery_method &&
                data.exchange_region &&
                data.exchange_server &&
                data.exchange_version &&
                data.exchange_amount &&
                this.exchange_rate &&
                !this.$refs['userEmailId'].hasError &&
                !data.is_invalid_deposit_amount
            ) {
                this.loginDialog = false;
            }
            this.ratios.map((tradeRatio) => {
                if (
                    tradeRatio.from_version == data.deposit_version &&
                    tradeRatio.to_version == data.exchange_version &&
                    tradeRatio.from_region == data.deposit_region &&
                    tradeRatio.to_region == data.exchange_region
                ) {
                    isTradeRateSet = true;
                    var userTradeRatio = tradeRatio.ratio.split(':');
                    return (this.exchange_rate = (userTradeRatio[1] / userTradeRatio[0]).toFixed(5));
                }
            });
            if (isTradeRateSet == false) {
                this.exchange_rate = null;
                this.exchange_amount = null;
                this.emitExchangeValue();
            }
            if (this.exchange_rate && data.deposit_amount) {
                var total_fee = this.base_fee + data.exchange_server_fee + data.deposit_server_fee; // + data.exchange_server_fee + data.deposit_delivery_method_fee + data.exchange_delivery_method_fee

                let exchange_amount = this.exchange_rate * data.deposit_amount;
                // if(new Date() > new Date('2022-10-15') && new Date()< new Date('2022-10-26')) {
                //     exchange_amount = (this.exchange_rate * data.deposit_amount) + (this.exchange_rate * data.deposit_amount) * 0.10;
                // }
                var fee_amount = exchange_amount * (total_fee / 100);

                this.exchange_amount = Math.ceil((exchange_amount - fee_amount) / 10) * 10;
                const VAT_COMPLIANCE = VatService.calculateVat(this.country, parseInt(this.exchange_amount, 10));
                if (VAT_COMPLIANCE.data) {
                    this.VAT = VAT_COMPLIANCE;
                    let index = this.country.indexOf('(');
                    let cntry = this.country.substring(0, index).trim();
                    this.VAT_MSG = `VAT ${VAT_COMPLIANCE.vat_percentage}%`;
                    this.$store.commit('SET_MESSAGE', this.VAT_MSG);
                    this.exchange_amount = parseInt(Math.ceil(VAT_COMPLIANCE.price), 10);
                }
            } else {
                this.exchange_amount = 0;
            }
            this.emitExchangeValue();
        },
        emitExchangeValue() {
            this.$emit('setExchangeAmount', this.exchange_amount);
        },
        submit() {
            if (Object.keys(this.user).length == 0 || !this.user.email) {
                this.loginDialog = true;
            } else {
                if (
                    this.userData.deposit_amount > this.userData.max_deposit_amount ||
                    this.userData.deposit_amount < this.userData.min_deposit_amount
                ) {
                    this.showTradeErrorModal = true;
                    return false;
                }
                if (
                    !this.userData.deposit_amount ||
                    !this.userData.deposit_character_name ||
                    !this.userData.deposit_delivery_method ||
                    !this.userData.deposit_region ||
                    !this.userData.deposit_server ||
                    !this.userData.deposit_version ||
                    !this.userData.exchange_character_name ||
                    !this.userData.exchange_delivery_method ||
                    !this.userData.exchange_region ||
                    !this.userData.exchange_server ||
                    !this.userData.exchange_version
                ) {
                    this.showMissingInfoModal = true;
                    return false;
                }
                if (this.userData.deposit_region === 'EU' && this.userData.exchange_region === 'US') {
                    this.showCrossModal = true;
                    return false;
                }
                if (this.userData.deposit_region === 'US' && this.userData.exchange_region === 'EU') {
                    this.showCrossModal = true;
                    return false;
                }
                this.userData.order_id = uuidv4();
                this.userData.country = this.country;
                const url = process.env.VUE_APP_BACKEND + '/exchange/trade';
                this.loading = true;

                let info = {
                    ip: this.ip,
                    ua: userService.userAgent(),
                    cc: this.cc,
                };
                axios
                    .post(
                        url,
                        { tradeData: this.userData, email: this.email, ip: info.ip, cc: info.cc, ua: info.ua },
                        {
                            headers: { Authorization: `Bearer ${this.token}` },
                        },
                    )
                    .then((response) => {
                        this.loading = false;
                        store.commit('SET_TRADE_DETAILS', this.userData);
                        store.commit('SET_TRADE_EMAIL', this.email);

                        this.$router.push('/exchange-success');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        hideModal() {
            this.showTradeErrorModal = false;
            this.showMissingInfoModal = false;
            this.showCrossModal = false;
        },
    },
    beforeCreate() {
        // getTradeAmountExchange()
        //     .then((res) => {
        //         this.thresholdValues = res
        //     })
        //     .catch((err) => {
        //         console.log('ERROR SETTING THRESHOLDVALUES IN TRADEDETAIL')
        //         console.log(err);
        //     });
    },
    mounted() {
        getRatio()
            .then((response) => {
                this.ratios = response.data;
            })
            .catch((error) => {
                console.log(error);
            });

        userService
            .info()
            .then((res) => {
                (this.ip = res.ip), (this.cc = res.country);
            })
            .catch((err) => {
                console.log(err);
            });

        this.$store.commit('SET_MESSAGE', null);
        if (this.$store.state.auth.user && this.$store.state.auth.user.email) {
            this.email = this.$store.state.auth.user.email;
        }
        this.countries = VatService.countries
            .map((c) => (c.vat > 0 ? `${c.name} (-${Math.abs(Math.floor(100 * c.vat) - 100)}% G)` : c.name))
            .sort();
        this.countriesCopy = [...this.countries];
    },
};
</script>

<style>
/* .country-paragraph {
    color: #17d0c1 !important;
    align-items: center;
    justify-content: center;
}
.country-container {
    background-color: #17d0c1 !important;
    opacity: .2;
} */
.fat {
    font-weight: bold;
    color: #fff;
}
.trade-detail input::placeholder {
    font-size: 13px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}
.trade-detail .disabled-input input::placeholder {
    color: #fff !important;
    text-align: center;
}
.trade-detail
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.trade-detail
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
.trade-detail .v-icon {
    color: #fff !important;
}
.trade-detail .v-input__icon {
    display: flex;
    height: 15px;
    flex: 1 0 auto;
    justify-content: flex-end;
    min-width: 24px;
    width: 165px;
    right: 0;
    position: absolute;
}
.trade-detail .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #fff !important;
}
.trade-detail .theme--light.v-select .v-select__selection--comma {
    color: #fff;
}
.trade-detail .v-menu__content .v-list {
    background: #555c70;
}
.trade-detail .theme--light.v-input input {
    color: #fff;
}
.trade-detail .exchange_rate {
    margin-top: 69%;
}
.v-dialog .modal.grey {
    background: #555c70;
}
.v-dialog .modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
</style>

<style scoped lang="scss">
.vat-msg {
    color: rgb(23, 208, 193) !important;
    opacity: 0.8;
}
.row {
    height: 100%;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    color: #ffffff !important;
    background: #17d0c1 !important;
}
.trade-detail {
    flex-basis: 100%;
    .v-card {
        height: 100%;
        border: 0px;
        background: #383f51;
    }
    .v-card__title {
        background: #555c70;
        height: 7%;
        border-radius: 2px 2px 0px 0px;
        padding: 20px 15px;
    }
    p {
        text-align: left;
        font-size: 14px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 3px;
        a {
            color: #fff;
        }
    }
    .v-card__text {
        height: calc(100% - 62px);
        background: #383f51;
        &::-webkit-scrollbar {
            width: 1em;
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
        .action-container {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin-right: -16px;
            margin-left: -16px;
            padding: 30px 20px;
            .trade-button {
                font-family: Open Sans !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 13px !important;
                line-height: 18px !important;
                text-align: center !important;
                text-transform: uppercase !important;
                text-decoration: none !important;
                color: #ffffff !important;
                background: #17d0c1 !important;
                box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
                border-radius: 3px !important;
                padding: 0 30px !important;
            }
        }
        .trade-form-container {
            margin-right: -16px;
            margin-left: -16px;
            padding: 30px 20px;
            h3 {
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                color: #ffffff;
            }
            .form-group {
                margin-bottom: 20px;
                label {
                    font-size: 12px;
                    line-height: 11px;
                    color: rgba(255, 255, 255, 0.6);
                    border-radius: 4px;
                    margin-bottom: 7px;
                }
                &::deep input::placeholder {
                    font-size: 13px;
                    line-height: 18px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
            .form-group2 {
                margin-bottom: 20px;
                position: relative;
                bottom: 130px;
            }
        }
    }
}
</style>
