<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <v-container fluid fill-height id="blog">
            <!-- Blog post overview -->
            <template>
                <v-container
                    class="backgroundImage"
                    fluid
                    id="blog"
                    style="
                        background-image: url('https://blog.trade-raid.com/wp-content/uploads/2021/07/home-hero-new@2x-1.jpg');
                    "
                >
                    <v-row>
                        <v-col cols="12">
                            <h1 class="blogTitle">The Trade-Raid.com Blog</h1>
                            <h2 class="blogSubtext">
                                Read here to learn more about Trade-Raid.com, <br />the team behind, our vision and how
                                we're creating the cross game trading concept.
                            </h2>
                        </v-col>
                    </v-row>
                </v-container>
                <h2 class="titleOverview">The Latest Trade-Raid.com Blog Posts</h2>
                <div class="text-center" v-if="loading">
                    <v-progress-circular :size="70" :width="7" color="#fff" indeterminate></v-progress-circular>
                </div>
                <template>
                    <v-row dense no-gutters align-content="end">
                        <v-col
                            v-for="blogPost in posts"
                            :key="blogPost.slug"
                            cols="12"
                            md="6"
                            lg="4"
                            style="height: 800px; width: 300px"
                        >
                            <v-card
                                elevation="20"
                                outlined
                                shaped
                                style="background-color: #20283c; color: #fff"
                                class="mx-auto my-12"
                                max-width="460"
                            >
                                <v-card-title style="height: 100px">
                                    <router-link
                                        :to="{ path: '/blog/posts/' + blogPost.slug }"
                                        style="font-size: 20px; font-weight: bold; color: #fff"
                                        >{{ blogPost.title.rendered }}</router-link
                                    >
                                    <!-- {{ blogPost.title.rendered.substring(0, 10) }}<br />{{ blogPost.title.rendered.substring(10) }} -->
                                </v-card-title>
                                <div class="categories-container">
                                    <v-chip
                                        v-for="category in blogPost._embedded['wp:term'][0]"
                                        v-bind:key="category.slug"
                                        class="ma-2 category-chip"
                                        color="primary"
                                        small
                                        >{{ category.name }}</v-chip
                                    >
                                </div>
                                <div class="img-wrapper">
                                    <router-link :to="{ path: '/blog/posts/' + blogPost.slug }">
                                        <v-img
                                            v-bind:style="{
                                                backgroundImage: `url(${
                                                    blogPost._embedded &&
                                                    blogPost._embedded['wp:featuredmedia'][0].source_url
                                                })`,
                                            }"
                                            class="blog-overview-image"
                                        ></v-img>
                                    </router-link>
                                </div>
                                <v-card-text
                                    v-html="blogPost.excerpt.rendered"
                                    style="font-size: 16px; color: #fff; height: 120px"
                                ></v-card-text>
                                <v-spacer></v-spacer>
                                <v-row style="padding-bottom: 20px" justify="space-around">
                                    <v-card-text style="font-size: 16px; color: #fff"
                                        >Published: {{ blogPost.date.split('T')[0] }}</v-card-text
                                    >
                                    <v-btn
                                        color="teal accent-4"
                                        @click="$router.push({ path: '/blog/posts/' + blogPost.slug })"
                                        outlined
                                    >
                                        Read More
                                    </v-btn>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
            </template>
        </v-container>
    </v-lazy>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Blogs & Guides',
    metaInfo: {
        title: 'Blogs & Guides',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Read about Trade-Raid.com, the team behind the site, our vision for the gaming industry, how we are creating the cross game trading concept and guides on WoW Dragonflight, WoTLK and many more',
            },
        ],
    },
    directives: {
        renderImages: {
            bind(el, binding, vnode) {
                // Find all img tags in the element
                const imgTags = el.querySelectorAll('img');

                // Loop through the img tags
                for (const imgTag of imgTags) {
                    // Create a new image element
                    const imgElement = document.createElement('img');

                    // Set the src attribute of the image element to the src attribute of the img tag
                    imgElement.setAttribute('src', imgTag.getAttribute('src'));

                    // Replace the img tag with the image element
                    imgTag.parentNode.replaceChild(imgElement, imgTag);
                }
            },
        },
    },
    data() {
        return {
            loading: true,
            posts: [],
            post: null,
        };
    },
    watch: {
        $route: {
            handler: function () {
                this.fetchData();
            },
            immediate: true,
        },
    },
    methods: {
        fetchData() {
            if (this.$route.params.slug) {
                axios
                    .get(`https://blog.trade-raid.com/wp-json/wp/v2/posts?slug=${this.$route.params.slug}`)

                    .then((response) => {
                        this.post = response.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (!this.$route.params.slug) {
                axios
                    .get('https://blog.trade-raid.com/wp-json/wp/v2/posts?_embed')
                    .then((response) => {
                        this.posts = response.data;
                        this.post = null;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    },
};
</script>

<style lang="scss">
#blog {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    .blogTitle {
        width: 100%;
        font-weight: bold;
        font-size: 50px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    .blogSubtext {
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        line-height: 37px;
        color: #fff;
        text-align: center;
    }
    .titleOverview {
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #fff;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .backgroundImage {
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 500px;
        padding-top: 180px !important;
        border-radius: 25px;
    }
    .img-wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: inline-block;
    }
    .categoryTitle {
        font-weight: 300;
        font-size: 20px;
        line-height: 37px;
        color: #fff;
        text-align: left;
    }
    .blog-post-content h2 {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
    }

    .blog-overview-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px;
        transition: 0.3s;
    }
    .blog-overview-image:hover {
        -ms-transform: scale(1.1); /* IE 9 */
        -webkit-transform: scale(1.1); /* Safari 3-8 */
        transform: scale(1.1);
    }

    .blog-post-image {
        // Position the image in the center of the card in a medium size
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
    }

    .categories-container {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
    }
    .category-chip {
        display: inline-block;
        margin-right: 8px;
        color: #17d0c1;
    }

    .blog-post-content strong {
        font-size: 35px;
        font-weight: bold;
        color: #fff;
    }

    .blog-post-content p {
        font-size: 16px;
        color: #fff;
    }

    .blog-post-content ul,
    .blog-post-content ol {
        font-size: 16px;
        color: #fff;
    }
}
</style>
