// fiat-payment-cards.png
export const fiatPaymentImage = 'https://trade-raid-com.mo.cloudinary.net/fiat-payment-cards.png';
/**
 * @note raw data for services, used for displaying information using the products component in /components/Shop/products.vue
 * @note used in /services to display es/ts/shop and in /shop to display es/ts
 */
export const services = [
    {
        active: true,
        backgroundImage: 'https://trade-raid-com.mo.cloudinary.net/trade-service_2.png',
        boost_method: null,
        custom_options_button: null,
        delivery_time: 24,
        description_list: [],
        description_long: null,
        tags: [
            {
                title: 'service',
                color: '#19bc8b',
            },
            {
                title: 'steam skins for MMO-gold',
                color: '',
            },
        ],
        description_short:
            'Start trading your Steam Items (CSGO/DOTA2/TF2/RUST) for WoW Retail/Classic SoM/WOTLK, New World, Lost Ark, TESO and FFXIV Gold here!',
        execution_speed: null,
        game: 'All',
        price: 0,
        regions: ['EU', 'US'],
        reward_list: [],
        title: 'Trade Service',
        type: 'service',
        edge: '/trade',
    },
    {
        active: true,
        backgroundImage: 'https://trade-raid-com.mo.cloudinary.net/exchange-service_2.png',
        boost_method: null,
        custom_options_button: null,
        delivery_time: 24,
        description_list: [],
        tags: [
            {
                title: 'service',
                color: '#19bc8b',
            },
            {
                title: 'swap gold',
                color: '',
            },
        ],
        description_long: null,
        description_short:
            'Swap gold across servers and games. Exchange Service allows you to trade gold Between Retail, SoM, WOTLK, Lost Ark in all sorts of combinations!',
        execution_speed: null,
        game: 'All',
        price: 0,
        regions: ['EU', 'US'],
        reward_list: [],
        title: 'Exchange Service',
        type: 'service',
        edge: '/exchange',
    },
    {
        active: false,
        backgroundImage: 'https://trade-raid-com.mo.cloudinary.net/service_shop_image.png',
        boost_method: null,
        custom_options_button: null,
        delivery_time: 24,
        description_list: [],
        tags: [
            {
                title: 'shop',
                color: '#19bc8b',
            },
            {
                title: '✨new',
                color: "rgb(75, 144, 255)"
            },
            // {
            //     title: 'boosts',
            //     color: '',
            // },
            // {
            //     title: 'mmo-gold',
            //     color: '',
            // }
        ],
        description_long: null,
        description_short:
            'Buy MMO Gold and Boosts',
        execution_speed: null,
        game: 'All',
        price: 0,
        regions: ['EU', 'US'],
        reward_list: ['Dragonflight Gold', 'Classic Era Gold', 'WOTLK Gold', 'FFIXV Gold', 'Lost Ark Gold'],
        title: 'Shop',
        type: 'service',
        edge: '/shop',
    },
    {
        active: false,
        backgroundImage: 'https://trade-raid-com.mo.cloudinary.net/mmo-swap.png',
        boost_method: null,
        custom_options_button: null,
        delivery_time: 24,
        description_list: [],
        tags: [
            {
                title: 'service',
                color: '#19bc8b',
            },
            {
                title: 'MMO-Gold for steam items',
                color: '',
            }
        ],
        description_long: null,
        description_short:
            'Trade MMO Gold for Steam Skins',
        execution_speed: null,
        game: 'All',
        price: 0,
        regions: ['EU', 'US'],
        reward_list: ['Coming soon!'],
        title: 'MMO Swap',
        type: 'service',
        edge: null,
    }
];

export const shopItemDisplay = {
    amount: 1,
    app_id: 1,
    asset_id: 1,
    context_id: 1,
    currency: '$',
    exterior: null,
    hash: 'Dragonflight Gold',
    image: 'https://trade-raid-com.mo.cloudinary.net/fiat-payment-stacked.png',
    market_hash_name: 'Dragonflight Gold',
    marketable: true,
    name: 'Dragonflight Gold',
    price: 200,
    steam_price: 200,
    tradable: true,
    edge: '/shop',
    type: 'display-item'
};




