import VatService from './VatService.js';

const PriceService = {
    splitNum: (num) => {
        num = num.toString().split('.');
        num[0] = Number(num[0]);
        if (num[1]) num[1] = Number(`0.${num[1]}`);
        else num[1] = 0;
        return num;
    },
    gamesWithSilver(game) {
        const games = ['Dragonflight Retail', 'WOTLK Classic', 'Classic Season of Mastery', 'Classic Era'];
        return games.includes(game);
    },
    formatSilver: (num) => {
        num = num.toString().split('.');
        return Number(num[1]);
    },
    abbreviateNumber: (num) => {
        if (num >= 1000000) {
            return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }
        return num;
    },
    calculatePrice: (price, country, extra_gold, currency) => {
        if (extra_gold) {
            let p = price / 0.97;
            price = p;
        }
        const VAT = VatService.calculateVat(country, price);
        if (VAT.data) {
            price = parseInt(VAT.price.toFixed(10), 10);
        }
        const num = PriceService.splitNum(price);
        return {
            raw: price.toFixed(1),
            gold: PriceService.abbreviateNumber(Math.ceil(num[0])),
            silver: PriceService.formatSilver(num[1].toFixed(2)),
            currency,
        };
    },
};

export default PriceService;
