<template>
    <div class="profile-info">
        <div class="section-title">Profile</div>
        <div class="section" style="color: white">
            <div class="profile-title"><img :src="user.avatar" /> {{ user.username }}</div>

            <div class="profile-item-counter">
                <p class="secondary-color-text">Email:</p>
                <p class="value">{{ getEmail }}</p>
            </div>
            <div class="section-line"></div>
            <div class="profile-item-counter">
                <p class="secondary-color-text">Country:</p>
                <p class="value">{{ getCountry }}</p>
            </div>
            <div class="section-line"></div>
            <div class="profile-item-counter">
                <p class="secondary-color-text">Registration:</p>
                <p class="value">{{ new Date(user.created_at).toLocaleDateString() }}</p>
            </div>
            <div class="section-line"></div>
            <div class="profile-item-counter">
                <p class="secondary-color-text">Exchange Orders</p>
                <p class="value">{{ getExchangeOrders }}</p>
            </div>
            <div class="section-line"></div>
            <div class="profile-item-counter">
                <p class="secondary-color-text">Trade Orders</p>
                <p class="value">{{ getTradeOrders }}</p>
            </div>
                <div class="section-line"></div>
            <div class="profile-item-counter">
                <p class="secondary-color-text">Shop Orders</p>
                <p class="value">{{ getShopOrders }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConnectSteam from '../ConnectSteam';
import { orderCount } from '../../api/auth';
export default {
    name: 'profileInfo',
    props: {
        orderCount: Object,
    },
    components: {
        ConnectSteam,
    },
    data() {
        return {
            country: '',
            exchangeOrdersN: null,
            tradeOrdersN: null,
            shopOrdersN: null,
            no_email: false,
        };
    },
    methods: {
        async getUserOrderCount() {
            try {
                this.exchangeOrdersN = (await orderCount(this.user._id, 'es', this.user.access_token)).data.val;
            } catch (error) {
                // error
            }
            try {
                this.tradeOrdersN = (await orderCount(this.user._id, 'ts', this.user.access_token)).data.val;
            } catch (error) {
                // error
            }
            try {
                 this.shopOrdersN = (await orderCount(this.user._id, 'shop', this.user.access_token)).data.val;
            } catch (error) {
                // error
            }
        },
    },
    computed: {
        ...mapGetters(['user']),
        getCountry() {
            if (this.user && this.user.country) {
                return this.user.country;
            }
            return '-';
        },
        getEmail() {
            if (this.user && this.user.email) {
                return this.user.email;
            }
            return '-';
        },
        getExchangeOrders() {
            if (this.exchangeOrdersN >= 1) {
                return this.exchangeOrdersN;
            }
            return '-';
        },
        getTradeOrders() {
            if (this.tradeOrdersN >= 1) {
                return this.tradeOrdersN;
            }
            return '-';
        },
        getShopOrders() {
                if (this.shopOrdersN >= 1) {
                return this.shopOrdersN;
            }
            return '-';
        }
    },
    mounted: async function () {
        await this.getUserOrderCount();
    },
    watch: {
        user: {
            async handler() {
                if (Object.keys(this.user).length > 0) {
                    await this.getUserOrderCount();
                }
            },
        },
    },
};
</script>

<style scoped>
.secondary-color-text {
    color: #c0c0c2;
}

.section-line {
    border-top: 0.5px solid #4c515c;
    width: 100%;
    padding-block: 5px;
}
.profile-info {
    width: 100%;
    min-width: 35vw;
    background-color: #383f51;
    padding: 25px;
    border-radius: 4px;
}
/** medium screens */
@media screen and (max-width: 1500px) {
    .profile-info {
        width: 570px;
    }
}

/** small screens */
@media screen and (max-width: 1000px) {
    /* .profile-info {
        width: 100vw;
    } */
    .profile-item-counter {
        justify-content: flex-start !important;
        gap: 15px;
    }
}
.profile-title {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-bottom: 25px;
}
.profile-item-counter {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.section-title {
    color: #f1f1f1;
    font-size: 25px;
    font-weight: bold;
}
.section {
    border-radius: 25px;
    padding-top: 25px;
}
img {
    border-radius: 25px;
}
</style>
