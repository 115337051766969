<template>
    <v-container fluid fill-height id="about">
        <!-- Det er dette der afgør hvilken CSS den skal bruge -->
        <h1 class="page-title">TRADE-RAID TERMS AND CONDITIONS OF USE</h1>
        <p class="page-sub-title">
            Last updated: June 2nd 2022<br /><br />
            1. GENERAL INFORMATION
            <br /><br />
            These are the terms and conditions of use of the TRADE-RAID service (the Terms of Use) between you (User)
            and: TRADE-RAID, (we, us, or our) Trade-Raid ApS CVR: 43262386, DENMARK. <br />
            The TRAID-RAID service is solely an opportunity for the user to exchange virtual in-game currencies or
            assets between platforms e.g., Steam assets in exchange for World of Warcraft currency (i.e., “gold”). The
            interpretation of these terms and conditions shall be interpretated in the scope of TRADE-RAID’s primary
            service which is trading or exchanging in-game currencies or assets between platforms. <br />
            <br />The TRADE-RAID service may not be utilized for speculative purposes and is to be considered illicit
            behavior and breach of the present Terms of Use. <br />Inquiries, claims or support may only be considered
            valid if the User contact TRAID-RAID’s support team on TRADE-RAID’s Facebook’s page or Steam page. <br />
            TRADE-RAID’s support team is able to review an inquiry that the User may have encountered by using
            TRADE-RAID’s services or assist with issues, bugs, contradictions that the User may have encountered by
            using TRADE-RAID’s services. <br />Claims or disputes shall also be directed to TRADE-RAID’s support team.
            In the given case, the support team will provide the User with a form that must be filled out. By filling
            out the form the User claims that he/she is entitled to compensation, termination, or remediation. By
            accepting the Terms of Use the User consents to use the TRADE-RAID service in compliance with the Terms of
            Use and to act in good faith. <br /><br />
            2. ACCEPTANCE OF AGREEMENT

            <br /><br />Please carefully read this Terms of Use before using TRADE-RAID service as further stated below.
            <br />By creating a TRADE-RAID account (the Account), accessing or using TRADE-RAID services through the
            TRADE-RAID website https://trade-raid.com/ (the Website), you agree to be bound by the Terms and Conditions,
            Privacy Policy, Cookies Policy, which altogether constitute the entire legally binding agreement between you
            and TRADE-RAID, governing your use of our service (collectively, the Agreement). <br />By accessing the
            Website to use the services provided by TRADE-RAID you signify that you have read, understood and have the
            legal capacity to and hereby agree to be legally bound by and to comply with the Terms of Use in full.<br /><br />
            3. TRADE-RAID SERVICE

            <br /><br />TRADE-RAID is an online platform that allows Steam-registered users to exchange Steam-supported
            virtual items for World of Warcraft Gold (virtual gold) with TRADE-RAID in accordance with the Terms and
            Conditions. <br />TRADE-RAID is not affiliated in any way with Valve Corporation and its affiliates
            (collectively the Valve) or Blizzard Entertainment and its affiliates. <br />By accessing our services, you
            agree that the terms of any respective Steam subscriber agreements, World of Warcraft EULA, and/or terms and
            conditions, and/or policies shall apply to you in all respects. <br />You can access the abovementioned
            agreements by following the links listed below respectively: <br /><br />

            World of Warcraft’s EULA:
            https://www.blizzard.com/en-us/legal/fba4d00f-c7e4-4883-b8b9-1b4500a402ea/blizzard-end-user-license-agreement

            <br /><br />Valve Corporation: https://store.steampowered.com/subscriber_agreement/<br /><br />
            <br />Any warranties, rights, obligations, or other contractual relationships that you may have with respect
            to your Steam account and Valve shall remain consistent with, and part of the Terms of Use hereof.
            <br />TRADE-RAID does not acquire any ownership or any other proprietary rights concerning the virtual items
            and is not a party to any agreement between the Users selling, purchasing, or exchanging virtual items
            through TRADE-RAID. <br />TRADE-RAID is not related to online gambling and does not run any drawings,
            lotteries and/or virtual casinos. <br />TRADE-RAID Service is only available through the Website. TRADE-RAID
            is currently in beta why the User acknowledges that inconveniences, “bugs” or “downtime” may occur
            throughout the beta. By using TRADE-RAID services the User thereby acknowledges not to take part in or
            perform any actions that are to be considered abusive conduct of the Service or disadvantageous for
            TRADE-RAID e.g., trading (or continuous trading) of a wrongly listed Steam item or asset, that the User knew
            or must have known was wrongfully listed with the purpose to obtain an unjustified benefit, enrichment, gain
            or profit. The User acknowledges that TRADE-RAID reserves the right to terminate the User if the
            beforementioned conduct, behavior or actions has been performed. The User also acknowledges that TRADE-RAID
            may be eligible for compensation by the User’s liability, if such actions, behavior or conduct has caused a
            foreseeable, direct or indirect economical loss. <br /><br />

            4. TRADE-RAID REWARDS TERMS AND CONDITIONS
            <br /><br />The Veteran (their CharacterName-Server-Faction) must have previously traded and received gold
            from Trade-Raid. Recruit-a-Friend bonus gold to the Recruit and Veteran is not rewarded if the Veteran has
            not used the Trade-Raid service earlier (Veteran’s Character Name is not registered in Trade-Raid’s
            database). The bonus gold can only be received once per user. Only users that have previously traded and
            received gold from Trade-Raid are eligible to this offer. Trade-Raid must be able to find your order in our
            database by identifying you with either 1) Character Name, 2) E-mail or 3) Steam Name. If the user writes a
            server which is not covered by Trade-Raid’s bonus gold rewards Trade-Raid has the right to not reward the
            user. The user should contact Trade-Raid support within 24 hours in case they made a mistake. Trade-Raid is
            in BETA and all our services and features are in early-stage development and subject to change. Errors and
            issues may occur which means Trade-Raid has the right to decline any agreement. The gold delivery of all
            rewards is up to 30 days. All WOTLK Classic realm and factions may not be covered by the Reward Page
            rewards. In case Trade-Raid deems it is impossible to deliver the gold, Trade-Raid has the right to not
            reward the user. <br /><br />

            5. ELIGIBILITY

            <br /><br />You must be at least 18 years of age to use the Service. <br />Users under the age of 18
            <br />If you are under the age of 18, please ask your parent or your legal guardian to read and accept the
            Terms of Use on your behalf before proceeding further with using the Service unless you are not obliged to
            do so in accordance with your local jurisdiction. <br />By continuing using the Service you affirm that you
            have understood and accepted the Terms and Conditions. If you (or your parent or legal guardian, if
            applicable) do not agree with the Terms and Conditions, then you may not use or access the Service or any
            part thereof. <br />By creating an account as further stated below, you represent and warrant that you are a
            “natural person” who is over the age of 18 or whose parent or legal guardian have accepted and agreed to the
            Terms and Conditions. <br />By using the Service, you hereby unconditionally declare, warrant and guarantee
            that: <br />(1) according to your local jurisdiction you are of legal age and eligible to enter into the
            Agreement. <br />(2) according to your local jurisdiction you have no restrictions to use the TRADE-RAID
            Service. <br />(3) you will comply with the Terms of Use and all applicable laws and regulations and will
            not directly or indirectly use the Service for any illegal activities. <br />(4) you have all necessary and
            relevant experience and knowledge to deal with virtual items, have a full understanding of their framework,
            are aware of all the merits, risks and any restrictions associated with exchanging virtual items between the
            Steam Platform and World of Warcraft as well as the necessary and relevant expertise and knowledge to trade,
            sale and/or purchase them, and accept the sole responsibility for any decisions made in respect of such
            items within the Service. <br />(5) you are a rightful holder of all the virtual items associated with your
            Steam ID.<br /><br />

            6. USER’S RIGHTS
            <br /><br />TRADE-RAID acknowledges that the User reserves the right to: <br />(1) require the agreed amount
            of World of Warcraft in-game virtual gold if the agreed amount of gold fails to be delivered to the agreed
            destination given by the User within 24 hours after you confirmed the offer on the User’s Steam-application.
            In the given scenario, the User acknowledges that the given right may only be put to force if the User files
            a complaint by contacting TRAD-RAID support within 24 hours after the User rightfully asserts that the
            agreed of amount of gold has failed to be delivered. The User acknowledges that failing to contact
            TRADE-RAID support within 24 hours after the given assertion is to be considered as forfeiture inaction by
            the User. <br />
            (1a) Trade-Raid’s guaranteed delivery time of 24 hours also accounts for its gold deliveries through its
            Exchange Service. The delivery time is always initiated from the moment that Trade-Raid has confirmed the
            gold deposit from the User, and not when the User claims to has sent the gold to Trade-Raid. In case of a
            dispute, both Trade-Raid and the User have to provide documentation for their activities.
            <br />(2) require any data, information, or interaction in relation or between the User and TRADE-RAID
            deleted at the User’s request at any time. The User acknowledges that TRADE-RAID has 10 business days to
            finally terminate the User’s data counting from the first business day the User required its data
            terminated. The User acknowledges that the present requisition may only be forceable if the User provides
            the requisition in writing to TRADE-RAID’s support team. <br />(3) require any data, information, or
            interaction in relation or between the User and TRADE-RAID issued to the User at any time. The User
            acknowledges that such requisition may fulfilled by TRADE-RAID issuing data, information, or interaction by
            issuing such in a digital format such as PDF. The User acknowledges that TRADE-RAID has 10 business days to
            issue the User’s data counting from the first business day the User required its data terminated. The User
            acknowledges that the present requisition may only be forceable, if the User provides the requisition in
            writing to TRADE-RAID’s support team.<br /><br />

            7. TRADE-RAID’S RIGHTS

            <br /><br />You acknowledge that we reserve the right to: <br />(1) refuse any trade of any virtual item;
            <br />(2) refuse to deliver virtual gold according to the User’s WoW Details (WoW Version, Server, Faction,
            Gold Delivery Method, Character Name, Battle Pet name) if the User has failed to inform the correct
            information and does not cooperate with TRADE-RAID support after 7 days of the original exchange. <br />
            (2a) If the User fails to mail its gold to Trade-Raid’s gold recipient through Trade-Raid’s Exchange
            Service, Trade-Raid is not obligated to deliver the agreed Exchanged Gold and the order is considered
            forfeit. Such failures can be due to: Writing Trade-Raid’s Gold Recipient Character Name incorrectly,
            Mailing the gold to the correct Character Name but on the wrong Server, Mailing the gold to the correct
            Character Name but in the wrong Region.
            <br />
            (3) reduce or increase the initially agreed World of Warcraft gold amount in case the exchanged Steam Items
            or assets have an unstable or unrealistic price, have been manipulated in value or for any other relevant
            reason. In case the User disagrees or is unsatisfied with our decision, the User may rightfully prove that
            the decision has been taken on an erroneous or false basis after which TRADE-RAID is legally obligated to
            accept the original agreement. TRADE-RAID will always valuate Steam items or assets in accordance with the
            website BUFF.163's listings, evaluations, or assumptions if possible. <br />(3a) TRADE-RAID is not obligated
            to accede the original agreement partially or in whole, if the Steam items or assets has an unreliable price
            history. These Terms of Use deems an item or asset unreliable if its pricing history is i) illiquid ii)
            sparse in the market, or iii) the item or asset has a low aggregated demand. TRADE-RAID acknowledges that
            TRADE-RAID holds the burden of proof in the assessment of determining an item or assets illiquidity,
            sparsity, or low demand in the market. TRADE-RAID is only obligated to provide such proof by the User’s
            specific request which must be in writing to TRADE-RAID’s support within 24 hours counting from the given
            time that the partial or whole refusal to accede the original agreement has been provided to the User.
            <br />(3b) TRADE-RAID can refuse to deliver the user's gold in case the user has not used TRADE-RAID's
            services within its rightful non-speculative purposes. Any attempt by the user to abuse or scam Trade-Raid's
            services will result in the User's order to be considered forfeit, the termination of the User to use
            TRADE-RAID's services and the exchanged items are non-refundable. <br />(4) modify, suspend and/or
            discontinue the Service, Website or content, features, or offers with or without specified notification.
            <br />(5) provide different capabilities and/or features for our Website, without any recourse to you or
            without any rights of claim assignable to you; <br />(6) undertake any actions that may deem appropriate in
            response to actual and/or suspected violations of these Terms and Conditions, including, but not limited to
            the suspension and/or termination of your access to the Service and/or and/or Website and/or Account;
            <br />(7) restrict the usage of the Service and/or Website and/or without prior notification for the account
            holder that fall within the following categories: <br />(a) Sanctioned individuals whose names are listed on
            international sanction lists (UN, OFAC, EU), and <br />(b) any individual acting on behalf any person listed
            on the above-mentioned international lists; <br />(8) to cooperate with public authorities or third parties
            authorities and/or third parties in relation to the investigation of any suspected or alleged crime or civil
            wrong. <br />TRADE-RAID may also provide information in response to any legal processes, such as subpoenas,
            search warrants and court orders, or to establish or exercise its legal rights or defend against legal
            claims. <br />TRADE-RAID is not be liable for any use or disclosure of such information by such third
            parties.<br />
            (9) Trade-Raid has the right to return/refund the User’s Exchange Service Gold Deposit and thus cancel the
            User’s Exchange Service order on the basis of: 1) the gold has been acquired through illicit means both
            within the rules of the MMO but also according to Danish law or 2) A calculation error or flaw has occurred.
            <br /><br />

            8. TRADE ON HOLD

            <br /><br />If the User’s Steam account is not protected by a ‘Steam Guard Mobile Authenticator’, a
            temporarily hold will placed on the items traded on the TRADE-RAID platform. If a trade hold is placed on
            the items in question, trading will be halted for 15 days by Steam. Due to this trade hold a given agreement
            between both parties (i.e. the User and TRADE-HOLD) will be postponed until the trade hold has been removed
            by Steam (i.e. a time period of 15 days). This means that the User will receive their gold after the trade
            on hold period has ended. <br />If the User’s items is affected by a trade hold by Steam, the User
            acknowledges that the given agreement issued by the User is postponed for 15 days and canceling the Trade
            Offer will result in a “tradeban” for 3 days by Steam. The mentioned tradeban only affects trading on Steam
            and is not governed by TRADE-RAID, but solely by Steam. <br />TRADE-RAID cannot be held accountable for
            tradebans governed by a third party, which the User acknowledges. <br />The User acknowledges that
            TRADE-RAID follows and complies with the general principle of concomitance (i.e. items, goods, assets or
            services are exchanged simultaneously), to which the User acknowledges that the price of the Steam items in
            question may vary in the given holding time of 15 days. Due to these price fluctuations, the User accepts
            that TRADE-RAID reserves the right to modify the original amount of gold of the agreement if the price(s) of
            the item(s) in question has increased/- or decreased since the specified date the User placed the trade on
            the TRADE-RAID platform. <br />The User acknowledges that TRADE-RAID at any time follows the current market
            prices, and that the natural price fluctuations cannot terminate the agreement with TRADE-RAID.<br /><br />

            9. TRADE-RAID ACCOUNT REGISTRATION
            <br /><br />In order to use TRADE-RAID Service you must sign-in through your Steam user account provided by
            Valve. <br />You must have a registered and active World of Warcraft account provided by Blizzard
            Entertainment to receive your World of Warcraft Gold. <br />By signing-in you authorize us to access and use
            certain Steam user account information, including, but not limited to your public Steam profile and the list
            of your virtual items. <br />To know more about the personal data we collect from you and how we use it,
            please visit our Privacy Policy. <br />Upon signing-in, your individual TRADE-RAID account (the Account)
            will be created automatically and you will be assigned with your personal TRADE-RAID ID (the Seller ID) to
            monitor your virtual items and TRADE-RAID balance. <br />You are solely responsible for managing and
            safeguarding your login credentials, maintaining the confidentiality and restricting access to your Account,
            as well as for all activities that occur under these credentials. <br />It is your responsibility to ensure
            that your use of the Service within your Account is in compliance with any applicable laws or regulations.
            <br />Suspension and/or Termination of the Account: <br />If we suspect that you are in a material breach of
            the Terms of Use and any other TRADE-RAID policy, we may undertake the following actions subject to our
            discretion without prior notice:<br />
            i. Suspend your Account;<br />
            ii. Suspend any transactions without any refund;<br />
            iii. Terminate your Account with immediate effect.<br />
            We further note that if the breach is not material, we may provide you with a notice of 21 days to give you
            the opportunity to rectify the breach subject to our discretion.
            <br />We note that we can undertake any and all of the above actions subject to our discretion without any
            liability or further obligation of any kind whatsoever to you or any other party.<br /><br />

            10. TRADE OF VIRTUAL ITEMS
            <br /><br />Using TRADE-RAID Service you can exchange any virtual Steam item available in your Inventory for
            MMO Gold. <br />You are solely responsible for reading and understanding all and any terms and conditions of
            any transactions conducted on, via or as a result of using the Service. <br />Trading Steam Items for
            virtual gold may be against World of Warcraft or Amazon Games' EULA. TRADE-RAID is committed to high safety
            standards regarding its delivery of MMO Gold. <br />All Steam Items shown on the TRADE-RAID website are
            valuated in MMO gold within the User's chosen particular game. <br />The valuation of the items cannot be
            modified or negotiated except after the virtual items have been exchanged. In the case the exchanged steam
            items have been manipulated in price or been unstable, or for any third reason have lost its eligibility as
            an exchangeable item TRADE-RAID may reduce or increase the valuation of the total gold sum of the specific
            order if needed. <br />TRADE-RAID always have the final word in any case. See TRADE-RAID rights section 2).
            <br /><br />

            11. DELIVERY OF VIRTUAL GOLD
            <br /><br />Trade-Raid is obligated to deliver the agreed gold amount to the chosen Server, Faction,
            Character Name and the User’s chosen Gold Delivery method. The time frame from when the User has confirmed
            the offer on their Steam Mobile App till the User receives their Virtual Gold in-game is 24 hours. Although
            Trade-Raid strives to deliver its virtual gold as soon as possible, the delivery time of 24 hours serves as
            a benchmark/indication and not an actual guarantee of delivery within that time frame. In case the delivery
            of virtual gold exceeds beyond 24 hours Trade-Raid will communicate with the User by e-mail the reasoning
            behind the delay. Such reasons may be: Delay of gold delivery, Gold Delivery is not possible, Insufficient
            WoW Details by the User, Manipulation of price of the exchanged Steam Items etc. In the unfortunate case the
            User has by any means written wrong WoW Details the User should contact us through Facebook Livechat or
            admin@traderaid.com to solve the issue as soon possible. TRADE-RAID cannot solve the issue and does not
            account for any losses if the virtual gold has already been delivered with the original WoW Details the User
            has given.<br /><br />

            11. REFUND, RETURN, CANCELLATION POLICY
            <br /><br />Any trades conducted on TRADE-RAID Website are final and not subject to a return, refund and/or
            cancellation. Any successful virtual gold delivery by TRADE-RAID is final and not subject to a return,
            refund and/or cancellation.<br /><br />

            12. TITLE
            <br /><br />The TRADE-RAID logo and any TRADE-RAID product or service names, logos or slogans and/or any
            other intellectual property that may appear on the Website and/or or service are trademarks of TRADE-RAID
            and our affiliates and may not be copied, imitated or used, in whole or in part, without our prior written
            permission. In addition, the features of the Website and/or and their content, designs, magnetic
            translations, domain names, downloadable documents, digital conversions including, without limitation, all
            page headers, custom graphics, button icons and scripts, constitute the intellectual property of TRADE-RAID
            and may not be copied, imitated or used, in whole or in part, without our prior written permission.
            <br />Any trademarks, logos, skins, artworks and other objects of intellectual property (either registered
            or unregistered), presented on the Website and , belong to their respective owners and there are no implied
            licenses to use them, unless otherwise stipulated by the owner in writing. Any unauthorized use is an
            infringement sanctioned by the applicable legislative framework.<br /><br />

            13. INDEMNIFICATION
            <br /><br />The Service is provided via the Website and on any TRADE-RAID platform "as is", we make no
            warranties, expressed or implied, and we hereby disclaim and negate all and any warranties, including
            without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose.
            Further, we do not warrant or make any representations concerning the accuracy, likely results, or
            reliability of the use of the Service and/or materials in relation to our Service or otherwise relating to
            such materials or on any sites and/or affiliated parties linked to this site. To the extent permitted by
            applicable law you shall indemnify, and hold harmless TRADE-RAID, its licensors, licensees, distributors,
            agents, representatives and other authorized users, and all of the foregoing entities' respective officers,
            directors, owners, employees, agents, representatives, and assigns from and against any and all claims,
            damages, obligations, losses, liabilities, costs, attorney fees, and expenses arising out of or in
            connection with (i) your use of the Service, (ii) your violation of the Agreement, (iii) the sale, purchase,
            and/or use of any virtual items, (iv) your violation of any third party right, including, but not limited
            to, copyright, trademark, or privacy right, and (v) any submission by you that causes damage to a third
            party. You agree to cooperate as fully as reasonably required in the defense of any claim. TRADE-RAID
            reserves the right to assume the exclusive defense and control of any matter otherwise subject to
            indemnification by you. You shall not enter into any agreement that affects the rights of TRADE-RAID without
            TRADE-RAID’s prior written approval.<br /><br />

            14. LIMITATION OF LIABILITY

            <br /><br />Neither TRADE-RAID nor any other party involved in creating or delivering the and/or Website
            and/or Services shall be liable for any direct, indirect, incidental, special, punitive, consequential, or
            exemplary damages arising out of or in connection with (i) any use of, or inability to use, the Services,
            the Website or the or (ii) the sale, purchase, or use of the virtual items. The Website or Service may
            contain links to other third-party websites. We are not in any way responsible for the content of such links
            as these are not in any way monitored by us. We shall not be held liable and/or responsible for the content
            of such third-party websites and accept no liability for any loss or damages occurring as a result of or
            relating to the use of these websites. We note that if you access these third-party websites, you do so at
            your own risk. The foregoing limitation applies regardless of the nature of the cause of action (whether
            breach of contract or tort, including negligence) and even if TRADE-RAID has previously been advised of, or
            reasonably could have foreseen, the possibility of such damage or loss. The foregoing limitation applies to
            all damages arising out of or in connection with any use of, or inability to use the Service, the Website,
            or the or, including but not limited to: (i) errors, mistakes, or inaccuracies; (ii) personal injury or
            property damage; (iii) any unauthorized access to or use of our secure servers, including any personal or
            financial information stored therein; (iv) any bugs, viruses, trojan horses, or the like that may be
            transmitted through the or services; and/or (v) any goods sold or purchased. To the extent that any
            jurisdiction does not allow the exclusion or limitation of any incidental or consequential damages, the
            above limitation shall apply to the extent permissible under applicable law. TRADE-RAID’s aggregate
            liability to a User in any circumstance is limited to either the amount paid by the user to TRADE-RAID
            during the year preceding the claim or $100, whichever is greater.<br /><br />

            15. RISK ASSESMENT IN VIOLATION OF THIRD-PARTY TERMS AND CONDITIONS

            <br /><br />1. Trade-Raid’s services may conflict with a third party’s terms and conditions of which the
            User acknowledges that by using Trade-Raid’s services, the User is solely acting at its own risk by the
            actions, conducts, behavior and/or omissions that may conflict with a third party’s ruleset. Well known
            consequences may be, but is not limited to: <br />i) Temporal trade-ban (the term originates from the Steam
            platform and means that the User is unable to trade in a specific timeframe) <br />ii) Account suspension
            (i.e., the User is unable to use its World of Warcraft account in a certain time frame. Usually acquired
            goods will be permanently removed from the User’s World of Warcraft account, which has only happened on one
            occasion in Trade-Raid’s entire operation history) <br />iii) Permanent account ban (i.e. the User’s World
            of Warcraft account will be permanently banned. To our knowledge, there has not been an incident where a
            Trade-Raid User that has been permanently banned; directly nor indirectly by using Trade-Raid’s service.

            <br /><br />
            16. RISK ASSESMENT IN LOSSES CAUSED BY FRADULANT SCHEMES

            <br /><br />Background: Before using Trade-Raid’s services, we strongly advise you to obtain an
            understanding of the current risk factors that are present within the Steam platform, here in particular:
            API scamming and other similar fraudulent schemes and beyond all: how to avoid them. To our knowledge, some
            scamming methods are more technical complicated than the other If you lose your Steam items due to API
            scamming, Trade-Raid will regrettably not be able to assist you in any way. Do not hesitate to contact our
            Support team before trading, especially if you are a newcomer here on Trade-Raid. <br />1. The User
            acknowledges that the common risk of being the target of an API scam is solely at the User’s own risk. Any
            valuables, goods or assets lost due to a scam or similar denomination of any origin is to be considered a
            conduct, transaction or agreement outside Trade-Raid’s operation of service which the User acknowledges. In
            the given nature of the beforementioned actions, Trade-Raid cannot be held accountable for a User’s in-or
            direct losses caused by fraud of which the User acknowledges.

            <br /><br />
            17. RISK ASSESMENT IN LOSSES OF WORLD OF WARCRAFT IN-GAME (VIRTUAL) GOLD
            <br /><br />Background: It is commonly known that to obtain in-game World of Warcraft gold for real life
            currency (outside the World of Warcraft’s in-game shop) is against the game’s Terms of Use. It is
            questionable whether obtaining in-game World of Warcraft gold by trading another in-game asset, as we
            believe that an acquisition of such is incomparable to an actual purchase for real life currency. Though,
            Blizzard may conflict our understanding with a more unfortunate view, which may put your newly acquired gold
            stock at risk. We here at Trade-Raid fully understand this, why we have tried to accompany these concerns
            with great precautions. <br />The World of Warcraft gold that we obtain and deliver to your mailboxes, comes
            from trusted partners that we have been associated with for more than 5 years. The partners that we deal
            with is legitimate boosting communities, who obtain its gold within World of Warcraft’s Terms of Use. This
            can be achieved by boosting or performing similar in-game services for in-game gold only and without
            account-sharing (also more commonly known as “self-play”). Although that we will never be able to guarantee
            that a goldstrip will not happen, we can statistically assure, that this happens extremely rarely. As a
            matter of fact, only one out of thousands of Trade-Raid Users has been a victim of a so-called goldstrip. If
            you have any concerns in regards of this topic; feel free to further your concerns by reaching out to our
            Support team. <br /><br />1. The User acknowledges that its agreement with Trade-Raid is fulfilled when the
            User has received the agreement’s specified amount of World of Warcraft in-game gold. <br />1a. The User
            acknowledges that the permanent removal of the World of Warcraft in-game gold obtained by an agreement with
            Trade-Raid is solely at the User’s own risk, of which Trade-Raid is unaccountable. <br />2. The User
            acknowledges the risk of getting its World of Warcraft account suspended or permanently banned allegedly due
            to World of Warcraft in-game gold obtained from Trade-Raid. The User acknowledges that trading in-game
            goods, assets or valuables for World of Warcraft in-game gold may conflict or constitute a breach of third
            party’s Terms of Use of which Trade-Raid is unaccountable. <br /><br />
            18. AMENDMENTS
            <br /><br />We may make changes to this Agreement and to the Service from time to time without prior
            notification. We may do this for a variety of reasons including changes in or requirements of the law, new
            features, or changes in business practices and/or any other reason. The most recent version of this
            Agreement will be posted on the Website and on the , and you should regularly check for the most recent
            version. If the changes include material changes that affect your rights or obligations, we will notify you
            of the changes by reasonable means, which could include notification through the Service or via email. If
            you continue to use the Service after the changes become effective, then you agree to the revised
            Agreement.<br /><br />
            19. TERMINATION
            <br /><br />If you wish to terminate this Agreement as between you and TRADE-RAID, you can do so at any time
            by no longer accessing and/or using TRADE-RAID Service.<br /><br />
            20. DELETION OF ACCOUNT
            <br /><br />You may request us to delete your Account without cause at any time via sending us an email at
            admin@traderaid.com. If you close your Account, then you will not be able to use the Service.<br /><br />

            21. NO WAIVER
            <br /><br />No waiver made by TRADE-RAID of any breach or any provision of this Agreement shall be deemed a
            waiver of any subsequent or prior breach of the same or any other provision.<br /><br />

            22. SEVERABILITY
            <br /><br />If any provision of these Terms of Use is deemed invalid and/or unenforceable under any statute,
            regulation, ordinance, legislation or by an arbitrator or court of competent jurisdiction, then such
            provision shall be deemed reformed or deleted but only to the extent necessary to comply with such statute,
            regulation, ordinance, arbitrator, or court, and the remaining provisions shall remain in full force and
            effect.<br /><br />

            23. ASSIGNMENT
            <br /><br />TRADE-RAID may assign, subcontract or otherwise transfer its rights and/or obligations hereunder
            without notice to you or obtaining your consent. You may not assign, subcontract or otherwise transfer your
            rights and/or obligations hereunder.<br /><br />

            24. APPLICABLE LAW AND JURISDICTION
            <br /><br />All enquiries relating to the construction, validity, enforcement and interpretation of this
            Agreement shall be governed by and construed and enforced in accordance with the laws of Cyprus. Any claims
            shall be sent to Spyrou Arauzou Koumantarias, 705, Fayza House, 1st floor, 3036, Limassol, Cyprus and
            e-mailed to admin@TRADE-RAID.<br /><br />

            25. MARKETING
            <br /><br />
            25.1 When you register as a User at Trade-Raid, answer our Feedback Survey, successfully complete an order
            at our Exchange Service or our Trade Service, you accept to receive marketing, information and offers about
            new products and services. Trade-Raid sends its marketing material to Users that have not explicitly
            expressed that they do not want to receive marketing from Trade-Raid. If a User has not declined to receive
            marketing, Trade-Raid will send you information and offers about new products and services via. e-mail.
            <br />
            25.2 You can at any time withdraw your consent by using the unsubscribing in our e-mails you receive from us
            provided Mailchimp. Trade-Raid does not re-subscribe unsubscribed Users, and the User has to subscribe to
            Trade-Raid’s marketing through one of our sign up forms at the Trade-Raid website. <br /><br />

            26. GIVEAWAYS
            <br /><br />
            26.1 Subject of the conditions of participation and organizer
            <br />
            26.2 The giveaway is organized for TRADE-RAID by TRADE-RAID ApS, Denmark.
            <br />
            26.3 By registering on TRADE-RAID, retweeting our giveaway tweets or sign up to our newsletter you accept
            these giveaway terms.
            <br />
            26.4 This giveaway is not connected, sponsored or supported by the platform or media where this is being
            marketed.
            <br />
            26.5 This giveaway and all its contents is subject to the laws of the Kingdom of Denmark.
            <br />
            26.6 The giveaway will consist of a digital asset or good such as MMO Gold or Steam Items between EUR 20 to
            EUR 10,000.
            <br />
            26.7 Winners are always randomly selected from all eligible participants. The winner will be notified
            directly through relevant communication channels such as e-mail or direct message through TRADE-RAID’s
            Social Media Pages.
            <br />
            26.8 If TRADE-RAID is not able to contact the winner within 3 days from the draw, the right and claim to the
            giveaway expires. In this case, TRADE-RAID is entitled to carry out a replacement draw.
            <br />
            26.9 By participating you accept that in the event you win, TRADE-RAID may use your username in any
            communications and on any media for advertising purposes.
            <br /><br />

            26. CLASS ACTION WAIVER
            <br /><br />Where permitted under the applicable law, you agree that you may bring claims against TRADE-RAID
            only in your individual capacity and not as a plaintiff or class member in any purported class or
            representative action.<br /><br />

            27. CONTACT DETAILS
            <br /><br />Should you have any questions, enquiries, remarks, complaints or comments regarding the Service,
            our Website or this Agreement please contact our Support team at admin@traderaid.com. We will reply you as
            soon as possible. <br /><br />
            ©2022 “TRADE-RAID” ALL RIGHTS RESERVED. Trade-Raid ApS CVR: 43262386, DENMARK.
        </p>
    </v-container>
</template>

<script>
export default {
    name: 'Terms of Use',
    metaInfo: {
        title: 'Terms of Use',
        meta: [
            { charset: 'utf-8' },
            { vmid: 'description', name: 'description', content: 'Trade-Raid terms and conditions' },
        ],
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
    #about {
        width: 100%;
        height: auto;
    }
}
#about {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    .page-title {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    .page-sub-title {
        text-align: center;
        width: 100%;
        max-width: 963px;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
    }
    .about-container {
        width: 100%;
        max-width: 1530px;
        .image-container {
            width: 100%;
            height: 100%;
            max-height: 400px;
            min-height: 400px;
            margin-bottom: 80px;
            background: url('https://trade-raid-com.mo.cloudinary.net/dota.jpeg') no-repeat;
            background-size: cover;
            background-position: 0 -108px;
        }
        .info-container {
            h2 {
                font-weight: 600;
                font-size: 36px;
                line-height: 60px;
                color: #fff;
            }
            .desc {
                font-size: 18px;
                line-height: 35px;
                color: rgba(255, 255, 255, 0.7);
                margin-bottom: 30px;
            }
            a {
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 3px;
                font-family: Open Sans;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                color: #ffffff;
                text-decoration: none;
                padding: 13px 30px;
                display: inline-block;
            }
            a + a {
                margin-left: 20px;
            }
        }
    }
}
@media screen and (max-width: 960px) {
    #about .about-container .info-container h2,
    #about .about-container .info-container .desc {
        text-align: center;
    }
}
</style>
