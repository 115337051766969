<template>
    <div>
        <!-- <a
            class="e-widget no-button"
            href="https://gleam.io/gFx9W/3x-25000-wotlk1000000-df-gold-giveaway"
            rel="nofollow"
            >Dragonflight Launch Giveaway</a
        > -->
    </div>
</template>
<script>
export default {
    created() {
        var scripts = ['https://widget.gleamjs.io/e.js'];
        scripts.forEach((script) => {
            let tag = document.createElement('script');
            tag.setAttribute('src', script);
            document.head.appendChild(tag);
        });
    },
};
</script>
