<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <div class="container">
            <div class="card">
                <img class="logo" src="https://trade-raid-com.mo.cloudinary.net/white-tr.png" alt="white-tr" />

                <div class="item">
                    <div class="circle"></div>
                    <img
                        src="https://trade-raid-com.mo.cloudinary.net/ts_services_all_games.png"
                        alt="trade-raid.com"
                    />
                </div>
                <div class="info-services">
                    <h1 class="title">Trade Service</h1>
                    <p class="paragraph">
                        Start trading your Steam Items (CSGO/DOTA2/TF2/RUST) for WoW Retail/Classic Era/WOTLK, New
                        World, Lost Ark, TESO and FFXIV Gold here!
                    </p>
                </div>
                <div class="service">
                    <router-link to="/trade" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
                        <button @click="navigate" class="pulse">Open Trade Service</button>
                    </router-link>
                </div>
            </div>
            <div class="card">
                <img class="logo" src="https://trade-raid-com.mo.cloudinary.net/white-tr.png" alt="white-tr" />
                <div class="item">
                    <div class="circle"></div>
                    <img
                        class="img2"
                        src="https://trade-raid-com.mo.cloudinary.net/es-service@100x.png"
                        alt="trade-raid.com"
                    />
                </div>
                <div class="info-services">
                    <h1 class="title">Exchange Service</h1>
                    <!-- <div class="tip-wrapper">
                    <span class="tip">NEW SERVICE AVAILABLE!</span>
                </div> -->
                    <p class="paragraph">
                        Swap gold across servers and games. Exchange Service allows you to trade gold Between Retail,
                        Classic Era, WOTLK, Lost Ark in all sorts of combinations!
                    </p>
                    <div class="sizes">
                        <!-- -->
                    </div>
                </div>
                <div class="service">
                    <router-link to="/exchange" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
                        <button @click="navigate" class="pulse">Open Exchange Service</button>
                    </router-link>
                </div>
            </div>
        </div>
    </v-lazy>
</template>

<script>
export default {
    name: 'Trade Steam Skins to WoW Gold',
    metaInfo: {
        title: 'Trade Steam Skins to WoW Gold',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Get started using Trade-Raids MMO Gold trading Services. Use our Trade Service to Trade your Steam Items to MMO gold or Exchange Service to Trade Gold Between Servers, Retail and WOTLK.',
            },
        ],
    },
};
</script>

<style lang="scss" scoped>
.title {
    color: #f1f1f1;
    margin-right: 100px;
    white-space: nowrap;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-left: 7%;
    margin-top: 10rem;
}
.card {
    margin-right: 2rem;
    transform-style: preserve-3d;
    height: 550px;
    width: 500px;
    // min-height: 55vh;
    // max-height: 80;
    // min-height: 50;
    // max-width: 25vw;
    border-radius: 30px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0px 0px 50px rgba(0, 0, 0, 0.2);
    color: #f1f1f1;
}
.logo {
    transition: all 0.75s ease-out;
    width: 5%;
    height: auto;
    opacity: 0.3;
    float: right;
    margin-right: 15px;
    right: 0;
    margin: 1.3rem 1.6rem 0 0;
    position: absolute;
    z-index: 2;
}
.paragraph {
    color: #6d739d;
}
@media screen and (max-width: 960px) {
    .card {
        max-width: 330px !important;
    }
}
.item {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f1f1f1;
    padding-bottom: 1rem;
    @media screen and (min-width: 960px) {
        .img2 {
            width: 100%;
            overflow: hidden;
            z-index: 1;
            transition: all 0.75s ease-out;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            height: 300px;
        }
    }
}
.item img {
    width: 100%;
    height: auto;
    overflow: hidden;
    z-index: 1;
    transition: all 0.75s ease-out;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.info-services {
    padding: 0rem 5rem;
}
.info-services h1 {
    height: 1px;
    font-size: 1rem !important;
    transition: all 0.75s ease-out;
    padding-bottom: 5px;
}
.info-services img {
    width: 1rem;
}

.games {
    justify-content: space-between;
    transition: all 0.75s ease-out;
}
.sizes {
    justify-content: space-between;
    transition: all 0.75s ease-out;
}
.service {
    transition: all 0.75s ease-out;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    margin-bottom: -20px;
    display: flex;
}
.service > button {
    display: flex;
    justify-content: center;
}

li {
    list-style-type: none;
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f1f1f1;
    }
}
li:hover {
    opacity: 0.8;
}

.fill:hover,
.fill:focus {
    box-shadow: inset 0 0 0 2em var(--hover);
}
// Animate the size, outside
.pulse:hover,
.pulse:focus {
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(#fff, 0);
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--hover);
    }
}
// Stack multiple shadows, one from the left, the other from the right
.close:hover,
.close:focus {
    box-shadow: inset -3.5em 0 0 0 var(--hover), inset 3.5em 0 0 0 var(--hover);
}
// Size can also be negative; see how it's smaller than the element
.raise:hover,
.raise:focus {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
}
// Animating from the bottom
.up:hover,
.up:focus {
    box-shadow: inset 0 -3.25em 0 0 var(--hover);
}
// And from the left
.slide:hover,
.slide:focus {
    box-shadow: inset 6.5em 0 0 0 var(--hover);
}
// Multiple shadows, one on the outside, another on the inside
.offset {
    box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);

    &:hover,
    &:focus {
        box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
    }
}
//=== Set button colors
// If you wonder why use Sass vars or CSS custom properties...
// Make a map with the class names and matching colors
$colors: (
    fill: #a972cb,
    pulse: #19bc8b,
    close: #ff7f82,
    raise: #ffa260,
    up: #e4cb58,
    slide: #8fc866,
    offset: #19bc8b,
);
// Sass variables compile to a static string; CSS variables are dynamic and inherited
// Loop through the map and set CSS custom properties using Sass variables
@each $button, $color in $colors {
    .#{$button} {
        --color: #{$color};
        --hover: #{adjust-hue($color, 45deg)};
    }
}
// Now every button will have different colors as set above. We get to use the same structure, only changing the custom properties.
button {
    color: var(--color);
    transition: 0.25s;
    &:hover,
    &:focus {
        border-color: var(--hover);
        color: #fff;
    }
}
// Basic button styles
button {
    background: none;
    border: 2px solid #17d0c1 !important;
    color: #17d0c1 !important;
    font: inherit;
    line-height: 1;
    margin: 0.5em;
    padding: 1em 2em;
    border-radius: 5px;
}

.tip {
    display: inline-block;
    border-radius: 1em;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    background: #e7fdd8;
    color: #66bf3c;
    padding: 6px 12px;
    vertical-align: top;
}

.tip-wrapper {
    margin-top: -30px;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
    cursor: pointer;
    position: absolute;
}

@media only screen and (max-width: 1070px) {
    .card {
        height: auto;
        width: 400px;
    }
}

@media only screen and (max-width: 800px) {
    .container {
        flex-wrap: wrap;
        margin-top: 100px;
    }
    .card {
        margin-top: 25px;
    }
}
</style>