import request from '../utils/request';

export function getRatio() {
    return request({
        url: `/exchange/get/ratio`,
        method: 'get',
    });
}

export function getExchangeServers() {
    return request({
        url: '/exchange/get/exchange-servers',
        method: 'get',
    });
}

export function getDepositServers() {
    return request({
        url: '/exchange/get/deposit-servers',
        method: 'get',
    });
}

export function getTradeAmountExchange() {
    return request({
        url: '/exchange/get/trade-amount',
        method: 'get',
    });
}

export function getTradeAmountTradeService() {
    return request({
        url: '/trade/get/trade-amount',
        method: 'get',
    });
}

export function getServerListTradeService() {
    return request({
        url: '/trade/get/servers',

        method: 'get',
    });
}

export function getServerListMmoGold(region, game) {
    return request({
        url: '/shop/mmo-gold/servers',
        method: 'POST',
        data: {
            region,
            game
        }
    })
}

export function getServerPrice(region,game,server) {
    return request({
        url: '/shop/server-price',
        method: 'POST',
        data: {
            region,
            game,
            server
        }
    });
};

export function convertUrlToData(url) {
    return request({
        url: '/create-data-from-url',
        method: 'POST',
        data: {
            url
        }
    })
}

export function getSteamItemServiceDisplays() {
    return request({
        url: '/shop/item-display/steam',
        method: 'GET'
    });
}