<template>
    <div class="review-order-wrapper">
        <div class="add-contact">
            <div class="form-group">
                <label for="">
                    Discord tag
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <img
                                v-on="on"
                                v-bind="attrs"
                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                alt="info icon"
                                style="margin-left: 7px"
                            />
                        </template>
                        <span>Add your discord so we can get in touch</span>
                    </v-tooltip>
                </label>
                <v-text-field
                    v-model="discord"
                    placeholder="Discord tag required*"
                    :rules="[rules.required]"
                    outlined
                    height="36px"
                    dense
                    hide-details
                >
                    <template v-slot:item="item">
                        {{ item }}
                        <svg
                            style="width: 100%"
                            fill="#adb5bd"
                            role="img"
                            viewBox="0 0 24 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>Discord</title>
                            <path
                                d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"
                            />
                        </svg>
                    </template>
                </v-text-field>
            </div>
        </div>
        <div v-for="(order, index) in getOrders" :key="index" class="order-info">
            <div class="order-flex">
                <img class="order-review-image" :src="order.backgroundImage" />
                <div class="info-field order-column">
                  
                    <div class="flex-inline">
                          <span>{{ order.title }}</span>
                        <div class="info-field margin-auto flex-inline nowrap">{{ $store.state.shop.activeCurrency }} {{ order.price }}</div>
                    </div>
                 
                    <div class="string-collection flex-inline">
                        {{ createInfoString(order) }}
                    </div>
                    <div v-if="order.info.options" class="flex-inline custom-option-display">
                        <li
                            class="tooltip custom-list-bullets mini-font"
                            v-for="(item, cidx) of order.info.options"
                            :key="cidx"
                        >
                            {{ item.name }}
                            <span class="tooltiptext"
                                >+ {{ $store.state.shop.activeCurrency }} {{ item.price_increase }}
                            </span>
                        </li>
                    </div>
                </div>
            </div>
            <div class="flex-inline mt-2 mini-font">
                <ClockIcon class="color-purple icon" /> Delivery Time: {{ order.delivery_time }} Hours
            </div>
            <div class="sold-by flex-inline mini-font mt-2">
                <UserIcon class="icon color-purple" /> <span class="color-white">Sold By: Trade-Raid.com</span>
            </div>
            <div class="actions">
                <div class="remove flex-inline" @click="showremoveItemModal = true">
                    <div class="cursor-pointer flex-inline">
                        <Trash2Icon class="color-grey" />
                        <span class="color-grey"></span>
                    </div>
                </div>
            </div>
            <removeItem-modal
                :show-modal="showremoveItemModal"
                @confirm="removeItem(index)"
                @close="showremoveItemModal = false"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { OrderabbreviateAmount } from '@/utils/orderService.js';
import removeItemModal from '@/components/PaymentFlow/removeItemModal.vue';
export default {
    name: 'PaymentFlowReviewOrder',
    props: {},
    components: {
        removeItemModal,
    },
    data() {
        return {
            rules: {
                required: (value) => !!value || 'Is required!',
            },
            discord: '',
            showremoveItemModal: false,
        };
    },
    computed: {
        ...mapGetters(['user', 'token']),
        getOrders() {
            return this.$store.state.shop.cartItems;
        },
        getActivePaymentMethod() {
            return this.$store.state.shop.activePaymentMethod;
        },
    },
    watch: {
        discord: function () {
            this.$store.commit('SET_KEY_VALUE_PAIR', ['discord_tag', this.discord]);
        },
    },
    methods: {
        createInfoString(order) {
            let string = null;
            if (order.type === 'gold') {
                string = `${order.game} - ${order.info.region} - ${order.info.gold_receive} G`;
            } else {
                string = `${order.game} - ${order.info.region} - ${order.info.boost_method}`;
            }

            return string.toUpperCase();
        },
        removeItem(index) {
            const shallow_ref = this.$store.state.shop.cartItems.filter((x, idx) => {
                if (idx !== index) {
                    return x;
                }
            });
            this.$store.state.shop.cartItems = shallow_ref;
            this.showremoveItemModal = false;
            if(!this.$store.state.shop.cartItems.length) {
                this.$router.push('/shop')
            }
        },
    },
};
</script>

<style scoped lang="scss">
.active-payment-method {
    margin-top: 15px;
    color: #f1f1f1;
}
.order-flex {
    display: flex;
    flex-direction: row;
}

.string-collection {
    font-size: 12px;
}
.order-info {
    margin-bottom: 15px;
    margin-top: 15px;
    background-color: #383f51;
    padding-block: 15px;
    padding-inline: 15px;
    border-radius: 5px;
    color: #f1f1f1;
    font-weight: bold;
}

.order-review-image {
    min-width: 64px;
    max-width: 64px;
    max-height: 64px;
    min-height: 64px;
    border-radius: 5px;
}

@media screen and (max-width: 300px) {
    .order-info {
        display: flex;
        // flex-direction: column;
        // justify-content: center;
        max-width: 280px!important;
        max-width: 280px!important;
        width: 280px!important;
        margin-block: 50px;
        width: 280px;
        margin-left: 10px;
        margin-right: 10px;
        // padding-left: 25px;
        // padding-bottom: 25px;
    }
    .nowrap {
        white-space:nowrap;
    }
}

.order-column {
    padding-left: 15px;
}
.note {
    color: #f1f1f1;
    width: 500px;
}
.form-group {
    margin-block: 15px;
    max-width: 255px;
    min-width: 350px;
    margin-bottom: 20px;
    .v-input {
        background-color: var(--color-dark-grey) !important;
        // border ::active
        color: white;
    }
    label {
        color: #f1f1f1;
    }
    .v-input__append-inner {
        background-color: var(--color-dark-grey) !important;
        color: #f1f1f1 !important;
    }
}
.actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
</style>
