import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './assets/css/main.css';
import './permission';
import DropDown from '../src/components/Drop/down';
import SocialLinks from '../src/components/Social/links';
import SocialLinks_flat from '../src/components/Social/flat_links';
import ButtonLinks from '../src/components/Button/links';
import ContactForm from '../src/components/Contact/form';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import NewsletterSignup from '../src/components/Newsletter/signup';
import NewsletterPopup from '../src/components/Newsletter/popup';
import MaintenanceDialog from '../src/components/Maintenance/dialog';
import ReviewButtons from '../src/components/Review/buttons';
import Announcement from '@/components/AnnouncementBar';
import BlogNews from '../src/components/Blog/news';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueMeta from 'vue-meta';
import BootstrapVue from 'bootstrap-vue';
import VueMasonry from 'vue-masonry-css';
import VueYoutube from 'vue-youtube';
import VueMatomo from 'vue-matomo';
import GleamComp1 from '../src/components/Gleam/comp1';
import GleamComp2 from '../src/components/Gleam/comp2';
import trustpilotCarousel from '../src/components/UI/trustpilot/carousel';
import trustpilotMini from '../src/components/UI/trustpilot/mini';
import TrustpilotPlugin from 'vue-trustpilot';
import Toast from 'vue-toastification';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
import 'vue-toastification/dist/index.css';
import VueGtm from '@gtm-support/vue2-gtm';
import { ChevronRightIcon, ChevronLeftIcon } from 'vue-feather-icons';
import ShoppingCartAsideModule from '../src/components/Shop/ShoppingCartAsideModule.vue';
import VaultModule from '../src/components/Vault/VaultModule.vue';
import AddToCartButton from '../src/components/Shop/AddToCartButton.vue';
import CustomBackDrop from '../src/components/UI/Backdrop'
import ShoppingCartModule from '../src/components/Shop/ShoppingCartModule.vue';
import { ShoppingCartIcon, MessageCircleIcon, ShoppingBagIcon, PlusIcon, XIcon, Trash2Icon, UserIcon, ClockIcon } from 'vue-feather-icons';
import MMOGameIcon from '../src/components/UI/GameIcons/mmo-icons.vue';

Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);
Vue.component('newsletter-popup', NewsletterPopup);
Vue.component('social-links', SocialLinks);
Vue.component('flat-links', SocialLinks_flat);
Vue.component('button-links', ButtonLinks);
Vue.component('contact-form', ContactForm);
Vue.component('drop-down', DropDown);
Vue.component('newsletter-signup', NewsletterSignup);
Vue.component('review-buttons', ReviewButtons);
Vue.component('announcement', Announcement);
Vue.component('maintenance-dialog', MaintenanceDialog);
Vue.component('blog-news', BlogNews);
Vue.component('gleam-comp1', GleamComp1);
Vue.component('gleam-comp2', GleamComp2);
Vue.component('trustpilot-carousel', trustpilotCarousel);
Vue.component('trustpilot-mini', trustpilotMini);
Vue.component('vue-toast', Toast);
Vue.component('ChevronRightIcon', ChevronRightIcon);
Vue.component('ChevronLeftIcon', ChevronLeftIcon);
Vue.component('ShoppingCartModule', ShoppingCartModule);
Vue.component('VaultModule', VaultModule);
Vue.component('AddToCartButton', AddToCartButton);
Vue.component('ShoppingCartAsideModule', ShoppingCartAsideModule);
Vue.component('MMOGameIcon', MMOGameIcon);
Vue.component('ShoppingCartIcon', ShoppingCartIcon);
Vue.component('MessageCircleIcon', MessageCircleIcon);
Vue.component('ShoppingBagIcon', ShoppingBagIcon);
Vue.component('PlusIcon', PlusIcon)
Vue.component('XIcon', XIcon);
Vue.component('Trash2Icon', Trash2Icon)
Vue.component('UserIcon', UserIcon);
Vue.component('ClockIcon', ClockIcon);
Vue.component('CustomBackDrop',CustomBackDrop);
Vue.component('ChevronRightIcon', ChevronRightIcon);
Vue.component('ChevronLeftIcon', ChevronLeftIcon);

const carouselWidget = {
    templateId: '53aa8912dec7e10d38f59f36',
    businessunitId: '602b33e15092110001846808',
    reviewUrl: 'https://uk.trustpilot.com/review/trade-raid.com',
    locale: 'en-us',
};

const options = {
    widgets: {
        carousel: carouselWidget,
    },
};

Vue.use(VueGtm, {
    id: 'GTM-5D3DQFN',
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
});

Vue.use(TawkMessengerVue, {
    propertyId: '6384be6db0d6371309d18526',
    widgetId: '1giv8fnkl',
});

Vue.use(TrustpilotPlugin, options);
Vue.use(Toast, {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 20,
    newestOnTop: true,
});
Vue.use(BootstrapVue);
Vue.use(VueMasonry);
Vue.use(VueYoutube);

Vue.use(VueMeta, {
    keyName: 'head',
});

Vue.use(VueScrollTo, {
    duration: 3000,
    easing: 'ease',
});

Vue.use(VueMatomo, {
    host: 'https://traderaid.matomo.cloud/',
    siteId: 1,
    trackerFileName: 'matomo',
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
});

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
