const self = {
    // https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_da.htm
    // VATs are derived from this site - https://www.globalvatcompliance.com/globalvatnews/world-countries-vat-rates-2020/
    countries: [
        { name: 'Denmark', vat: 0.75 },
        { name: 'Norway', vat: 0 },
        { name: 'Switzerland', vat: 0 },
        { name: 'Afghanistan', vat: 0 },
        { name: 'Albania', vat: 0 },
        { name: 'Aland Islands', vat: 0 },
        { name: 'Algeria', vat: 0 },
        { name: 'American Samoa', vat: 0 },
        { name: 'Andorra', vat: 0 },
        { name: 'Angola', vat: 0 },
        { name: 'Anguilla', vat: 0 },
        { name: 'Antarctica', vat: 0 },
        { name: 'Antigua and Barbuda', vat: 0 },
        { name: 'Argentina', vat: 0 },
        { name: 'Armenia', vat: 0 },
        { name: 'Aruba', vat: 0 },
        { name: 'Australia', vat: 0 },
        { name: 'Austria', vat: 0.8 },
        { name: 'Azerbaijan', vat: 0 },
        { name: 'Bahamas', vat: 0 },
        { name: 'Bahrain', vat: 0 },
        { name: 'Bangladesh', vat: 0 },
        { name: 'Barbados', vat: 0 },
        { name: 'Belarus', vat: 0 },
        { name: 'Belgium', vat: 0.79 },
        { name: 'Belize', vat: 0 },
        { name: 'Benin', vat: 0 },
        { name: 'Bermuda', vat: 0 },
        { name: 'Bhutan', vat: 0 },
        { name: 'Bolivia', vat: 0 },
        { name: 'Bosnia and Herzegovina', vat: 0 },
        { name: 'Botswana', vat: 0 },
        { name: 'Bouvet Island', vat: 0 },
        { name: 'Brazil', vat: 0 },
        { name: 'British Antarctic Territory', vat: 0 },
        { name: 'British Indian Ocean Territory', vat: 0 },
        { name: 'British Virgin Islands', vat: 0 },
        { name: 'Brunei', vat: 0 },
        { name: 'Bulgaria', vat: 0.8 },
        { name: 'Burkina Faso', vat: 0 },
        { name: 'Burundi', vat: 0 },
        { name: 'Cambodia', vat: 0 },
        { name: 'Cameroon', vat: 0 },
        { name: 'Canada', vat: 0 },
        { name: 'Cape Verde', vat: 0 },
        { name: 'Cayman Islands', vat: 0 },
        { name: 'Central African Republic', vat: 0 },
        { name: 'Chad', vat: 0 },
        { name: 'Chile', vat: 0 },
        { name: 'China', vat: 0 },
        { name: 'Christmas Island', vat: 0 },
        { name: 'Cocos [Keeling] Islands', vat: 0 },
        { name: 'Colombia', vat: 0 },
        { name: 'Comoros', vat: 0 },
        { name: 'Congo - Brazzaville', vat: 0 },
        { name: 'Congo - Kinshasa', vat: 0 },
        { name: 'Cook Islands', vat: 0 },
        { name: 'Costa Rica', vat: 0 },
        { name: 'Croatia', vat: 0.75 },
        { name: 'Cuba', vat: 0 },
        { name: 'Cyprus', vat: 0.81 },
        { name: 'Czech Republic', vat: 0.8 },
        { name: 'Côte d’Ivoire', vat: 0 },
        { name: 'Djibouti', vat: 0 },
        { name: 'Dominica', vat: 0 },
        { name: 'Dominican Republic', vat: 0 },
        { name: 'Ecuador', vat: 0 },
        { name: 'Egypt', vat: 0 },
        { name: 'El Salvador', vat: 0 },
        { name: 'Equatorial Guinea', vat: 0 },
        { name: 'Eritrea', vat: 0 },
        { name: 'Estonia', vat: 0.8 },
        { name: 'Ethiopia', vat: 0 },
        { name: 'Falkland Islands', vat: 0 },
        { name: 'Faroe Islands', vat: 0 },
        { name: 'Fiji', vat: 0 },
        { name: 'Finland', vat: 0.76 },
        { name: 'France', vat: 0.8 },
        { name: 'French Guiana', vat: 0 },
        { name: 'French Polynesia', vat: 0 },
        { name: 'French Southern Territories', vat: 0 },
        { name: 'Gabon', vat: 0 },
        { name: 'Gambia', vat: 0 },
        { name: 'Georgia', vat: 0 },
        { name: 'Germany', vat: 0.81 },
        { name: 'Ghana', vat: 0 },
        { name: 'Gibraltar', vat: 0 },
        { name: 'Greece', vat: 0.76 },
        { name: 'Greenland', vat: 0 },
        { name: 'Grenada', vat: 0 },
        { name: 'Guadeloupe', vat: 0 },
        { name: 'Guam', vat: 0 },
        { name: 'Guatemala', vat: 0 },
        { name: 'Guernsey', vat: 0 },
        { name: 'Guinea', vat: 0 },
        { name: 'Guinea-Bissau', vat: 0 },
        { name: 'Guyana', vat: 0 },
        { name: 'Haiti', vat: 0 },
        { name: 'Heard Island and McDonald Islands', vat: 0 },
        { name: 'Honduras', vat: 0 },
        { name: 'Hong Kong SAR China', vat: 0 },
        { name: 'Hungary', vat: 0.73 },
        { name: 'Iceland', vat: 0 },
        { name: 'India', vat: 0 },
        { name: 'Indonesia', vat: 0 },
        { name: 'Iran', vat: 0 },
        { name: 'Iraq', vat: 0 },
        { name: 'Ireland', vat: 0.77 },
        { name: 'Isle of Man', vat: 0 },
        { name: 'Israel', vat: 0 },
        { name: 'Italy', vat: 0.78 },
        { name: 'Jamaica', vat: 0 },
        { name: 'Japan', vat: 0 },
        { name: 'Jersey', vat: 0 },
        { name: 'Jordan', vat: 0 },
        { name: 'Kazakhstan', vat: 0 },
        { name: 'Kenya', vat: 0 },
        { name: 'Kiribati', vat: 0 },
        { name: 'Kuwait', vat: 0 },
        { name: 'Kyrgyzstan', vat: 0 },
        { name: 'Laos', vat: 0 },
        { name: 'Latvia', vat: 0.79 },
        { name: 'Lebanon', vat: 0 },
        { name: 'Lesotho', vat: 0 },
        { name: 'Liberia', vat: 0 },
        { name: 'Libya', vat: 0 },
        { name: 'Liechtenstein', vat: 0 },
        { name: 'Lithuania', vat: 0.79 },
        { name: 'Luxembourg', vat: 0.83 },
        { name: 'Macau SAR China', vat: 0 },
        { name: 'Macedonia', vat: 0 },
        { name: 'Madagascar', vat: 0 },
        { name: 'Malawi', vat: 0 },
        { name: 'Malaysia', vat: 0 },
        { name: 'Maldives', vat: 0 },
        { name: 'Mali', vat: 0 },
        { name: 'Malta', vat: 0.82 },
        { name: 'Marshall Islands', vat: 0 },
        { name: 'Martinique', vat: 0 },
        { name: 'Mauritania', vat: 0 },
        { name: 'Mauritius', vat: 0 },
        { name: 'Mayotte', vat: 0 },
        { name: 'Mexico', vat: 0 },
        { name: 'Micronesia', vat: 0 },
        { name: 'Moldova', vat: 0 },
        { name: 'Monaco', vat: 0 },
        { name: 'Mongolia', vat: 0 },
        { name: 'Montenegro', vat: 0 },
        { name: 'Montserrat', vat: 0 },
        { name: 'Morocco', vat: 0 },
        { name: 'Mozambique', vat: 0 },
        { name: 'Myanmar [Burma]', vat: 0 },
        { name: 'Namibia', vat: 0 },
        { name: 'Nauru', vat: 0 },
        { name: 'Nepal', vat: 0 },
        { name: 'Netherlands', vat: 0.79 },
        { name: 'Netherlands Antilles', vat: 0 },
        { name: 'New Caledonia', vat: 0 },
        { name: 'New Zealand', vat: 0 },
        { name: 'Nicaragua', vat: 0 },
        { name: 'Niger', vat: 0 },
        { name: 'Nigeria', vat: 0 },
        { name: 'Niue', vat: 0 },
        { name: 'Norfolk Island', vat: 0 },
        { name: 'North Korea', vat: 0 },
        { name: 'Northern Mariana Islands', vat: 0 },
        { name: 'Oman', vat: 0 },
        { name: 'Pakistan', vat: 0 },
        { name: 'Palau', vat: 0 },
        { name: 'Palestinian Territories', vat: 0 },
        { name: 'Panama', vat: 0 },
        { name: 'Papua New Guinea', vat: 0 },
        { name: 'Paraguay', vat: 0 },
        { name: 'Peru', vat: 0 },
        { name: 'Philippines', vat: 0 },
        { name: 'Pitcairn Islands', vat: 0 },
        { name: 'Poland', vat: 0.77 },
        { name: 'Portugal', vat: 0.77 },
        { name: 'Puerto Rico', vat: 0 },
        { name: 'Qatar', vat: 0 },
        { name: 'Romania', vat: 0.81 },
        { name: 'Russia', vat: 0 },
        { name: 'Rwanda', vat: 0 },
        { name: 'Réunion', vat: 0 },
        { name: 'Saint Barthélemy', vat: 0 },
        { name: 'Saint Helena', vat: 0 },
        { name: 'Saint Kitts and Nevis', vat: 0 },
        { name: 'Saint Lucia', vat: 0 },
        { name: 'Saint Martin', vat: 0 },
        { name: 'Saint Pierre and Miquelon', vat: 0 },
        { name: 'Saint Vincent and the Grenadines', vat: 0 },
        { name: 'Samoa', vat: 0 },
        { name: 'San Marino', vat: 0 },
        { name: 'Saudi Arabia', vat: 0 },
        { name: 'Senegal', vat: 0 },
        { name: 'Serbia', vat: 0 },
        { name: 'Seychelles', vat: 0 },
        { name: 'Sierra Leone', vat: 0 },
        { name: 'Singapore', vat: 0 },
        { name: 'Slovakia', vat: 0.8 },
        { name: 'Slovenia', vat: 0.78 },
        { name: 'Solomon Islands', vat: 0 },
        { name: 'Somalia', vat: 0 },
        { name: 'South Africa', vat: 0 },
        { name: 'South Georgia and the South Sandwich Islands', vat: 0 },
        { name: 'South Korea', vat: 0 },
        { name: 'Spain', vat: 0.79 },
        { name: 'Sri Lanka', vat: 0 },
        { name: 'Sudan', vat: 0 },
        { name: 'Suriname', vat: 0 },
        { name: 'Svalbard and Jan Mayen', vat: 0 },
        { name: 'Swaziland', vat: 0 },
        { name: 'Sweden', vat: 0.75 },
        { name: 'Syria', vat: 0 },
        { name: 'São Tomé and Príncipe', vat: 0 },
        { name: 'Taiwan', vat: 0 },
        { name: 'Tajikistan', vat: 0 },
        { name: 'Tanzania', vat: 0 },
        { name: 'Thailand', vat: 0 },
        { name: 'Timor-Leste', vat: 0 },
        { name: 'Togo', vat: 0 },
        { name: 'Tokelau', vat: 0 },
        { name: 'Tonga', vat: 0 },
        { name: 'Trinidad and Tobago', vat: 0 },
        { name: 'Tunisia', vat: 0 },
        { name: 'Turkey', vat: 0 },
        { name: 'Turkmenistan', vat: 0 },
        { name: 'Turks and Caicos Islands', vat: 0 },
        { name: 'Tuvalu', vat: 0 },
        { name: 'U.S. Minor Outlying Islands', vat: 0 },
        { name: 'U.S. Virgin Islands', vat: 0 },
        { name: 'Uganda', vat: 0 },
        { name: 'Ukraine', vat: 0 },
        { name: 'United Kingdom', vat: 0 },
        { name: 'United States', vat: 0 },
        { name: 'Uruguay', vat: 0 },
        { name: 'Uzbekistan', vat: 0 },
        { name: 'Vanuatu', vat: 0 },
        { name: 'Vatican City', vat: 0 },
        { name: 'Venezuela', vat: 0 },
        { name: 'Vietnam', vat: 0 },
        { name: 'Wallis and Futuna', vat: 0 },
        { name: 'Western Sahara', vat: 0 },
        { name: 'Yemen', vat: 0 },
        { name: 'Zambia', vat: 0 },
        { name: 'Zimbabwe', vat: 0 },
    ],
    calculateVat: (country, price) => {
        const vatDelimiter = country.indexOf('(');
        const cntry = country.substring(0, vatDelimiter).trim();
        const c = self.countries.filter((x) => x.name.toLowerCase() === cntry.toLowerCase())[0];
        if (c && c.vat > 0)
            return {
                data: true,
                price: parseInt(price) * c.vat,
                vat_percentage: Math.abs(Math.floor(100 * c.vat) - 100),
                country,
            };
        return { data: false, price, country: cntry, vat_percentage: false };
    },
};

export default self;
