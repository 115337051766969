<template>
    <div class="order-callback center-block">
        <div class="title">We have received your payment and we are currently processing your order!</div>
        <div class="order-info-container">
            <div class="title">Order Information</div>
            <div v-if="orders" class="order">
                <ShopOrdersModal :order="orders" />
            </div>
        </div>
        <div v-if="showSignUp">
            <SignIn
                :signUpShowPrompt="showSignUp"
                signUpShowPromptMsg="If you want to save your previous orders, sign up to trade-raid.com below"
                :emailFromOrder="email"
                :orderId="order_id"
            />
        </div>
    </div>
</template>

<script>
import { orderController } from '@/api/shop.js';
import SignIn from '../../components/SignIn';
import './utility.styles.css';
import ShopOrdersModal from '../../components/Profile/shopOrdersModal.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'OrderSuccessCallBack',
    props: {},
    components: {
        ShopOrdersModal,
        SignIn,
    },
    data() {
        return {
            orders: null,
            order_id: null,
            email: null,
            showSignUp: false,
        };
    },
    methods: {},
    computed: {
        ...mapGetters(['user', 'token']),
    },
    watch: {},
    mounted: async function () {
        const params = new URLSearchParams(document.location.search);
        const txt_token = params.get('q');

        await orderController(txt_token, 'ACCEPTED').then((res) => {
            this.orders = res.data.order;
            this.order_id = res.data.order_id;
            this.email = res.data.email;
            if (!this.user.email) {
                setTimeout(() => {
                    this.showSignUp = true;
                }, 1000);
            }
        });
    },
};
</script>
<style scoped lang="scss">
.title {
    display: flex;
    justify-content: center;
}
.order {
    // width: 70%;
    display: flex;
    justify-content: center;
}
</style>
