import request from '../utils/request';

export function getInventory(payload, steamId, token) {
    return request({
        // hexaOne
        // url: `/h/${payload.game}/get-inventory/${steamId}?version=${payload.version}&region=${payload.region}&token=${token}`,
        // steamApis
        url: `/${payload.game}/get-inventory/${steamId}?version=${payload.version}&region=${payload.region}&token=${token}`,
        method: 'GET',
    });
}

export function sendTradeEmail(data, token) {
    return request({
        url: `/offer?token=${token}`,
        method: 'POST',
        data,
    });
}
