<template>
    <div class="order-modal">
        <v-card color="#202136">
            <v-card-title class="headline modal grey text-white background-color-gradient"> Order: {{ order.id }} </v-card-title>
            <v-card-text>
                <div class="trade-info-wrap">
                    <div class="order-created_at">
                        <ClockIcon />
                        {{ new Date(order.created_at).toLocaleDateString() }}
                        {{
                            new Date(order.created_at).toLocaleTimeString(language, {
                                hour: 'numeric',
                                minute: 'numeric',
                            })
                        }}
                    </div>
                    <div class="order-status" :class="statusController(order.status).toLowerCase()">
                        {{ statusController(order.status) }}
                    </div>
                    <div
                        v-if="
                            statusController(order.status) === 'PENDING' ||
                            (statusController(order.status) === 'ESCROW' && order.offer_id)
                        "
                        class="interactive"
                    >
                        <a target="_blank" :href="`https://steamcommunity.com/tradeoffer/${order.offer_id}`"
                            >Open Offer</a
                        >
                    </div>
                </div>

                <div class="order-items">
                    <img
                        class="order-img"
                        v-for="(orderItem, i) in getOrderItems(order.items, 3)"
                        :key="i"
                        :src="orderItem.image"
                        alt="Order item image"
                    />
                    {{ order.items.length }} item(s) <ArrowRightIcon />
                    {{ abbreviateAmount(order.amount, 'standard', 'long') }}
                    <Coin class="cost mr-1" kind="gold" style="width: 15px; height: auto" :include_logo="false" />
                </div>
                <div class="char-name trade-modal-info">
                    <div class="k secondary-color">Character Name:</div>
                    <div class="v">{{ order.character_name }}</div>
                </div>
                <div class="email trade-modal-info">
                    <div class="k secondary-color">Email:</div>
                    <div class="v">{{ order.email }}</div>
                </div>
                <div class="Game trade-modal-info">
                    <div class="k secondary-color">Game:</div>
                    <div class="v">{{ order.game_info.game }}</div>
                </div>
                <div class="server trade-modal-info">
                    <div class="k secondary-color">Server:</div>
                    <div class="v">{{ order.game_info.server }}</div>
                </div>
                <div class="region trade-modal-info">
                    <div class="k secondary-color">Region:</div>
                    <div class="v">{{ order.game_info.region }}</div>
                </div>
                <div v-if="order.game_info.faction" class="faction trade-modal-info">
                    <div class="k secondary-color">Faction:</div>
                    <div class="v">{{ order.game_info.faction }}</div>
                </div>
                <div class="delivery_method trade-modal-info">
                    <div class="k secondary-color">Delivery Method:</div>
                    <div class="v">{{ order.delivery_method.obj_type }}</div>
                </div>
                <div v-if="order.delivery_method.battle_pet_name" class="delivery-artifact trade-modal-info">
                    <div class="k secondary-color">Battle Pet/BOE:</div>
                    <div class="v">{{ order.delivery_method.battle_pet_name }}</div>
                </div>
                <div v-if="order.referral" class="coupon trade-modal-info">
                    <div class="k secondary-color">Coupon:</div>
                    <div class="v">{{ order.referral }}</div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import MMOGameIcon from '../UI/GameIcons/mmo-icons.vue';
import Coin from '../UI/SVG/coin.vue';
import { ClockIcon, ArrowRightIcon } from 'vue-feather-icons';
import {
    MMOGameAlias,
    OrderStatusController,
    getOrderItemsAmount,
    OrderabbreviateAmount,
} from '../../utils/orderService.js';
export default {
    name: 'tradeOrderModal',
    props: {
        order: Object,
    },
    components: {
        MMOGameIcon,
        Coin,
        ClockIcon,
        ArrowRightIcon,
    },
    data() {
        return {
            MMOGameIconWidth: '30',
            language: null,
        };
    },
    watch: {},
    computed: {},
    methods: {
        gameType(game) {
            if (game === 'Final Fantasy XIV') {
                this.MMOGameIconWidth = '100';
            } else {
                this.MMOGameIconWidth = '50';
            }
            return MMOGameAlias(game);
        },
        statusController(status) {
            return OrderStatusController(status);
        },
        getOrderItems(items, n) {
            return getOrderItemsAmount(items, n);
        },
        abbreviateAmount(number, notation, compactDisplay) {
            return OrderabbreviateAmount(number, notation, compactDisplay);
        },
    },
    mounted: function () {
        this.language = navigator.language;
    },
};
</script>

<style scoped>
* {
    color: #f1f1f1;
}
.order-game {
    display: flex;
    gap: 15px;
    align-items: center;
    padding-top: 15px;
}
.order-modal {
    color: #f1f1f1;
}
.secondary-color {
    color: hsla(0, 0%, 100%, 0.3) !important;
}

.trade-modal-info {
    display: flex;
    padding: 5px;
    justify-content: space-between;
}

.interactive {
    cursor: pointer;
    background-color: hsla(0, 0%, 100%, 0.3);
    padding: 5px;
    border-radius: 5px;
    font-size: 0.7rem;
    min-width: 85px;
    display: flex;
    justify-content: center;
}

.interactive > a {
    color: #f1f1f1 !important;
}

.accepted {
    color: #79c175;
    background-color: #3d4442;
}
.pending {
    color: #fad303;
    background-color: #a59233;
    height: 50%;
}
.declined {
    color: #dc6e6f;
    background-color: #53484e;
}

.escrow {
    color: #5a96c7;
    background-color: #3357a5;
}
.order-status {
    padding: 5px;
    border-radius: 5px;
    min-width: 85px;
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
}

.order-items {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 250px;
    padding-bottom: 10px;
}
.order-img {
    width: 40px;
    background-color: hsla(0, 0%, 100%, 0.3);
    padding: 5px;
    border-radius: 5px;
}

.order-created_at {
    display: flex;
    align-items: center;
    gap: 15px;
}

.trade-info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 15px;
    gap: 5px;
}
.background-color-gradient {
    background-image: linear-gradient(#5761ff, #4b90ff)!important;
}
</style>
