<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card color="#383F51">
                <v-card-title class="white--text">
                    <span class="text-h5">Trade Service Maintenance</span>
                </v-card-title>
                <v-card-text class="white--text">
                    We are dealing with technical issues at our Trade Service. <br />As a result, the service has been
                    temporarily taken down for maintenance. <br />Users that seek to trade their Steam Items to Gold are
                    recommended to add Trade-Raid on
                    <a href="https://steamcommunity.com/id/wowgoldtrader/" style="color: #17d0c1">Steam </a> in the
                    meantime. <br />Exchange Service and Reward Activities are unaffected and operating normally.
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            dialog: true,
        };
    },
};
</script>
