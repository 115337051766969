<template>
    <v-container fluid fill-height id="after-trade">
        <section>
            <div
                class="banner-container d-flex justify-center align-center flex-column flex-lg-row"
                ctyle="max-width: 1000px"
            >
                <img
                    src="https://trade-raid-com.mo.cloudinary.net/icons/received.svg"
                    alt="trade-success-banner"
                    class="handshake-img"
                />
                <p class="receive-msg">Trade offer has been sent!<br />Please follow the trade instructions below</p>
            </div>
            <!-- link not working with any id ${$store.state.auth.user.steam_id} -->
            <center>
                <!-- steam://openurl/https://steamcommunity.com/tradeoffer/4341744802
                     -->
                <v-btn
                    class="mobile-btn white--text"
                    v-bind:href="`https://steamcommunity.com/tradeoffer/${this.$store.state.trade.offer_id}`"
                    target="_blank"
                    height="44px"
                    x-small
                    rounded
                    depressed
                    color="#17d0c1"
                >
                    <img
                        src="https://trade-raid-com.mo.cloudinary.net/icons/steam.svg"
                        alt="Steam"
                        style="display: block"
                    />
                    <a
                        src="https://trade-raid-com.mo.cloudinary.net/icons/steam.svg"
                        class="steam-ref"
                        href="https://steamcommunity.com/id/cultmanager/tradeoffers/"
                        target="_blank"
                        style="color=#fff"
                    ></a>
                    Open trade via Steam
                </v-btn>
                <!-- <v-btn
                    v-bind:href="`steam://openurl/https://steamcommunity.com/tradeoffer/${this.$store.state.trade.offer_id}`"
                    target="_blank"
                    height="44px"
                    x-small
                    rounded
                    color="#17d0c1"
                    style="margin-bottom: 10px;"
                    v-if="$store.state.auth.user.id"
                >
                    <img
                        src="https://trade-raid-com.mo.cloudinary.net/icons/steam.svg"
                        alt="Steam"
                        style="display: block; margin-right: 10px"
                    />
                    ${$store.state.auth.user.id}
                    Open Trade in Steam Client
                </v-btn> -->
            </center>
        </section>

        <section id="cta" class="wrapper">
            <div class="inner">
                <h2>Trade instructions - carefully follow each step</h2>
                <p class="trade-instructs">
                    We have not yet received your Steam Items. Trade-raid will begin preparing your gold once the trade
                    offer has been accepted and confirmed on your phone.<br />
                    <br />You will receive an order confirmation mail once we have received your items.
                </p>
            </div>

            <div class="flex-column flex-md-row align-center">
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_1.png" alt="Step image" />
                    </div>
                    <h3>Step 1</h3>
                    <p>Accept the trade offer on your PC or phone</p>
                </div>
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_2.png" alt="Step image" />
                    </div>
                    <h3>Step 2</h3>
                    <p>Confirm the trade offer on your phone</p>
                </div>
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_3.png" alt="Step image" />
                    </div>
                    <h3>Step 3</h3>
                    <p>Trade-Raid is preparing your gold</p>
                </div>
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_4.png" alt="Step image" />
                    </div>
                    <h3>Step 4</h3>
                    <p>Trade-Raid will deliver the gold to you</p>
                </div>
                <div class="icon-block">
                    <div class="svg">
                        <img src="https://trade-raid-com.mo.cloudinary.net/after-trade/step_5.png" alt="Step image" />
                    </div>
                    <h3>Step 5</h3>
                    <p>Review us on Trustpilot!</p>
                </div>
            </div>
        </section>

        <div class="">
            <h2 style="text-align: center; font-size: 200%; color: white">Need help?</h2>
            <p style="text-align: center; font-size: 100%; color: white">
                Visit our <a target="_blank" href="https://trade-raid.com/support" style="color: #17d0c1">FAQ</a> or
                contact Trade-Raid
                <a target="_blank" href="https://trade-raid.com/support" style="color: #17d0c1">Support</a> <br />Please
                mention the CharacterName-Server-Faction associated with your Trade-Raid order when reaching out to us!
            </p>
            <br />
        </div>

        <section class="collapse-container">
            <v-expansion-panels flat>
                <v-expansion-panel v-for="(faq, index) in faqs2" :key="index">
                    <v-expansion-panel-header expand-icon="mdi-chevron-down">{{ faq.title }}</v-expansion-panel-header>
                    <v-expansion-panel-content class="flex-column">
                        <div v-html="faq.ans"></div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </section>
        <!-- <newsletter-popup></newsletter-popup> -->
        <!-- <maintenance-dialog></maintenance-dialog>  -->
        <!-- <contact-form></contact-form> -->
    </v-container>
</template>

<script>
export default {
    name: 'Trade Success',
    metaInfo: {
        title: 'Trade Success',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content: 'Trade-Raid Trade offer has been sent! Trade-Raid will begin preparing your gold now.',
            },
        ],
    },
    data() {
        return {
            dialog: true,
        };
    },
    data: () => ({
        faqs2: [
            {
                title: 'Why did I not receive a trade offer from Trade-Raid?',
                ans: `
                    <p>If you did not receive your trade offer it is because:
                    <br>1) You are trade locked (You have reset your password, your Steam Mobile Guard etc.) Try to send a trade offer to a friend on Steam and see if that is possible.
                    <br>2) You have changed/updated your Trade Offer Link after you Signed In Through Steam at the Trade-Raid website. You will not receive trade offers from us if you updated your trade offer link. Click on your top right corner and go to “Trade Offer Link” to retrieve and update your new Trade Offer Link. Then try to create a new trade again.
                    <br>3) You are trying to trade items from a game(s) that you are VAC-banned from. It is not possible to trade Steam Items from a game Steam has VAC banned you from playing. Try to create a new trade-offer with items from games you are not VAC banned from.
                    <br>4) Our trade-bot(s) are undergoing maintenance. Please contact <a target="_blank" href="https://trade-raid.com/support" style="color:#fff">Trade-Raid Support</a>.
                    </p>
                `,
            },
            {
                title: 'My initial trade offer was declined, and I have received a new offer, what do I do?',
                ans: `
                    <p>Always double check the Trade-bot's Steam Level and Registration Date both when you accept the trade offer and when you confirm the offer on your phone. They must match. If not, please decline your trade immediately without hesitation.</p>
                    <p>If the first trade offer you created was declined automatically, and you receive a new offer that you have not created (a second trade offer) please also decline that trade immediately without hesitation. You may be API scammed.</p>
                    <p>Please read more about API scams here: https://dmarket.com/blog/steam-api-key-scam/</p>
                    <p>For Danish Users: https://scammed.dk/api-scam/</p>
                    <p>You can always reach out for Trade-Raid support if you need further assistance.</p>
                `,
            },
            {
                title: 'Refer your friends! (Up to 550.000G Dragonflight Retail, 5000G Wrath of the Lich King Classic or 2500 Season of Mastery Gold)',
                ans: `
                    <p>Recruit your friends to use Trade-Raid's Steam to MMO gold Service and earn gold for each friend you refer!
                    Get started here: <a target="_blank" href="https://trade-raid.com/gold" style="color:#fff">Reward Page</a>
                    </p>
                `,
            },
            {
                title: 'Share your feedback with Trade-Raid (+5000 Dragonflight Retail, 25 Wrath of the Lich King Classic Gold or 10 Classic Season of Mastery Gold)',
                ans: `
                    <p>Answer our short Feedback Survey and choose between to receive 5000 Dragonflight Retail Gold, 25 Wrath of the Lich King Classic Gold or 10 Classic Season of Mastery Gold! All you need to do is share your first impressions and opinions of using Trade-Raid's services!
                    <br>Get started here: <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdnkhfGqQgBHbGGgornL5r4aGJNysObFpGXJaVGeNZuXrGj6A/viewform" style="color:#fff">Trade-Raid’s Feedback Survey</a>
                    <br>All users are encouraged to participate in our survey whether you have used the Trade-Raid service or not. This reward can be received in combination with other Rewards or Trade-Raid orders.
                </p>
                `,
            },
        ],
    }),
};
</script>

<style>
#faq .v-expansion-panel-content p {
    margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
//Hide mobile-btn on mobile, show on desktop
@media (min-width: 901px) {
    .mobile-btn {
        display: none;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
.banner-container img {
    margin-right: 100px;
}
@media screen and (min-width: 1264px) {
    #after-trade {
        padding-top: calc(80px + 100px);
        padding-bottom: 124px;
        flex-direction: column;
        align-content: center;
        background: linear-gradient(
            90deg,
            rgba(0, 14, 25, 0.1) 0%,
            rgba(15, 24, 45, 0.1) 30.7%,
            rgba(62, 0, 108, 0.1) 60.29%,
            rgba(11, 72, 67, 0.1) 79.69%,
            rgba(15, 24, 45, 0.1) 95.48%
        );
        .handshake-img {
            max-width: 300px;
        }
        .trade-instructs {
            max-width: 100%;
            font-size: 20px;
        }
        .collapse-container {
            width: 100%;
            max-width: 1170px;
            .v-expansion-panel {
                border-radius: 2px;
                background: #383f51;
                margin-top: 0;
                margin-bottom: 20px;
            }
            .v-expansion-panel-header {
                padding: 25px 30px;
                font-size: 18px;
                line-height: 24px;
                color: #fff;
                &::v-deep .v-expansion-panel-header__icon i {
                    color: #fff;
                }
                &.v-expansion-panel-header--active,
                &.v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon i {
                    color: #17d0c1;
                }
            }
            .v-expansion-panel-content {
                padding-left: 30px;
                padding-right: 30px;
                font-size: 16px;
                line-height: 30px;
                color: rgba(255, 255, 255, 1);
                div {
                    padding-top: 0;
                    padding-bottom: 25px;
                }
            }
            .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
                padding: 0;
                font-size: 16px;
                line-height: 30px;
                color: rgba(255, 255, 255, 0.7);
            }
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }

            td,
            th {
                border: 2px solid black;
                text-align: left;
                padding: 10px;
            }

            tr:nth-child(even) {
                background-color: #99929273;
            }
        }
        section {
            width: 80%;
            max-width: 1530px;
            padding: 25px 24px;
            &:first-child {
                padding: 60px 24px;
            }
            &:nth-child(2) {
                padding: 100px 24px 150px;
            }
            &:nth-child(3) {
                padding: 80px 24px;
            }
            &:nth-child(2n + 1) {
                background: #383f51;
            }
            .receive-msg {
                font-weight: 600;
                font-size: 36px;
                line-height: 75px;
                color: #ffffff;
                margin: 0;
            }
            .steam-ref {
                text-decoration: none;
                color: #ffffff;
                padding: 10px 10px 10px 10px;
                border-radius: 2px;
            }
            .steam-ref:hover {
                opacity: 0.3;
            }
            .icon-block p {
                margin-bottom: 0;
            }
            &#cta .inner > p {
                max-width: 56%;
            }
            .svg img {
                width: 70px;
                height: 70px;
            }
            &:last-child {
                text-align: center;
                h3 {
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 60px;
                    color: #ffffff;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 18px;
                    line-height: 35px;
                    display: flex;
                    align-items: center;
                    color: rgba(255, 255, 255, 0.7);
                    margin-bottom: 30px;
                    text-align: center;
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (max-width: 1264px) {
    .banner-container img {
        margin-right: 0;
    }
    #after-trade {
        padding-top: calc(80px + 100px);
        padding-bottom: 124px;
        flex-direction: column;
        align-content: center;
        background: linear-gradient(
            90deg,
            rgba(0, 14, 25, 0.1) 0%,
            rgba(15, 24, 45, 0.1) 30.7%,
            rgba(62, 0, 108, 0.1) 60.29%,
            rgba(11, 72, 67, 0.1) 79.69%,
            rgba(15, 24, 45, 0.1) 95.48%
        );
        .handshake-img {
            max-width: 200px;
        }
        .trade-instructs {
            max-width: 100%;
            font-size: 14px;
        }
        .collapse-container {
            width: 100%;
            max-width: 1170px;
            .v-expansion-panel {
                border-radius: 2px;
                background: #383f51;
                margin-top: 0;
                margin-bottom: 20px;
            }
            .v-expansion-panel-header {
                padding: 25px 30px;
                font-size: 18px;
                line-height: 24px;
                color: #fff;
                &::v-deep .v-expansion-panel-header__icon i {
                    color: #fff;
                }
                &.v-expansion-panel-header--active,
                &.v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon i {
                    color: #17d0c1;
                }
            }
            .v-expansion-panel-content {
                padding-left: 30px;
                padding-right: 30px;
                font-size: 13px;
                line-height: 20px;
                color: rgba(255, 255, 255, 1);
                div {
                    padding-top: 0;
                    padding-bottom: 25px;
                }
            }
            .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
                padding: 0;
                font-size: 14px;
                line-height: 30px;
                color: rgba(255, 255, 255, 0.7);
            }
            table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }

            td,
            th {
                border: 2px solid black;
                text-align: left;
                padding: 10px;
            }

            tr:nth-child(even) {
                background-color: #99929273;
            }
        }
        section {
            width: 80%;
            max-width: 1530px;
            padding: 25px 24px;
            &:first-child {
                padding: 60px 24px;
            }
            &:nth-child(2) {
                padding: 100px 24px 10px;
            }
            &:nth-child(3) {
                padding: 80px 24px;
            }
            &:nth-child(2n + 1) {
                background: #383f51;
            }
            .receive-msg {
                font-weight: bold;
                font-size: 30px;
                line-height: 40px;
                color: #ffffff;
                margin: 0;
                margin-bottom: 10px;
                text-align: center;
            }
            .steam-ref {
                text-decoration: none;
                color: #ffffff;
                padding: 10px 10px 10px 10px;
                border-radius: 2px;
            }
            .steam-ref:hover {
                opacity: 0.3;
            }
            .icon-block {
                width: 100%;
            }
            .icon-block p {
                margin-bottom: 50px;
                width: 100%;
            }
            &#cta .inner > p {
                max-width: 100%;
            }
            .svg img {
                width: 70px;
                height: 70px;
            }
            &:last-child {
                text-align: center;
                h3 {
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 60px;
                    color: #ffffff;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 13px;
                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    color: rgba(255, 255, 255, 0.7);
                    margin-bottom: 30px;
                    text-align: center;
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }
    #cta .inner {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        max-width: unset;
    }
    #cta .inner:first-child {
        flex-direction: column;
        align-items: center;
    }
    #cta .icon-block:not(:last-child):after {
        display: none;
    }
}
</style>
