<template>
    <div class="steps">
        <div class="flex-inline mobile-title">
            <span class="elipse title">{{ $store.state.shop.currentCheckoutStep.number }}</span>
            <div class="title">{{ $store.state.shop.currentCheckoutStep.title }}</div>
        </div>
        <div class="step-wrapper flex-inline gap-15">
            <div class="current-step">
                <StepPaymentMethod v-if="$store.state.shop.currentCheckoutStep.number === 1" />
                <StepReviewOrder v-if="$store.state.shop.currentCheckoutStep.number === 2" />
            </div>

            <div class="order-summary-wrapper">
                <div class="title">Purchase Summary</div>
                <div class="border"></div>
                <div class="order-summary-section">
                    <div class="flex-inline">
                        <span class="sub-color">Purchase(s)</span>
                        <span class="margin-auto">
                            {{ $store.state.shop.activeCurrency }} {{ getTotalOrderPrice }}
                        </span>
                    </div>
                    <div class="flex-inline">
                        <span class="sub-color">Payment Service Fee</span>
                        <span class="margin-auto">
                            {{ $store.state.shop.activeCurrency }} {{ $store.state.shop.serviceFee }}
                        </span>
                    </div>
                    <div class="flex-inline">
                        <span class="sub-color">VAT</span>
                        <span class="margin-auto">
                            Will be added at checkout
                        </span>
                    </div>
                </div>
                <!-- <div class="border"></div>
                Add a promo code
                <div class="flex-inline">
                    <v-text-field
                        v-model="$store.state.shop.promoCode"
                        class="promo-code"
                        outlined
                        dense
                        placeholder="Enter promo code"
                    ></v-text-field>
                    <v-btn class="promo-btn background-color-gradient" @click="applyPromoCode">
                        Apply
                    </v-btn>
                </div> -->
                <div class="border"></div>
                <div class="terms">
                    By placing an order at <span>Trade-Raid.com</span>, you're agreeing to our
                    <a href="https://trade-raid.com/terms" style="color: #fff">Terms of Use</a> and <a href="https://trade-raid.com/privacy-policy" style="color: #fff">Privacy Policy</a>
                    <br /><br />After clicking the "Proceed to Payment" button below, you will be redirected to a secure payment page.
                </div>
                <v-btn
                    v-if="checkOrderBeforeProceed"
                    @click="secureCheckout()"
                    class="checkout-btn background-color-gradient mt-2"
                    >Proceed to Payment</v-btn
                >
            </div>
        </div>
    </div>
</template>

<script>
import '../Shop/utility.styles.css';
import './payment.styles.css';
import StepPaymentMethod from './stepPaymentMethod.vue';
import StepReviewOrder from './stepReviewOrder.vue';
import { checkOut } from '../../api/shop.js';
import { mapGetters } from 'vuex';
export default {
    name: 'steps',
    components: {
        StepPaymentMethod,
        StepReviewOrder,
    },
    props: {
        order: Object,
        activeStep: {
            title: String,
            index: Number,
        },
    },
    data() {
        return {
            active_step: 'Payment Method',
        };
    },
    computed: {
        ...mapGetters(['user', 'token']),
        getOrderN() {
            return this.$store.state.shop.cartItems.length;
        },
        getTotalOrderPrice() {
            let base_price = 0;
            for (const item of this.$store.state.shop.cartItems) {
                base_price += item.price;
            }
            return base_price;
        },
        checkOrderBeforeProceed() {
            // check if the user have filled in their Discord tag
            if (!this.$store.state.shop.discord_tag) {
                return false;
            }
            if (this.$store.state.shop.cartItems.length && this.$store.state.shop.currentCheckoutStep.number === 2) {
                return true;
            }
            return false;
        },
    },
    methods: {
        handleStep(step, index) {
            this.active_step = step;
        },
        storePaymentMethodInfo(key) {
            return this.$store.state.shop.activePaymentMethod[key];
        },
        checkStepState() {
            const current_step = this.active_step.number;
            if (current_step === 1 || this.$store.state.shop.activePaymentMethod) {
                return 2;
            }
        },
        async secureCheckout() {
            let user = null;
            if (this.user) {
                user = this.user;
            }
            const items = this.$store.state.shop.cartItems;
            const discord = this.$store.state.shop.discord_tag;
            const checkout_session = (await checkOut(items, user, this.$store.state.shop.activePaymentMethod, discord)).data;
            window.location.assign(checkout_session.url);
        },
    },
};
</script>

<style scoped lang="scss">
.steps {
    padding-top: 15px;
}
.gap-15 {
    gap: 25px;
}

.step-wrapper {
    align-items: flex-start;
}

@media screen and (max-width: 480px) {
    .step-wrapper {
        display: flex;
        flex-direction: column;
        padding-left: 25px;
        padding-bottom: 25px;
    }
    .order-summary-wrapper {
    }
    .mobile-title {
        padding-left: 25px;
    }
}

@media screen and (max-width: 300px) {
    .step-wrapper {
        display: flex;
        flex-direction: column;
        width: 100px;
        padding-left: 25px;
        padding-bottom: 25px;
    }
    .order-summary-wrapper {
        max-width: 280px;
    }
    .mobile-title {
        padding-left: 25px;
    }
}

.border {
    width: 100%;
    height: 2px;
    background: var(--background-color-gradient);
    margin-block: 10px;
}
.order-summary-wrapper {
    /* margin-top: -30px; */
    color: #f1f1f1;
    width: 350px;
    font-weight: bold;
}
.sub-color {
    color: #7c8599;
    font-size: 13px;
    font-weight: bold;
}
.current-step {
    // display:flex;

    // width: 30vw;

}
@media screen and (max-width: 300px) {
    .current-step {
        // justify-content: center;
        // align-items: center;
        // width: 100%;
    }
}
.terms {
    color: #7c8599;
    font-size: 13px;
}
.terms > span {
    color: #f1f1f1;
}

.order-summary-section {
    padding-inline: 15px;
    font-size: 13px;
    font-weight: bold;
}
.elipse {
    background: linear-gradient(#5761ff, #4b90ff);
    border-radius: 50px;
    width: 35px;
    height: 35px;
    justify-content: center;
    display: flex;
}
</style>
